import { styled } from '@mui/material/styles';
import { Box, Link, Button, Divider, Typography, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import { DialogAnimate } from 'components/animate';
import { OrderCompleteIllustration } from 'assets';
import { generatePath, useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { Routes } from 'constants';
import { titleUrlFormatter, getPath } from 'utils/index';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

const DialogStyle = styled(DialogAnimate)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: 'calc(100% - 48px)',
      maxHeight: 'calc(100% - 48px)',
    },
  },
}));

// TODO registration order

export default function CheckoutOrderCompleteSuccess({ ...other }) {
  const history = useHistory();
  const location = useLocation();
  const { eventId, eventName, orderId } = useParams();

  const searchParams = new URLSearchParams(location.search);

  const orderPathRedirect = `${generatePath(getPath(Routes.MY_RACES), {
    orderId,
  })}?${searchParams.toString()}&timestamp=${moment().toISOString()}`;

  const handleRedirect = () => {
    history.push(generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId }));
  };

  return (
    <DialogStyle fullScreen {...other}>
      <Box sx={{ p: 4, maxWidth: 480, margin: 'auto' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h4" paragraph>
            Thank you for your Registration!
          </Typography>

          <OrderCompleteIllustration sx={{ height: 260, my: 10 }} />

          <Typography align="left" paragraph>
            Registration order &nbsp;
            <Link href={orderPathRedirect}>{orderId}</Link>
          </Typography>

          <Typography align="left" sx={{ color: 'text.secondary' }}>
            We also send you an email.
            <br /> <br /> If you have any question or queries then fell to get in contact us. <br /> <br /> All the
            best,
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Stack direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between" spacing={2}>
          <Button color="inherit" onClick={handleRedirect} startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}>
            Back to event
          </Button>
          {/* <Button
            variant="contained"
            startIcon={<Iconify icon={'ant-design:file-pdf-filled'} />}
            onClick={handleResetStep}
          >
            Download as PDF
          </Button> */}
        </Stack>
      </Box>
    </DialogStyle>
  );
}
