import useIsMountedRef from 'hooks/useIsMountedRef';
import { useState, useEffect } from 'react';
import { useParams, useLocation, generatePath, useHistory } from 'react-router-dom';
import { constructPath, getPath } from 'utils/index';

// ----------------------------------------------------------------------

export default function useTabs(defaultValues, options) {
  const [currentTab, setCurrentTab] = useState(defaultValues || '');
  const params = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const isMountedRef = useIsMountedRef();
  // Navigate tab base on url path
  const getTabBaseOnUrlPath = options?.tabs?.find((tab) => constructPath(tab?.path, params, generatePath) === pathname);

  useEffect(() => {
    if (getTabBaseOnUrlPath && isMountedRef.current) {
      setCurrentTab(getTabBaseOnUrlPath?.value);
    }
    return () => {
      isMountedRef.current = false;
    };
  }, [getTabBaseOnUrlPath, isMountedRef]);

  const handleOnChangeOnTab = (event, newValue, hasValue, options) => {
    const disallowNavigate = options?.disallowNavigate || false;
    if (options?.tabs?.length) {
      const getTab = options?.tabs?.find((tab) => tab.value === newValue);
      if (getTab && getTab.path && !disallowNavigate) {
        const defaultPath = generatePath(getPath(getTab.path), params);
        const path = hasValue ? `${defaultPath}#${hasValue}` : defaultPath;
        history.replace(path);
      }
    }
    setCurrentTab(newValue);
  };

  return {
    currentTab,
    onChangeTab: handleOnChangeOnTab,
    setCurrentTab,
  };
}
