import { useCallback, memo, useEffect, useState, useImperativeHandle, forwardRef } from 'react';
import uuidv4 from 'utils/uuidv4';
import { camelCase, capitalCase, sentenceCase } from 'change-case';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  fieldMap,
  disableFieldOnLabelMapping,
  needsLabelFieldsMapping,
  FieldProperties,
  fieldTypes,
  RHFRadioGroup,
  RHFSelect,
  FieldSetupAvailable,
} from 'components/hook-form';
import { createYupSchema, mergeObjectsByField, removeDuplicateTypes } from 'utils';
import ModalSaveChanges from 'sections/@dashboard/event-signup/ModalSaveChanges';
import {
  Grid,
  Collapse,
  Typography,
  Card,
  Button,
  Box,
  Stack,
  OutlinedInput,
  Checkbox,
  FormHelperText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import useResponsive from 'hooks/useResponsive';
import { CUSTOM_FIELD_ID } from 'constants/index';
import FormProvider from './FormProvider';
import RHFTextField from './RHFTextField';

const RegisterForButtonValues = {
  MYSELF: 'registerMySelf',
  OTHERS: 'registerOther',
};

const getFullName = (option) => {
  return `${option?.firstName?.trim()} ${option?.lastName?.trim()}`;
};

export const DynamicForm = forwardRef(
  (
    {
      registrantData,
      formFields,
      numberOfForms,
      formNumber,
      onSubmitActionHandler,
      teamName,
      openForm,
      setOpenForm,
      lineItemsLength = 0,
      currentLineItemIndexPlusOne = 1,
      nextPageCall = () => {},
      setEnabledNext = () => {},
      isModal = false,
      onCloseModal = () => {},
      isPreview = false,
      authUserData = {},
      isAllFormsFilledIn = false,
      lastCard,
      prefillOptions: prefillOptionsProps = [],
      formIndex,
    },
    ref
  ) => {
    const [hasReachedTheLast, setHasReachedTheLast] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const isDesktop = useResponsive('up', 'lg');

    const parsedUserData = registrantData?.userData
      ? typeof registrantData?.userData === 'string'
        ? JSON.parse(registrantData?.userData)
        : registrantData?.userData
      : {};

    const keysOfRegistrantData = Object.keys(parsedUserData);
    const registrantDataFullName = `${parsedUserData?.firstName} ${parsedUserData?.lastName}`;
    const authUserFullName = `${authUserData?.firstName} ${authUserData?.lastName}`;

    const yepSchema = formFields.reduce(createYupSchema, {});

    const isRegisteredForSelf = registrantDataFullName?.toLowerCase() === authUserFullName?.toLowerCase();

    const prefillOptions = prefillOptionsProps?.filter(
      (option) => getFullName(option)?.toLowerCase() !== authUserFullName?.toLowerCase()
    );

    const forSelfExistingData = prefillOptionsProps?.find(
      (option) => getFullName(option)?.toLowerCase() === authUserFullName?.toLowerCase()
    );

    const forSelfExistingDataParsed = JSON.parse(forSelfExistingData?.userData ?? '{}') ?? {};

    const mergedAuthUserData = mergeObjectsByField([forSelfExistingDataParsed, authUserData], [])?.[0];
    const FormSchemaValidation = Yup.object().shape({
      ...yepSchema,
      ...(!isPreview && { registerFor: Yup.string().required('This field is required') }),
    });

    const registeredForSelfDefaultValues = (fieldId, forceAuthUserData = false) => {
      const value = forceAuthUserData
        ? mergedAuthUserData?.[fieldId] || parsedUserData?.[fieldId] || undefined
        : parsedUserData?.[fieldId] || mergedAuthUserData?.[fieldId] || undefined;
      return value
        ? {
            [fieldId]: value,
          }
        : {};
    };

    const defaultValues = formFields.reduce(
      (acc, field) => ({
        ...acc,
        [field?.fieldId]: keysOfRegistrantData?.includes(field?.fieldId)
          ? parsedUserData?.[field?.fieldId]
          : field?.defaultValue
          ? field?.defaultValue
          : '',
        ...(numberOfForms > 1 && {
          teamName: teamName ?? '',
        }),
        ...(!isPreview && {
          registerFor: isRegisteredForSelf
            ? RegisterForButtonValues.MYSELF
            : parsedUserData?.[field?.fieldId] // If this has field this prepopulate the registerFor
            ? RegisterForButtonValues.OTHERS
            : '',
          ...(isRegisteredForSelf && registeredForSelfDefaultValues(field?.fieldId)),
          registerOthersOption: '',
        }),
      }),
      {}
    );

    const methods = useForm({
      resolver: yupResolver(FormSchemaValidation),
      defaultValues,
      shouldFocusError: true,
    });

    const {
      handleSubmit,
      formState: { isSubmitting, isDirty, errors },
      watch,
      control,
      setValue,
      reset,
    } = methods;

    const hasErrors = Boolean(Object.entries(errors)?.length);

    const formValues = watch();

    const registerForValue = watch('registerFor');
    const registerOthersOptionValue = watch('registerOthersOption');

    const lastForm = numberOfForms === formNumber && lineItemsLength === currentLineItemIndexPlusOne;

    const canProceedToNextStep = isAllFormsFilledIn && lastCard;

    const handleResetForm = useCallback(() => {
      reset(defaultValues);
    }, [defaultValues]);

    useImperativeHandle(ref, () => ({
      handleResetForm: handleResetForm,
    }));

    const mergeUserData = (forceAuthUserData) =>
      formFields.reduce(
        (acc, field) => ({
          ...acc,
          ...registeredForSelfDefaultValues(field?.fieldId, forceAuthUserData),
        }),
        {}
      );

    const userOthersValues = formFields.reduce((acc, field) => {
      const fieldId = field?.fieldId;
      const isFieldInRegistrantData = keysOfRegistrantData?.includes(fieldId);
      const defaultValue = field?.defaultValue ?? '';

      return {
        ...acc,
        [fieldId]: isFieldInRegistrantData && !isRegisteredForSelf ? parsedUserData?.[fieldId] : defaultValue,
        ...(numberOfForms > 1 && { teamName: teamName ?? '' }),
      };
    }, {});

    const userValues = (providedValue = {}) =>
      formFields.reduce(
        // this is used only on resetting to other registrant
        (acc, field) => ({
          ...acc,
          [field?.fieldId]: providedValue?.[field?.fieldId] ? providedValue?.[field?.fieldId] : '',
          ...(numberOfForms > 1 && {
            teamName: teamName ?? '',
          }),
        }),
        {}
      );

    const hasOtherValues =
      formValues?.firstName && (!registerForValue || registerForValue !== RegisterForButtonValues.MYSELF);

    // "Register For" Changes Only
    useEffect(() => {
      if (!hasOtherValues) {
        if (registerForValue === RegisterForButtonValues.MYSELF) {
          const newValues = { ...mergeUserData(true), registerFor: RegisterForButtonValues.MYSELF };
          Object.entries(newValues).forEach(([key, value]) => {
            setValue(key, value);
          });
        } else if (registerForValue === RegisterForButtonValues.OTHERS) {
          const newValues = {
            ...userOthersValues,
            registerFor: RegisterForButtonValues.OTHERS,
          };
          Object.entries(newValues).forEach(([key, value]) => {
            setValue(key, value);
          });
        }
      }
    }, [registerForValue]);

    // Prefilling selected previous registrant Changes Only
    useEffect(() => {
      if (registerOthersOptionValue) {
        const toPreFillValues = prefillOptionsProps?.find(
          (option) => camelCase(getFullName(option)) === registerOthersOptionValue // uses camelCase as the value provided for this field is camelCased
        );
        const newValues = {
          ...userValues(JSON.parse(toPreFillValues?.userData || '')),
          registerFor: RegisterForButtonValues.OTHERS,
        };
        Object.entries(newValues).forEach(([key, value]) => {
          setValue(key, value);
        });
      }
    }, [registerOthersOptionValue, prefillOptionsProps]);

    useEffect(() => {
      if (lastForm && !hasReachedTheLast) {
        setEnabledNext(true);
        setHasReachedTheLast(true);
      }
    }, [lastForm, hasReachedTheLast]);

    const handleEditableClick = useCallback(() => {
      setOpenForm(formNumber);
    }, []);

    const handleModalClose = useCallback(() => {
      setOpenModal(false);
    }, []);

    const handleNextClick = useCallback(() => {
      setOpenModal(false);
      nextFunction();
    }, []);

    const nextFunction = useCallback(() => {
      if (isDirty) {
        return setOpenModal(true);
      }

      if (canProceedToNextStep) {
        nextPageCall();
      } else if (!lastForm) {
        setOpenForm(numberOfForms <= currentLineItemIndexPlusOne + 1 ? currentLineItemIndexPlusOne + 1 : 1);
      }
      handleModalClose();
    }, [numberOfForms, lastForm, canProceedToNextStep, isDirty, currentLineItemIndexPlusOne]);

    const onSubmit = useCallback(
      async (values) => {
        const teamTitleHasChanged = Boolean(teamName && values?.teamName && teamName !== values?.teamName);
        // ----- to remove values -----
        delete values.registerOthersOption;
        delete values.registerFor;
        // ----- end to remove values -----

        await onSubmitActionHandler(
          values,
          isDirty,
          formNumber,
          registrantData?.id || '',
          teamTitleHasChanged,
          currentLineItemIndexPlusOne,
          formIndex
        );

        reset(values);

        if (isModal) {
          // close the modal
          onCloseModal();
        }

        if (!isModal) {
          if (canProceedToNextStep) {
            nextPageCall();
          } else if (!lastForm) {
            setOpenForm(numberOfForms <= currentLineItemIndexPlusOne + 1 ? currentLineItemIndexPlusOne + 1 : 1);
          }
        }
      },
      [
        formNumber,
        registrantData,
        lastForm,
        canProceedToNextStep,
        teamName,
        isDirty,
        isModal,
        currentLineItemIndexPlusOne,
        formIndex,
      ]
    );

    const initUserDataDisplay = (userData) => (
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1">{`${userData?.firstName || ''} ${userData?.lastName || ''}`}</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp;({userData?.gender || ''})
        </Typography>
      </Box>
    );

    const helperTextForTitleChange = `You can only change Team Name if you are editing Team Member 1`;

    return (
      <>
        {openModal && <ModalSaveChanges onClose={handleModalClose} open={openModal} onConfirm={handleNextClick} />}

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {numberOfForms > 1 && formNumber === 1 && (
              <RHFTextField
                sx={{ m: 2 }}
                name="teamName"
                label="Team Name"
                required
                disabled={openForm !== 1}
                helperText={openForm !== 1 ? helperTextForTitleChange : ''}
              />
            )}
            {numberOfForms > 1 ? (
              <Card sx={{ p: 2, pt: 3, pb: 4, m: 2, border: 1 }}>
                <Collapse in={openForm === formNumber} sx={{ width: 1 }}>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography style={{ color: 'red' }} variant="subtitle1">
                      Team Member &nbsp;{formNumber}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {!isPreview && (
                      <RegistrationOwnership
                        control={control}
                        selection={formValues?.registerFor}
                        registerOthersOptions={prefillOptions}
                      />
                    )}
                    <Grid container spacing={3}>
                      {formFields?.map((field, ind) => (
                        <Grid item xs={12} md={6} key={ind}>
                          <FieldComponent field={field} />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ pt: 3 }}>
                    <Stack
                      spacing={1}
                      direction="row"
                      alignItems="space-between"
                      sx={!isModal ? { px: 2, py: 1 } : { px: 0, py: 1 }}
                    >
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isDirty}
                        disableRipple
                      >
                        {!registrantData?.id ? 'Save data' : 'Save Changes'}
                      </LoadingButton>
                      {registrantData?.id && (
                        <Button variant="outlined" onClick={nextFunction} disableRipple>
                          {numberOfForms === formNumber
                            ? canProceedToNextStep && !isModal
                              ? 'Proceed To Next Step'
                              : 'Done'
                            : 'Next Member'}
                        </Button>
                      )}
                    </Stack>
                  </Grid>
                </Collapse>

                {openForm !== formNumber && registrantData?.id && initUserDataDisplay(parsedUserData)}

                {openForm !== formNumber && !registrantData?.id && (
                  <Typography variant="subtitle1">Team Member &nbsp;{formNumber}</Typography>
                )}

                {openForm !== formNumber && registrantData?.id && (
                  <Box
                    sx={{
                      mt: 3,
                      display: 'flex',
                      position: { sm: 'absolute' },
                      right: { sm: 24 },
                      bottom: { sm: 10 },
                    }}
                  >
                    <Button variant={'outlined'} size={'small'} color={'inherit'} onClick={handleEditableClick}>
                      Edit
                    </Button>
                    <Box sx={{ mx: 0.5 }} />
                  </Box>
                )}
              </Card>
            ) : (
              <Grid item sx={{ p: 2, pt: 3, m: 2 }}>
                {!isPreview && (
                  <RegistrationOwnership
                    control={control}
                    selection={formValues?.registerFor}
                    registerOthersOptions={prefillOptions}
                  />
                )}
                <Grid container spacing={3}>
                  {formFields?.map((field, ind) => {
                    return (
                      <Grid item xs={12} md={6} key={ind}>
                        <FieldComponent field={field} />
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item xs={12} sx={{ pt: 3 }}>
                  <Stack spacing={1} direction="row" alignItems="space-between" sx={{ px: 2, py: 1 }}>
                    <Stack>
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        color={hasErrors ? 'error' : 'primary'}
                        loading={isSubmitting}
                        disabled={!isDirty}
                        disableRipple
                      >
                        {!registrantData?.id ? 'Save data' : 'Save Changes'}
                      </LoadingButton>
                      {hasErrors && <FormHelperText error>There are some errors scroll up</FormHelperText>}
                    </Stack>
                    {registrantData?.id && canProceedToNextStep && !isModal && (
                      <LoadingButton variant="outlined" onClick={nextFunction} disableRipple>
                        {isDesktop ? 'Proceed to next step' : 'Next step'}
                      </LoadingButton>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </>
    );
  }
);

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const FieldComponent = ({ field }) => {
  const Component = fieldMap[field?.fieldType];

  const isFieldRequired = field?.validations?.some((validation) => validation?.type === 'required');

  return (
    <>
      {needsLabelFieldsMapping.includes(field?.fieldType) && <LabelStyle>{field?.label}</LabelStyle>}
      <Component
        name={field?.fieldId}
        {...(!disableFieldOnLabelMapping.includes(field?.fieldType) && { label: field?.label })}
        placeholder={field?.placeholder}
        {...(field?.type && { type: field?.type })}
        {...(field?.defaultValue && { defaultValue: field?.defaultValue })}
        {...(field?.options?.length && { options: field?.options })}
        {...(isFieldRequired && { required: true })}
      >
        {field?.options?.length > 0 && (
          <>
            {!disableFieldOnLabelMapping.includes(field?.fieldType) && <option value={''} />}
            {field?.options.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option.label}
              </option>
            ))}
          </>
        )}
      </Component>
    </>
  );
};

export const FieldIdField = ({ field }) => {
  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>Field Id</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <LabelStyle sx={{ mt: 2, color: 'black' }}>
            <strong>{field?.fieldId}</strong>
          </LabelStyle>
        </Stack>
      </Stack>
    </>
  );
};

export const LabelField = ({ setField, field, errors }) => {
  const fieldIdExists = errors?.some((item) => item?.fieldIdExists);
  const hasErrors = fieldIdExists;

  const fieldId = field?.[FieldProperties.FIELD_ID];

  // If not on the list then it should be available for edit
  const shouldBeAvailableForEdit = !FieldSetupAvailable?.[fieldId];

  const isEditable = shouldBeAvailableForEdit || FieldSetupAvailable?.[fieldId]?.includes(fieldId);

  const handleOnChange = useCallback(
    (event) => {
      const value = event.target.value;

      setField((prev) => ({
        ...prev,
        [FieldProperties.LABEL]: value,
        [FieldProperties.FIELD_ID]:
          value && !fieldIdExists ? camelCase(value) : `${camelCase(value || CUSTOM_FIELD_ID)}_${uuidv4(4)}`,
      }));
    },
    [fieldIdExists]
  );

  return (
    <>
      <OutlinedInput
        fullWidth
        multiline
        size="small"
        placeholder="Field name"
        name={fieldId}
        value={field?.[FieldProperties.LABEL]}
        disabled={!isEditable}
        required
        inputProps={{ maxLength: 50 }}
        onChange={handleOnChange}
        error={!field?.[FieldProperties.LABEL] || fieldIdExists}
        sx={{
          typography: 'h6',
          '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
        }}
      />
      {hasErrors && (
        <FormHelperText error id="accountId-error">
          Field id already exists please create a new one
        </FormHelperText>
      )}
    </>
  );
};

export const PlaceholderField = ({ setField, field }) => {
  const handleOnChange = (event) => {
    const value = event.target.value;

    setField((prev) => ({
      ...prev,
      [FieldProperties.PLACEHOLDER]: value,
    }));
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{sentenceCase(FieldProperties.PLACEHOLDER)}</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <OutlinedInput
            fullWidth
            multiline
            size="small"
            placeholder="Field name"
            name={FieldProperties.PLACEHOLDER}
            value={field?.[FieldProperties.PLACEHOLDER]}
            required
            inputProps={{ maxLength: 50 }}
            onChange={handleOnChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export const DefaultValueField = ({ setField, field }) => {
  const typeNeedsOptions =
    field?.fieldType === fieldTypes.Checkbox ||
    field?.fieldType === fieldTypes.Radio ||
    field?.fieldType === fieldTypes.Select;
  const handleOnChange = (event) => {
    const value = event.target.value;

    setField((prev) => ({
      ...prev,
      [FieldProperties.DEFAULT_VALUE]: value,
    }));
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{sentenceCase(FieldProperties.DEFAULT_VALUE)}</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <OutlinedInput
            fullWidth
            multiline
            size="small"
            placeholder="Field name"
            name={FieldProperties.DEFAULT_VALUE}
            value={field?.[FieldProperties.DEFAULT_VALUE] || ''}
            required
            inputProps={{ maxLength: 50 }}
            onChange={handleOnChange}
          />
          {typeNeedsOptions && <FormHelperText id="accountId-error">Should be one of the options</FormHelperText>}
        </Stack>
      </Stack>
    </>
  );
};

export const isRequiredField = ({ setField, field }) => {
  const handleOnChange = (event) => {
    const value = event.target.checked;

    const validation = value
      ? [
          {
            type: 'required',
            params: `["This field is required"]`,
          },
        ]
      : [];

    setField((prev) => {
      const validations = removeDuplicateTypes([
        ...(prev?.validations || [])?.filter((item) => item?.type !== 'required'),
        ...validation,
      ]);
      return { ...prev, [FieldProperties.VALIDATIONS]: validations };
    });
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{sentenceCase(FieldProperties.IS_REQUIRED)}</LabelStyle>
        <Checkbox checked={field?.validations?.some((item) => item?.type === 'required')} onChange={handleOnChange} />
      </Stack>
    </>
  );
};

export const isNumberOnlyField = ({ setField, field }) => {
  const handleOnChange = (event) => {
    const value = event.target.checked;

    setField((prev) => ({
      ...prev,
      [FieldProperties.TYPE]: value ? FieldProperties.TYPE_NUMBER : '',
      [FieldProperties.VALIDATION_TYPE]: value ? FieldProperties.TYPE_NUMBER : '',
    }));
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 1, mr: 2 }}>{sentenceCase(FieldProperties.IS_NUMBER_ONLY)}</LabelStyle>
        <Checkbox checked={field?.type === FieldProperties.TYPE_NUMBER} onChange={handleOnChange} />
      </Stack>
    </>
  );
};

export const isEmailField = ({ setField, field }) => {
  const handleOnChange = (event) => {
    const value = event.target.checked;

    setField((prev) => ({
      ...prev,
      [FieldProperties.TYPE]: value ? FieldProperties.TYPE_EMAIL : '',
    }));
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 1, mr: 2 }}>{sentenceCase(FieldProperties.IS_EMAIL)}</LabelStyle>
        <Checkbox checked={field?.type === FieldProperties.TYPE_EMAIL} onChange={handleOnChange} />
      </Stack>
    </>
  );
};

export const getNumberFromValidation = (field, valueType) => {
  return (
    field?.validations
      ?.find((item) => item?.type === valueType)
      ?.params?.split(',')?.[0]
      ?.replace(/[^0-9]/g, '') || ''
  );
};

export const MinValueField = ({ setField, field }) => {
  let inputValue = getNumberFromValidation(field, FieldProperties.MIN_VALUE);
  const handleOnChange = (event) => {
    const value = event.target.value;

    const minVal = value
      ? [
          {
            type: FieldProperties.MIN_VALUE,
            params: `[${value},"This field cannot be less than ${value}" ]`,
          },
        ]
      : [];

    setField((prev) => {
      const validations = [
        ...(prev?.validations || [])?.filter((item) => item?.type !== FieldProperties.MIN_VALUE),
        ...minVal,
      ];
      inputValue = getNumberFromValidation({ validations }, FieldProperties.MIN_VALUE);
      return { ...prev, [FieldProperties.VALIDATIONS]: validations };
    });
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{capitalCase(FieldProperties.MIN_VALUE)}</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <OutlinedInput
            fullWidth
            multiline
            size="small"
            placeholder="Min Value"
            name={FieldProperties.MIN_VALUE}
            type="number"
            value={inputValue}
            required
            inputProps={{ maxLength: 3 }}
            onChange={handleOnChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export const MaxValueField = ({ setField, field }) => {
  let inputValue = getNumberFromValidation(field, FieldProperties.MAX_VALUE);
  const handleOnChange = (event) => {
    const value = event.target.value;

    const maxVal = value
      ? [
          {
            type: FieldProperties.MAX_VALUE,
            params: `[${value},"This field cannot be more than ${value}"]`,
          },
        ]
      : [];

    setField((prev) => {
      const validations = [
        ...(prev?.validations || [])?.filter((item) => item?.type !== FieldProperties.MAX_VALUE),
        ...maxVal,
      ];

      inputValue = getNumberFromValidation({ validations }, FieldProperties.MAX_VALUE);
      return { ...prev, [FieldProperties.VALIDATIONS]: validations };
    });
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{sentenceCase(FieldProperties.MAX_VALUE)}</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <OutlinedInput
            fullWidth
            multiline
            size="small"
            placeholder="Max Value"
            name={FieldProperties.MAX_VALUE}
            type="number"
            value={inputValue}
            required
            inputProps={{ maxLength: 3 }}
            onChange={handleOnChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

export const OptionsField = ({ setField, field }) => {
  const inputValue = field?.options?.reduce((acc, item) => [...acc, item?.label], [])?.join(',');

  const handleOnChange = (event) => {
    const value = event.target.value?.split(',')?.reduce((acc, item) => {
      return [
        ...acc,
        {
          label: item,
          value: item,
        },
      ];
    }, []);

    setField((prev) => ({
      ...prev,
      [FieldProperties.OPTIONS]: value,
    }));
  };

  return (
    <>
      <Stack direction="row">
        <LabelStyle sx={{ mt: 2, mr: 2 }}>{sentenceCase(FieldProperties.OPTIONS)}</LabelStyle>
        <Stack direction="row" flexWrap="wrap">
          <OutlinedInput
            fullWidth
            multiline
            size="large"
            placeholder="Separated by commas"
            name={FieldProperties.OPTIONS}
            value={inputValue}
            onChange={handleOnChange}
          />
        </Stack>
      </Stack>
    </>
  );
};

const RegistrationOwnership = ({ control, selection, registerOthersOptions: options }) => {
  return (
    <Grid container sx={{ pb: 2, width: '100%' }}>
      <Grid item xs={12} md={6}>
        <RHFRadioGroup
          name="registerFor"
          label="Completing this form for"
          id="registerFor-id-dynamic-form"
          options={[
            { label: 'Myself', value: RegisterForButtonValues.MYSELF },
            { label: 'Someone else', value: RegisterForButtonValues.OTHERS },
          ]}
          control={control}
        />
        {selection === RegisterForButtonValues.OTHERS && options?.length > 0 && (
          <RHFSelect name="registerOthersOption" label="Select Registrant(optional)">
            <option value="" />
            {options.map((option, index) => {
              const value = getFullName(option);
              return (
                <option key={option?.id || index} value={camelCase(value)}>
                  {capitalCase(value)}
                </option>
              );
            })}
          </RHFSelect>
        )}
      </Grid>
    </Grid>
  );
};
