/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createToken = /* GraphQL */ `
  mutation CreateToken($ctx: CTX!, $args: Token!) {
    createToken(ctx: $ctx, args: $args)
  }
`;
export const verifyToken = /* GraphQL */ `
  mutation VerifyToken($ctx: CTX!, $args: UserToken!) {
    verifyToken(ctx: $ctx, args: $args)
  }
`;
export const upsertUser = /* GraphQL */ `
  mutation UpsertUser($ctx: CTX!, $args: UpsertUserInput!) {
    upsertUser(ctx: $ctx, args: $args)
  }
`;
export const addUserToGroup = /* GraphQL */ `
  mutation AddUserToGroup($ctx: CTX!, $args: UserToGroupArgs!) {
    addUserToGroup(ctx: $ctx, args: $args)
  }
`;
export const createDraftRegistration = /* GraphQL */ `
  mutation CreateDraftRegistration($ctx: CTX!, $args: CreateDraftOrderArgs) {
    createDraftRegistration(ctx: $ctx, args: $args)
  }
`;
export const resetDraftRegistration = /* GraphQL */ `
  mutation ResetDraftRegistration($ctx: CTX!, $args: ResetDraftOrderArgs) {
    resetDraftRegistration(ctx: $ctx, args: $args)
  }
`;
export const getCalculatedRegistrationCart = /* GraphQL */ `
  mutation GetCalculatedRegistrationCart(
    $ctx: CTX!
    $args: RegistrationCartArgs
  ) {
    getCalculatedRegistrationCart(ctx: $ctx, args: $args)
  }
`;
export const updateCategoriesInventory = /* GraphQL */ `
  mutation UpdateCategoriesInventory($ctx: CTX!, $args: UpdateInventoryArgs) {
    updateCategoriesInventory(ctx: $ctx, args: $args)
  }
`;
export const updateAmountAccumulated = /* GraphQL */ `
  mutation UpdateAmountAccumulated(
    $ctx: CTX!
    $args: UpdateAmountAccumulatedArgs
  ) {
    updateAmountAccumulated(ctx: $ctx, args: $args)
  }
`;
export const preCheckoutChecker = /* GraphQL */ `
  mutation PreCheckoutChecker($ctx: CTX!, $args: PreCheckoutCheckerArgs) {
    preCheckoutChecker(ctx: $ctx, args: $args)
  }
`;
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent($ctx: CTX!, $args: CreatePaymentIntentArgs) {
    createPaymentIntent(ctx: $ctx, args: $args)
  }
`;
export const fetchPaymentMethodsAvailable = /* GraphQL */ `
  mutation FetchPaymentMethodsAvailable($ctx: CTX!) {
    fetchPaymentMethodsAvailable(ctx: $ctx)
  }
`;
export const createAnOrganization = /* GraphQL */ `
  mutation CreateAnOrganization(
    $ctx: CTX!
    $args: CreateOrganizationInputArgs
  ) {
    createAnOrganization(ctx: $ctx, args: $args)
  }
`;
export const deleteAnOrganization = /* GraphQL */ `
  mutation DeleteAnOrganization(
    $ctx: CTX!
    $args: [DeleteAnOrganizationArgs!]
  ) {
    deleteAnOrganization(ctx: $ctx, args: $args)
  }
`;
export const updateAnOrganization = /* GraphQL */ `
  mutation UpdateAnOrganization(
    $ctx: CTX!
    $args: UpdateAnOrganizationInputArgs
  ) {
    updateAnOrganization(ctx: $ctx, args: $args)
  }
`;
export const removeStaffsFromOrganization = /* GraphQL */ `
  mutation RemoveStaffsFromOrganization(
    $ctx: CTX!
    $args: OrganizationResolverArgs
  ) {
    removeStaffsFromOrganization(ctx: $ctx, args: $args)
  }
`;
export const batchDeleteUserOrganizationEventJunction = /* GraphQL */ `
  mutation BatchDeleteUserOrganizationEventJunction($ctx: CTX!, $ids: [ID]) {
    batchDeleteUserOrganizationEventJunction(ctx: $ctx, ids: $ids) {
      id
      userId
      userEmail
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organizationId
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNewEvent = /* GraphQL */ `
  mutation CreateNewEvent($ctx: CTX!, $args: CreateEventArgs) {
    createNewEvent(ctx: $ctx, args: $args)
  }
`;
export const updateAnEvent = /* GraphQL */ `
  mutation UpdateAnEvent($ctx: CTX!, $args: UpdateEventArgs) {
    updateAnEvent(ctx: $ctx, args: $args)
  }
`;
export const deleteAnEvent = /* GraphQL */ `
  mutation DeleteAnEvent($ctx: CTX!, $args: DeleteEventArgs) {
    deleteAnEvent(ctx: $ctx, args: $args)
  }
`;
export const createNewCategory = /* GraphQL */ `
  mutation CreateNewCategory($ctx: CTX!, $args: CreateCategoryArgs) {
    createNewCategory(ctx: $ctx, args: $args)
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($ctx: CTX!, $args: UpdateCategoryArgs) {
    updateCategory(ctx: $ctx, args: $args)
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($ctx: CTX!, $args: [DeleteCategoryArgs]) {
    deleteCategory(ctx: $ctx, args: $args)
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      eventName
      name
      eventShortDescription
      additionalNotes
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      eventStaffs {
        nextToken
        __typename
      }
      registrationEnd
      registrationStart
      hasSameRouteForAllCat
      routeImageURL {
        nextToken
        __typename
      }
      mainEventImage {
        nextToken
        __typename
      }
      eventInstance
      eventType
      dateOfEvent
      slotsAvailabilityIsSameForAllCategories
      canHaveMultipleRegistrantsInOneOrder
      maxRegistrantsInOneOrder
      hasSameFormForAllCategories
      metricUsedIsKm
      eventPrizes {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      contactInfo {
        type
        value
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      published
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      isWaiverFormSameForAll
      isRulesAndRegulationsSameForAll
      payoutOption {
        bankName
        accountNumber
        fullName
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      addressLine1
      addressLine2
      barangay
      city
      state
      region
      country
      transactionFee
      transactionFeeType
      updatedBibNumberSequence
      deliveryOptions {
        title
        description
        value
        __typename
      }
      createdAt
      subscription {
        eventsStaff
        categories
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      eventName
      name
      eventShortDescription
      additionalNotes
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      eventStaffs {
        nextToken
        __typename
      }
      registrationEnd
      registrationStart
      hasSameRouteForAllCat
      routeImageURL {
        nextToken
        __typename
      }
      mainEventImage {
        nextToken
        __typename
      }
      eventInstance
      eventType
      dateOfEvent
      slotsAvailabilityIsSameForAllCategories
      canHaveMultipleRegistrantsInOneOrder
      maxRegistrantsInOneOrder
      hasSameFormForAllCategories
      metricUsedIsKm
      eventPrizes {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      contactInfo {
        type
        value
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      published
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      isWaiverFormSameForAll
      isRulesAndRegulationsSameForAll
      payoutOption {
        bankName
        accountNumber
        fullName
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      addressLine1
      addressLine2
      barangay
      city
      state
      region
      country
      transactionFee
      transactionFeeType
      updatedBibNumberSequence
      deliveryOptions {
        title
        description
        value
        __typename
      }
      createdAt
      subscription {
        eventsStaff
        categories
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      eventName
      name
      eventShortDescription
      additionalNotes
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      eventStaffs {
        nextToken
        __typename
      }
      registrationEnd
      registrationStart
      hasSameRouteForAllCat
      routeImageURL {
        nextToken
        __typename
      }
      mainEventImage {
        nextToken
        __typename
      }
      eventInstance
      eventType
      dateOfEvent
      slotsAvailabilityIsSameForAllCategories
      canHaveMultipleRegistrantsInOneOrder
      maxRegistrantsInOneOrder
      hasSameFormForAllCategories
      metricUsedIsKm
      eventPrizes {
        nextToken
        __typename
      }
      categories {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      contactInfo {
        type
        value
        __typename
      }
      discounts {
        nextToken
        __typename
      }
      published
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      isWaiverFormSameForAll
      isRulesAndRegulationsSameForAll
      payoutOption {
        bankName
        accountNumber
        fullName
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      addressLine1
      addressLine2
      barangay
      city
      state
      region
      country
      transactionFee
      transactionFeeType
      updatedBibNumberSequence
      deliveryOptions {
        title
        description
        value
        __typename
      }
      createdAt
      subscription {
        eventsStaff
        categories
        __typename
      }
      updatedAt
      __typename
    }
  }
`;
export const createPayout = /* GraphQL */ `
  mutation CreatePayout(
    $input: CreatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    createPayout(input: $input, condition: $condition) {
      id
      userId
      eventId
      organizationId
      bankAccount {
        accountNumber
        accountName
        bankCode
        __typename
      }
      amount {
        type
        amountInCent
        currency
        __typename
      }
      transactionDate
      status
      agreements {
        nextToken
        __typename
      }
      odooData
      paymongoData
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayout = /* GraphQL */ `
  mutation UpdatePayout(
    $input: UpdatePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    updatePayout(input: $input, condition: $condition) {
      id
      userId
      eventId
      organizationId
      bankAccount {
        accountNumber
        accountName
        bankCode
        __typename
      }
      amount {
        type
        amountInCent
        currency
        __typename
      }
      transactionDate
      status
      agreements {
        nextToken
        __typename
      }
      odooData
      paymongoData
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayout = /* GraphQL */ `
  mutation DeletePayout(
    $input: DeletePayoutInput!
    $condition: ModelPayoutConditionInput
  ) {
    deletePayout(input: $input, condition: $condition) {
      id
      userId
      eventId
      organizationId
      bankAccount {
        accountNumber
        accountName
        bankCode
        __typename
      }
      amount {
        type
        amountInCent
        currency
        __typename
      }
      transactionDate
      status
      agreements {
        nextToken
        __typename
      }
      odooData
      paymongoData
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventCategory = /* GraphQL */ `
  mutation CreateEventCategory(
    $input: CreateEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    createEventCategory(input: $input, condition: $condition) {
      id
      eventId
      distance
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      categoryType
      categoryValue
      categoryImageURL {
        nextToken
        __typename
      }
      slots {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      raceKits {
        nextToken
        __typename
      }
      cutOffTime
      allowableQuantityPerOrder
      assemblyTime
      gunTime
      regPrice {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      categoryPrizes {
        __typename
      }
      form {
        nextToken
        __typename
      }
      registrants {
        nextToken
        __typename
      }
      routeImageURL {
        nextToken
        __typename
      }
      waiverForm {
        nextToken
        __typename
      }
      rulesAndRegulations {
        nextToken
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      raceBibPreFix
      raceResults {
        id
        belongsTo
        description
        title
        createdAt
        updatedAt
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventCategory = /* GraphQL */ `
  mutation UpdateEventCategory(
    $input: UpdateEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    updateEventCategory(input: $input, condition: $condition) {
      id
      eventId
      distance
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      categoryType
      categoryValue
      categoryImageURL {
        nextToken
        __typename
      }
      slots {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      raceKits {
        nextToken
        __typename
      }
      cutOffTime
      allowableQuantityPerOrder
      assemblyTime
      gunTime
      regPrice {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      categoryPrizes {
        __typename
      }
      form {
        nextToken
        __typename
      }
      registrants {
        nextToken
        __typename
      }
      routeImageURL {
        nextToken
        __typename
      }
      waiverForm {
        nextToken
        __typename
      }
      rulesAndRegulations {
        nextToken
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      raceBibPreFix
      raceResults {
        id
        belongsTo
        description
        title
        createdAt
        updatedAt
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventCategory = /* GraphQL */ `
  mutation DeleteEventCategory(
    $input: DeleteEventCategoryInput!
    $condition: ModelEventCategoryConditionInput
  ) {
    deleteEventCategory(input: $input, condition: $condition) {
      id
      eventId
      distance
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      categoryType
      categoryValue
      categoryImageURL {
        nextToken
        __typename
      }
      slots {
        categoryId
        eventId
        totalSlotsAvailable
        totalRegistrants
        createdAt
        updatedAt
        __typename
      }
      raceKits {
        nextToken
        __typename
      }
      cutOffTime
      allowableQuantityPerOrder
      assemblyTime
      gunTime
      regPrice {
        categoryId
        eventId
        createdAt
        updatedAt
        __typename
      }
      categoryPrizes {
        __typename
      }
      form {
        nextToken
        __typename
      }
      registrants {
        nextToken
        __typename
      }
      routeImageURL {
        nextToken
        __typename
      }
      waiverForm {
        nextToken
        __typename
      }
      rulesAndRegulations {
        nextToken
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      raceBibPreFix
      raceResults {
        id
        belongsTo
        description
        title
        createdAt
        updatedAt
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRaceKit = /* GraphQL */ `
  mutation CreateRaceKit(
    $input: CreateRaceKitInput!
    $condition: ModelRaceKitConditionInput
  ) {
    createRaceKit(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      images {
        nextToken
        __typename
      }
      kitType
      description
      displaySequence
      optionName
      optionValues
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRaceKit = /* GraphQL */ `
  mutation UpdateRaceKit(
    $input: UpdateRaceKitInput!
    $condition: ModelRaceKitConditionInput
  ) {
    updateRaceKit(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      images {
        nextToken
        __typename
      }
      kitType
      description
      displaySequence
      optionName
      optionValues
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRaceKit = /* GraphQL */ `
  mutation DeleteRaceKit(
    $input: DeleteRaceKitInput!
    $condition: ModelRaceKitConditionInput
  ) {
    deleteRaceKit(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      images {
        nextToken
        __typename
      }
      kitType
      description
      displaySequence
      optionName
      optionValues
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventPrize = /* GraphQL */ `
  mutation CreateEventPrize(
    $input: CreateEventPrizeInput!
    $condition: ModelEventPrizeConditionInput
  ) {
    createEventPrize(input: $input, condition: $condition) {
      id
      eventId
      prizeType
      imageURL {
        nextToken
        __typename
      }
      prizeValue {
        prizeAmountType
        prizeAmountValue
        __typename
      }
      prizeCategory {
        prizeCategoryType
        prizeCategoryValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventPrize = /* GraphQL */ `
  mutation UpdateEventPrize(
    $input: UpdateEventPrizeInput!
    $condition: ModelEventPrizeConditionInput
  ) {
    updateEventPrize(input: $input, condition: $condition) {
      id
      eventId
      prizeType
      imageURL {
        nextToken
        __typename
      }
      prizeValue {
        prizeAmountType
        prizeAmountValue
        __typename
      }
      prizeCategory {
        prizeCategoryType
        prizeCategoryValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventPrize = /* GraphQL */ `
  mutation DeleteEventPrize(
    $input: DeleteEventPrizeInput!
    $condition: ModelEventPrizeConditionInput
  ) {
    deleteEventPrize(input: $input, condition: $condition) {
      id
      eventId
      prizeType
      imageURL {
        nextToken
        __typename
      }
      prizeValue {
        prizeAmountType
        prizeAmountValue
        __typename
      }
      prizeCategory {
        prizeCategoryType
        prizeCategoryValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActivity = /* GraphQL */ `
  mutation CreateActivity(
    $input: CreateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    createActivity(input: $input, condition: $condition) {
      id
      belongsTo
      action
      field
      oldValue
      newValue
      userId
      performedBy {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      performedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActivity = /* GraphQL */ `
  mutation UpdateActivity(
    $input: UpdateActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    updateActivity(input: $input, condition: $condition) {
      id
      belongsTo
      action
      field
      oldValue
      newValue
      userId
      performedBy {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      performedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActivity = /* GraphQL */ `
  mutation DeleteActivity(
    $input: DeleteActivityInput!
    $condition: ModelActivityConditionInput
  ) {
    deleteActivity(input: $input, condition: $condition) {
      id
      belongsTo
      action
      field
      oldValue
      newValue
      userId
      performedBy {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      performedAt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCategorySlotsInventory = /* GraphQL */ `
  mutation CreateCategorySlotsInventory(
    $input: CreateCategorySlotsInventoryInput!
    $condition: ModelCategorySlotsInventoryConditionInput
  ) {
    createCategorySlotsInventory(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      totalSlotsAvailable
      totalRegistrants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCategorySlotsInventory = /* GraphQL */ `
  mutation UpdateCategorySlotsInventory(
    $input: UpdateCategorySlotsInventoryInput!
    $condition: ModelCategorySlotsInventoryConditionInput
  ) {
    updateCategorySlotsInventory(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      totalSlotsAvailable
      totalRegistrants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCategorySlotsInventory = /* GraphQL */ `
  mutation DeleteCategorySlotsInventory(
    $input: DeleteCategorySlotsInventoryInput!
    $condition: ModelCategorySlotsInventoryConditionInput
  ) {
    deleteCategorySlotsInventory(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      totalSlotsAvailable
      totalRegistrants
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrationPrice = /* GraphQL */ `
  mutation CreateRegistrationPrice(
    $input: CreateRegistrationPriceInput!
    $condition: ModelRegistrationPriceConditionInput
  ) {
    createRegistrationPrice(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      prices {
        type
        amountInCent
        currency
        enabled
        priceValidFrom
        priceValidTo
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistrationPrice = /* GraphQL */ `
  mutation UpdateRegistrationPrice(
    $input: UpdateRegistrationPriceInput!
    $condition: ModelRegistrationPriceConditionInput
  ) {
    updateRegistrationPrice(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      prices {
        type
        amountInCent
        currency
        enabled
        priceValidFrom
        priceValidTo
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistrationPrice = /* GraphQL */ `
  mutation DeleteRegistrationPrice(
    $input: DeleteRegistrationPriceInput!
    $condition: ModelRegistrationPriceConditionInput
  ) {
    deleteRegistrationPrice(input: $input, condition: $condition) {
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      prices {
        type
        amountInCent
        currency
        enabled
        priceValidFrom
        priceValidTo
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrationDiscount = /* GraphQL */ `
  mutation CreateRegistrationDiscount(
    $input: CreateRegistrationDiscountInput!
    $condition: ModelRegistrationDiscountConditionInput
  ) {
    createRegistrationDiscount(input: $input, condition: $condition) {
      id
      eventId
      categoryId
      discountName
      discountDesc
      discountData {
        type
        amountInCent
        currency
        priceValidFrom
        priceValidTo
        __typename
      }
      enabled
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistrationDiscount = /* GraphQL */ `
  mutation UpdateRegistrationDiscount(
    $input: UpdateRegistrationDiscountInput!
    $condition: ModelRegistrationDiscountConditionInput
  ) {
    updateRegistrationDiscount(input: $input, condition: $condition) {
      id
      eventId
      categoryId
      discountName
      discountDesc
      discountData {
        type
        amountInCent
        currency
        priceValidFrom
        priceValidTo
        __typename
      }
      enabled
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistrationDiscount = /* GraphQL */ `
  mutation DeleteRegistrationDiscount(
    $input: DeleteRegistrationDiscountInput!
    $condition: ModelRegistrationDiscountConditionInput
  ) {
    deleteRegistrationDiscount(input: $input, condition: $condition) {
      id
      eventId
      categoryId
      discountName
      discountDesc
      discountData {
        type
        amountInCent
        currency
        priceValidFrom
        priceValidTo
        __typename
      }
      enabled
      quantity
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createForm = /* GraphQL */ `
  mutation CreateForm(
    $input: CreateFormInput!
    $condition: ModelFormConditionInput
  ) {
    createForm(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      formOrderNumber
      formFields {
        fieldType
        fieldId
        validationType
        label
        orderNumber
        placeholder
        type
        defaultValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateForm = /* GraphQL */ `
  mutation UpdateForm(
    $input: UpdateFormInput!
    $condition: ModelFormConditionInput
  ) {
    updateForm(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      formOrderNumber
      formFields {
        fieldType
        fieldId
        validationType
        label
        orderNumber
        placeholder
        type
        defaultValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteForm = /* GraphQL */ `
  mutation DeleteForm(
    $input: DeleteFormInput!
    $condition: ModelFormConditionInput
  ) {
    deleteForm(input: $input, condition: $condition) {
      id
      categoryId
      eventId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      formOrderNumber
      formFields {
        fieldType
        fieldId
        validationType
        label
        orderNumber
        placeholder
        type
        defaultValue
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      urlImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      urlImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      urlImage
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      fileUrls {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      fileUrls {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      belongsTo
      description
      title
      fileUrls {
        url
        type
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMockUpData = /* GraphQL */ `
  mutation CreateMockUpData(
    $input: CreateMockUpDataInput!
    $condition: ModelMockUpDataConditionInput
  ) {
    createMockUpData(input: $input, condition: $condition) {
      id
      eventId
      belongsTo
      type
      title
      description
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMockUpData = /* GraphQL */ `
  mutation UpdateMockUpData(
    $input: UpdateMockUpDataInput!
    $condition: ModelMockUpDataConditionInput
  ) {
    updateMockUpData(input: $input, condition: $condition) {
      id
      eventId
      belongsTo
      type
      title
      description
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMockUpData = /* GraphQL */ `
  mutation DeleteMockUpData(
    $input: DeleteMockUpDataInput!
    $condition: ModelMockUpDataConditionInput
  ) {
    deleteMockUpData(input: $input, condition: $condition) {
      id
      eventId
      belongsTo
      type
      title
      description
      body
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userSub
      firstName
      lastName
      fullName
      email
      password
      isEmailConfirmed
      birthDate
      organizedEvents {
        nextToken
        __typename
      }
      organization {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      phoneNumber
      about
      isPublic
      profilePhoto {
        id
        belongsTo
        description
        title
        urlImage
        createdAt
        updatedAt
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      primaryAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      billingShippingAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      addresses {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      registrantsData {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      subscription {
        organizations
        events
        organizationStaff
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userSub
      firstName
      lastName
      fullName
      email
      password
      isEmailConfirmed
      birthDate
      organizedEvents {
        nextToken
        __typename
      }
      organization {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      phoneNumber
      about
      isPublic
      profilePhoto {
        id
        belongsTo
        description
        title
        urlImage
        createdAt
        updatedAt
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      primaryAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      billingShippingAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      addresses {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      registrantsData {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      subscription {
        organizations
        events
        organizationStaff
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userSub
      firstName
      lastName
      fullName
      email
      password
      isEmailConfirmed
      birthDate
      organizedEvents {
        nextToken
        __typename
      }
      organization {
        nextToken
        __typename
      }
      registrations {
        nextToken
        __typename
      }
      phoneNumber
      about
      isPublic
      profilePhoto {
        id
        belongsTo
        description
        title
        urlImage
        createdAt
        updatedAt
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      primaryAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      billingShippingAddress {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      addresses {
        type
        addressLine1
        country
        region
        regionCode
        state
        stateCode
        city
        cityCode
        barangay
        barangayCode
        zipCode
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      registrantsData {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      subscription {
        organizations
        events
        organizationStaff
        __typename
      }
      activities {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserOrganizationEventJunction = /* GraphQL */ `
  mutation CreateUserOrganizationEventJunction(
    $input: CreateUserOrganizationEventJunctionInput!
    $condition: ModelUserOrganizationEventJunctionConditionInput
  ) {
    createUserOrganizationEventJunction(input: $input, condition: $condition) {
      id
      userId
      userEmail
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organizationId
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserOrganizationEventJunction = /* GraphQL */ `
  mutation UpdateUserOrganizationEventJunction(
    $input: UpdateUserOrganizationEventJunctionInput!
    $condition: ModelUserOrganizationEventJunctionConditionInput
  ) {
    updateUserOrganizationEventJunction(input: $input, condition: $condition) {
      id
      userId
      userEmail
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organizationId
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserOrganizationEventJunction = /* GraphQL */ `
  mutation DeleteUserOrganizationEventJunction(
    $input: DeleteUserOrganizationEventJunctionInput!
    $condition: ModelUserOrganizationEventJunctionConditionInput
  ) {
    deleteUserOrganizationEventJunction(input: $input, condition: $condition) {
      id
      userId
      userEmail
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      organizationId
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      creatorId
      organizationEmails {
        nextToken
        __typename
      }
      organizationProfilePhotoURLs {
        nextToken
        __typename
      }
      organizationName
      subscription {
        organizationStaff
        events
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      staffs {
        nextToken
        __typename
      }
      events {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      creatorId
      organizationEmails {
        nextToken
        __typename
      }
      organizationProfilePhotoURLs {
        nextToken
        __typename
      }
      organizationName
      subscription {
        organizationStaff
        events
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      staffs {
        nextToken
        __typename
      }
      events {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      creatorId
      organizationEmails {
        nextToken
        __typename
      }
      organizationProfilePhotoURLs {
        nextToken
        __typename
      }
      organizationName
      subscription {
        organizationStaff
        events
        __typename
      }
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      staffs {
        nextToken
        __typename
      }
      events {
        nextToken
        __typename
      }
      payouts {
        nextToken
        __typename
      }
      status
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserOrganizationWithRoles = /* GraphQL */ `
  mutation CreateUserOrganizationWithRoles(
    $input: CreateUserOrganizationWithRolesInput!
    $condition: ModelUserOrganizationWithRolesConditionInput
  ) {
    createUserOrganizationWithRoles(input: $input, condition: $condition) {
      id
      type
      userId
      userEmail
      userFirstName
      userLastName
      fullName
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserOrganizationWithRoles = /* GraphQL */ `
  mutation UpdateUserOrganizationWithRoles(
    $input: UpdateUserOrganizationWithRolesInput!
    $condition: ModelUserOrganizationWithRolesConditionInput
  ) {
    updateUserOrganizationWithRoles(input: $input, condition: $condition) {
      id
      type
      userId
      userEmail
      userFirstName
      userLastName
      fullName
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserOrganizationWithRoles = /* GraphQL */ `
  mutation DeleteUserOrganizationWithRoles(
    $input: DeleteUserOrganizationWithRolesInput!
    $condition: ModelUserOrganizationWithRolesConditionInput
  ) {
    deleteUserOrganizationWithRoles(input: $input, condition: $condition) {
      id
      type
      userId
      userEmail
      userFirstName
      userLastName
      fullName
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      organizationId
      organization {
        id
        creatorId
        organizationName
        status
        createdAt
        updatedAt
        __typename
      }
      status
      roles {
        role
        scope
        descriptions
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmailSchema = /* GraphQL */ `
  mutation CreateEmailSchema(
    $input: CreateEmailSchemaInput!
    $condition: ModelEmailSchemaConditionInput
  ) {
    createEmailSchema(input: $input, condition: $condition) {
      id
      belongsTo
      type
      email
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmailSchema = /* GraphQL */ `
  mutation UpdateEmailSchema(
    $input: UpdateEmailSchemaInput!
    $condition: ModelEmailSchemaConditionInput
  ) {
    updateEmailSchema(input: $input, condition: $condition) {
      id
      belongsTo
      type
      email
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmailSchema = /* GraphQL */ `
  mutation DeleteEmailSchema(
    $input: DeleteEmailSchemaInput!
    $condition: ModelEmailSchemaConditionInput
  ) {
    deleteEmailSchema(input: $input, condition: $condition) {
      id
      belongsTo
      type
      email
      verified
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventRegistrationOrder = /* GraphQL */ `
  mutation CreateEventRegistrationOrder(
    $input: CreateEventRegistrationOrderInput!
    $condition: ModelEventRegistrationOrderConditionInput
  ) {
    createEventRegistrationOrder(input: $input, condition: $condition) {
      id
      userEmail
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      lineItems {
        nextToken
        __typename
      }
      registrationState
      paymentStatus
      progress
      deliveryOption
      paymentInformation {
        orderId
        description
        paidAt
        createdAt
        updatedAt
        __typename
      }
      appliedDiscounts {
        discountId
        __typename
      }
      paymentRetries {
        sourceHookId
        timeStamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventRegistrationOrder = /* GraphQL */ `
  mutation UpdateEventRegistrationOrder(
    $input: UpdateEventRegistrationOrderInput!
    $condition: ModelEventRegistrationOrderConditionInput
  ) {
    updateEventRegistrationOrder(input: $input, condition: $condition) {
      id
      userEmail
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      lineItems {
        nextToken
        __typename
      }
      registrationState
      paymentStatus
      progress
      deliveryOption
      paymentInformation {
        orderId
        description
        paidAt
        createdAt
        updatedAt
        __typename
      }
      appliedDiscounts {
        discountId
        __typename
      }
      paymentRetries {
        sourceHookId
        timeStamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventRegistrationOrder = /* GraphQL */ `
  mutation DeleteEventRegistrationOrder(
    $input: DeleteEventRegistrationOrderInput!
    $condition: ModelEventRegistrationOrderConditionInput
  ) {
    deleteEventRegistrationOrder(input: $input, condition: $condition) {
      id
      userEmail
      eventId
      event {
        id
        eventName
        name
        eventShortDescription
        additionalNotes
        organizationId
        registrationEnd
        registrationStart
        hasSameRouteForAllCat
        eventInstance
        eventType
        dateOfEvent
        slotsAvailabilityIsSameForAllCategories
        canHaveMultipleRegistrantsInOneOrder
        maxRegistrantsInOneOrder
        hasSameFormForAllCategories
        metricUsedIsKm
        published
        isWaiverFormSameForAll
        isRulesAndRegulationsSameForAll
        addressLine1
        addressLine2
        barangay
        city
        state
        region
        country
        transactionFee
        transactionFeeType
        updatedBibNumberSequence
        createdAt
        updatedAt
        __typename
      }
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      lineItems {
        nextToken
        __typename
      }
      registrationState
      paymentStatus
      progress
      deliveryOption
      paymentInformation {
        orderId
        description
        paidAt
        createdAt
        updatedAt
        __typename
      }
      appliedDiscounts {
        discountId
        __typename
      }
      paymentRetries {
        sourceHookId
        timeStamp
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventRegistrationLineItem = /* GraphQL */ `
  mutation CreateEventRegistrationLineItem(
    $input: CreateEventRegistrationLineItemInput!
    $condition: ModelEventRegistrationLineItemConditionInput
  ) {
    createEventRegistrationLineItem(input: $input, condition: $condition) {
      id
      orderId
      eventId
      categoryId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      paymentPortion {
        type
        amountInCent
        currency
        __typename
      }
      registrationOrder {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      teamName
      bibNumber
      registrantData {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventRegistrationLineItem = /* GraphQL */ `
  mutation UpdateEventRegistrationLineItem(
    $input: UpdateEventRegistrationLineItemInput!
    $condition: ModelEventRegistrationLineItemConditionInput
  ) {
    updateEventRegistrationLineItem(input: $input, condition: $condition) {
      id
      orderId
      eventId
      categoryId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      paymentPortion {
        type
        amountInCent
        currency
        __typename
      }
      registrationOrder {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      teamName
      bibNumber
      registrantData {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventRegistrationLineItem = /* GraphQL */ `
  mutation DeleteEventRegistrationLineItem(
    $input: DeleteEventRegistrationLineItemInput!
    $condition: ModelEventRegistrationLineItemConditionInput
  ) {
    deleteEventRegistrationLineItem(input: $input, condition: $condition) {
      id
      orderId
      eventId
      categoryId
      category {
        id
        eventId
        distance
        categoryType
        categoryValue
        cutOffTime
        allowableQuantityPerOrder
        assemblyTime
        gunTime
        raceBibPreFix
        createdAt
        updatedAt
        __typename
      }
      paymentPortion {
        type
        amountInCent
        currency
        __typename
      }
      registrationOrder {
        id
        userEmail
        eventId
        userId
        registrationState
        paymentStatus
        progress
        deliveryOption
        createdAt
        updatedAt
        __typename
      }
      teamName
      bibNumber
      registrantData {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrantData = /* GraphQL */ `
  mutation CreateRegistrantData(
    $input: CreateRegistrantDataInput!
    $condition: ModelRegistrantDataConditionInput
  ) {
    createRegistrantData(input: $input, condition: $condition) {
      id
      lineItemId
      orderId
      eventId
      formOrderNumber
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      categoryId
      lineItem {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      age
      bibNumber
      userEmail
      gender
      userData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistrantData = /* GraphQL */ `
  mutation UpdateRegistrantData(
    $input: UpdateRegistrantDataInput!
    $condition: ModelRegistrantDataConditionInput
  ) {
    updateRegistrantData(input: $input, condition: $condition) {
      id
      lineItemId
      orderId
      eventId
      formOrderNumber
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      categoryId
      lineItem {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      age
      bibNumber
      userEmail
      gender
      userData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistrantData = /* GraphQL */ `
  mutation DeleteRegistrantData(
    $input: DeleteRegistrantDataInput!
    $condition: ModelRegistrantDataConditionInput
  ) {
    deleteRegistrantData(input: $input, condition: $condition) {
      id
      lineItemId
      orderId
      eventId
      formOrderNumber
      userId
      user {
        id
        userSub
        firstName
        lastName
        fullName
        email
        password
        isEmailConfirmed
        birthDate
        phoneNumber
        about
        isPublic
        createdAt
        updatedAt
        __typename
      }
      categoryId
      lineItem {
        id
        orderId
        eventId
        categoryId
        teamName
        bibNumber
        createdAt
        updatedAt
        __typename
      }
      agreements {
        nextToken
        __typename
      }
      activities {
        nextToken
        __typename
      }
      firstName
      lastName
      fullName
      age
      bibNumber
      userEmail
      gender
      userData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAgreement = /* GraphQL */ `
  mutation CreateAgreement(
    $input: CreateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    createAgreement(input: $input, condition: $condition) {
      id
      belongsTo
      type
      agreement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAgreement = /* GraphQL */ `
  mutation UpdateAgreement(
    $input: UpdateAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    updateAgreement(input: $input, condition: $condition) {
      id
      belongsTo
      type
      agreement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAgreement = /* GraphQL */ `
  mutation DeleteAgreement(
    $input: DeleteAgreementInput!
    $condition: ModelAgreementConditionInput
  ) {
    deleteAgreement(input: $input, condition: $condition) {
      id
      belongsTo
      type
      agreement
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegistrationOrderPayment = /* GraphQL */ `
  mutation CreateRegistrationOrderPayment(
    $input: CreateRegistrationOrderPaymentInput!
    $condition: ModelRegistrationOrderPaymentConditionInput
  ) {
    createRegistrationOrderPayment(input: $input, condition: $condition) {
      orderId
      source {
        id
        type
        __typename
      }
      billing {
        email
        name
        phone
        __typename
      }
      description
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      paidAt
      totalPaidAmount {
        type
        amountInCent
        currency
        __typename
      }
      breakDown {
        id
        distance
        quantity
        __typename
      }
      netAmount {
        type
        amountInCent
        currency
        __typename
      }
      taxes {
        type
        amountInCent
        currency
        __typename
      }
      fees {
        type
        amountInCent
        currency
        __typename
      }
      donations {
        type
        amountInCent
        currency
        __typename
      }
      discounts {
        type
        amountInCent
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegistrationOrderPayment = /* GraphQL */ `
  mutation UpdateRegistrationOrderPayment(
    $input: UpdateRegistrationOrderPaymentInput!
    $condition: ModelRegistrationOrderPaymentConditionInput
  ) {
    updateRegistrationOrderPayment(input: $input, condition: $condition) {
      orderId
      source {
        id
        type
        __typename
      }
      billing {
        email
        name
        phone
        __typename
      }
      description
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      paidAt
      totalPaidAmount {
        type
        amountInCent
        currency
        __typename
      }
      breakDown {
        id
        distance
        quantity
        __typename
      }
      netAmount {
        type
        amountInCent
        currency
        __typename
      }
      taxes {
        type
        amountInCent
        currency
        __typename
      }
      fees {
        type
        amountInCent
        currency
        __typename
      }
      donations {
        type
        amountInCent
        currency
        __typename
      }
      discounts {
        type
        amountInCent
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegistrationOrderPayment = /* GraphQL */ `
  mutation DeleteRegistrationOrderPayment(
    $input: DeleteRegistrationOrderPaymentInput!
    $condition: ModelRegistrationOrderPaymentConditionInput
  ) {
    deleteRegistrationOrderPayment(input: $input, condition: $condition) {
      orderId
      source {
        id
        type
        __typename
      }
      billing {
        email
        name
        phone
        __typename
      }
      description
      odoo {
        odooId
        odooExternalId
        data
        __typename
      }
      paidAt
      totalPaidAmount {
        type
        amountInCent
        currency
        __typename
      }
      breakDown {
        id
        distance
        quantity
        __typename
      }
      netAmount {
        type
        amountInCent
        currency
        __typename
      }
      taxes {
        type
        amountInCent
        currency
        __typename
      }
      fees {
        type
        amountInCent
        currency
        __typename
      }
      donations {
        type
        amountInCent
        currency
        __typename
      }
      discounts {
        type
        amountInCent
        currency
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createApp = /* GraphQL */ `
  mutation CreateApp(
    $input: CreateAppInput!
    $condition: ModelAppConditionInput
  ) {
    createApp(input: $input, condition: $condition) {
      featureToggles
      constants
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateApp = /* GraphQL */ `
  mutation UpdateApp(
    $input: UpdateAppInput!
    $condition: ModelAppConditionInput
  ) {
    updateApp(input: $input, condition: $condition) {
      featureToggles
      constants
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteApp = /* GraphQL */ `
  mutation DeleteApp(
    $input: DeleteAppInput!
    $condition: ModelAppConditionInput
  ) {
    deleteApp(input: $input, condition: $condition) {
      featureToggles
      constants
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
