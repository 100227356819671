import { useCallback, useState } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Grid, Card, Button, CardHeader, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import EmptyContent from 'components/EmptyContent';
import { userChoiceCategoriesTotalQuantitySelector, userChoiceCategoriesSelector } from 'modules/registration/selector';
import { createDraftRegistration } from 'modules/registration/actions';
import { Routes } from 'constants';
import { getPath, sortDistances, titleUrlFormatter } from 'utils';
import RegistrationCartSummary from './RegistrationCartSummary';
import SignupCategoryList from './SignupCategoryList';

// ----------------------------------------------------------------------

export default function NumberOfRegistrantsSelection({ event }) {
  const dispatch = useDispatch();
  const registrationOrderTotalQuantity = useSelector(userChoiceCategoriesTotalQuantitySelector);
  const useChoiceCategories = useSelector(userChoiceCategoriesSelector);

  const [loading, setLoading] = useState(false);
  const {
    metricUsedIsKm,
    mainEventImage,
    canHaveMultipleRegistrantsInOneOrder,
    categories,
    eventName,
    id: eventId,
    slotsAvailabilityIsSameForAllCategories,
    maxRegistrantsInOneOrder = 10, // TODO
  } = event;
  const eventCategories = categories?.items || [];

  const sortedCategoriesByDistance = sortDistances(eventCategories);

  const handleNextStep = useCallback(async () => {
    setLoading(true);
    await dispatch(createDraftRegistration(eventId));
    setLoading(false);
  }, [setLoading, useChoiceCategories, eventId, eventName]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card sx={{ mb: 3 }}>
          <CardHeader
            title={
              <Typography variant="h6">
                Select number of registrants
                <Typography component="span" sx={{ color: 'text.secondary' }}>
                  &nbsp;({registrationOrderTotalQuantity}&nbsp;registrants)
                </Typography>
              </Typography>
            }
            sx={{ mb: 3 }}
          />

          {event?.categories?.items?.length > 0 ? (
            <Scrollbar>
              <SignupCategoryList
                categories={sortedCategoriesByDistance}
                metricUsedIsKm={metricUsedIsKm}
                eventImages={mainEventImage?.items || []}
                canHaveMultipleRegistrantsInOneOrder={Boolean(canHaveMultipleRegistrantsInOneOrder)}
                registrationOrderTotalQuantity={registrationOrderTotalQuantity}
                slotsAvailabilityIsSameForAllCategories={slotsAvailabilityIsSameForAllCategories}
                maxRegistrantsInOneOrder={maxRegistrantsInOneOrder}
              />
            </Scrollbar>
          ) : (
            <EmptyContent
              title="Cart is empty"
              description="Look like you have no items in your registration cart."
              img="/assets/illustrations/illustration_empty_cart.svg"
            />
          )}
        </Card>

        <Button
          color="inherit"
          component={RouterLink}
          to={generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId })}
          startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
        >
          Back to event
        </Button>
      </Grid>

      <Grid item xs={12} md={4}>
        <RegistrationCartSummary />
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
          onClick={handleNextStep}
          disabled={!registrationOrderTotalQuantity || loading}
        >
          Next Step
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
