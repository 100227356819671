import { PaymentMethod } from 'constants/index';
import { TermsOfServiceMarkdown, PrivacyPolicyMarkdown } from './longTextConstants';

export const Attestations = {
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  DATA_SHARING_AGREEMENT: 'DATA_SHARING_AGREEMENT',
  PLATFORM_AGREEMENT: 'PLATFORM_AGREEMENT',
  EVENT_CREATION_AGREEMENT: 'EVENT_CREATION_AGREEMENT',
  GENERAL_EVENT_REGISTRATION_WAIVER: 'EVENT_CREATION_AGREEMENT',
  REGISTRATION_SIGNUP_PORTAL_AGREEMENT: 'REGISTRATION_SIGNUP_PORTAL_AGREEMENT',
};

// This is a redundant function but is required as we cannot use the one in here src/utils/index.js
const htmlToPlainText = (html) => {
  return html.replace(/<[^>]*>/g, '');
};

export const AttestationValues = {
  [Attestations.PRIVACY_POLICY]: {
    label: 'Privacy Policy',
    message: 'PRIVACY_POLICY',
    markDownValue: PrivacyPolicyMarkdown,
    textVal: htmlToPlainText(PrivacyPolicyMarkdown),
    type: Attestations.PRIVACY_POLICY,
  },
  [Attestations.TERMS_OF_SERVICE]: {
    label: 'Terms of Service',
    message: 'TERMS_OF_SERVICE',
    type: Attestations.TERMS_OF_SERVICE,
    textVal: htmlToPlainText(TermsOfServiceMarkdown),
    markDownValue: TermsOfServiceMarkdown,
  },
};

export const GeneralHashValue = {
  ORGANIZATION_ROUTE: 'organization_route',
};

export const PaymentOptionsMapper = [
  {
    value: PaymentMethod.GCASH,
    title: 'GCASH',
    description: 'You will be redirected to GCASH website to complete your purchase securely.',
  },
  {
    value: PaymentMethod.PAYMAYA,
    title: 'PAYMAYA',
    description: 'You will be redirected to PAYMAYA website to complete your purchase securely.',
  },
];
