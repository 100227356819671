import { createAction } from 'redux-actions';
import { Auth, Logger } from 'aws-amplify';
import fetch from 'utils/fetch';
import { createBrowserHistory } from 'history';
import { push, getLocation } from 'connected-react-router';
import { isAdminPortalSelector, profileSelector } from 'modules/app/selector';
import bcryptjs from 'bcryptjs';
import {
  GET_USER_DATA,
  UPDATE_USER_DATA,
  LOADING_USER,
  ADD_USER_TO_GROUP,
  HYDRATE_USER,
  FORGOT_PASSWORD,
  REGISTER_USER,
  CONFIRM_SIGNUP,
  SIGN_IN_USER,
  RESEND_CONFIRMATION,
  FORGOT_PASSWORD_SUBMIT,
  LOGOUT_USER,
  CHANGE_USER_PASSWORD,
  NO_USER_IS_LOADED,
  USER_ORGANIZED_EVENTS,
  USER_ORGANIZATIONS,
  SEARCH_USER,
  // Org
  ADD_NEW_ORGANIZATION_TO_LIST,
  ADD_TO_USER_ORGANIZATION_LIST,
  REMOVE_JUNCTION_ORGANIZATION,
  FETCH_USER_EVENT_JUNCTION,
  CHECK_USER,
} from 'modules/user/types';
import {
  removeSelectedOrganizationId,
  removeSelectedEventId,
  getStaffEventsList,
  // setSelectedOrganizationId,
  // setSelectedEventId,
} from 'modules/organization/actions';
import { createUser, createToken, verifyToken, updateUser, createAgreement } from 'graphql/mutations';
import { listUsers } from 'graphql/customQueries';
import {
  userDynamoIdSelector,
  primaryAddressSelector,
  userDataSelector,
  addressesSelector,
  billingShippingAddressSelector,
  userIdSelector,
} from 'modules/user/selector';
import qs from 'qs';
import { fetchUserEventOrder } from 'modules/registration/actions';
// import { createNote as createNoteMutation, deleteNote as deleteNoteMutation } from 'graphql/mutations';
import { createAlert } from 'modules/alerts/actions';
import {
  getPath,
  setLocalStorageItem,
  removeLocalStorageItem,
  getLocalStorageItem,
  clearLocalStorage,
  // isOrganizationsRoute,
  // isOrganizationRoute,
  // getOrganizationIdByPath,
  // pathIncludesEvent,
  // getEventIdByPath,
} from 'utils';
import {
  Routes,
  ResponseType,
  localStorageVariable,
  ProfileView,
  Roles,
  AttestationValues,
  // GeneralHashValue,
  // QueryParams,
} from 'constants/index';
import {
  UserErrorTypes,
  CognitoConstantErrors,
  UserSuccessTypes,
  userCustomAttributes,
  userSearchType,
} from 'modules/user/constants';
import { capitalizerOfWords } from 'utils/stringHelper';
import { setProfileView, setUserAdminEmail, setUserAdminRoles } from 'modules/app/actions';

const logger = new Logger('user/actions');

export const browserHistory = createBrowserHistory({ forceRefresh: true });

export const setUserLoading = createAction(LOADING_USER, (value) => (dispatch) => value);

export const hydrateUser = createAction(HYDRATE_USER, async (currentPath = '') => async (dispatch, getState) => {
  const state = getState();
  const reduxState = getState();
  const primaryAddress = primaryAddressSelector(reduxState);
  const billingShippingAddress = billingShippingAddressSelector(reduxState);
  let userData = userDataSelector(reduxState); // Assigning to let as we may modify this if user impersonated please see the logic below
  const addresses = addressesSelector(reduxState);
  const { pathname } = getLocation(state);
  const isRouteVerification = pathname === getPath(Routes.VERIFICATION);
  const isAdminPortal = isAdminPortalSelector(reduxState);

  dispatch(setUserLoading(true));
  await Auth.currentSession()
    .then(async (data) => {
      const userAdminRoles = data?.idToken?.payload?.['cognito:groups'] || [];
      const isUserAppAdmin = userAdminRoles?.includes(Roles.ADMIN);

      const impersonatedUser = getLocalStorageItem(localStorageVariable.USER_TO_IMPERSONATE);

      let userResponse = null;
      let isImpersonated = false;

      const isAdminAndImpersonated = isAdminPortal && isUserAppAdmin && impersonatedUser;

      if (isAdminPortal) {
        if (!isUserAppAdmin) {
          // Dispatch to route no access then logout user
          dispatch(createAlert('CUSTOM_ERROR', 'You are not assigned as administrator'));
          await Auth.signOut();
          clearLocalStorage();
          return;
        }

        dispatch(setUserAdminEmail(data?.idToken?.payload?.email));
        dispatch(setUserAdminRoles(userAdminRoles));

        if (!impersonatedUser) {
          dispatch(push(getPath(Routes.ADMIN_CHOOSE_USER)));
        } else {
          const filter = { email: { eq: `${impersonatedUser}` } };

          userResponse = await fetch
            .graphql({
              query: listUsers,
              variables: { filter },
            })
            .catch((err) => {
              logger.error('Error fetching user', err);
            });

          const userDataRes = userResponse?.data?.listUsers?.items?.[0];

          userData = {
            ...userDataRes,
            sub: userDataRes.userSub,
            isUserImpersonated: true,
            profilePhoto: userDataRes?.profilePhoto,
          };
          isImpersonated = true;

          if (!userDataRes?.id) {
            removeLocalStorageItem(localStorageVariable.USER_TO_IMPERSONATE);
            dispatch(createAlert('CUSTOM_ERROR', 'No user exist with this email on our database'));
            return dispatch(push(getPath(Routes.ADMIN_CHOOSE_USER)));
          }
        }
      } else {
        const userFilter = { email: { eq: `${data?.idToken?.payload?.email}` } };
        userResponse = await fetch
          .graphql({
            query: listUsers,
            variables: { filter: userFilter },
          })
          .catch((err) => {
            logger.error('Error fetching user', err);
          });
      }

      const userDataFromDynamo = userResponse?.data?.listUsers?.items?.[0] || null;

      const dataToPopulate = {
        ...userData,
        ...(!isImpersonated && data?.idToken?.payload && data?.idToken?.payload),
        // ...userDataFromDynamo,
        id: userDataFromDynamo?.id,
        profilePhoto: userDataFromDynamo?.profilePhoto,
        about: userDataFromDynamo?.about,
        subscription: userDataFromDynamo?.subscription,
        address:
          {
            ...addresses,
            primaryAddress: {
              ...primaryAddress,
              ...(userDataFromDynamo?.primaryAddress && userDataFromDynamo?.primaryAddress),
            },
            billingShippingAddress: {
              ...billingShippingAddress,
              ...(userDataFromDynamo?.billingShippingAddress && userDataFromDynamo?.billingShippingAddress),
            },
          } || '',
      };

      delete dataToPopulate?.password;

      dispatch(dispatchUserData(dataToPopulate, true));

      dispatch(dispatchUserOrganizedEvents(userDataFromDynamo?.organizedEvents?.items || []));

      dispatch(dispatchUserOrganization(userDataFromDynamo?.organization?.items || []));

      const redirectPathAfterLogin = getLocalStorageItem(localStorageVariable.REDIRECT_PATH_AFTER_LOGIN);

      // TODO as of the moment logic is when user is redirecting to organization without having login credentials
      // if (redirectPathAfterLogin) {
      //   if (isOrganizationsRoute(redirectPathAfterLogin)) {
      //     dispatch(setProfileView(ProfileView.organization));

      //     const orgId = getOrganizationIdByPath(redirectPathAfterLogin);
      //     if (isOrganizationRoute(redirectPathAfterLogin) && orgId) {
      //       dispatch(setSelectedOrganizationId(orgId));
      //       const eventId = getEventIdByPath(redirectPathAfterLogin);

      //       if (eventId && pathIncludesEvent(redirectPathAfterLogin)) {
      //         dispatch(setSelectedEventId(orgId));
      //       }
      //     }
      //   }

      //   removeLocalStorageItem(localStorageVariable.REDIRECT_PATH_AFTER_LOGIN);
      //   dispatch(push(redirectPathAfterLogin));
      // }

      // TODO if there's nextToken in userDataFromDynamo?.organizedEvents?.items query more
      // TODO if there's nextToken in userDataFromDynamo?.organization?.items

      if (isAdminAndImpersonated) {
        dispatch(push(getPath(Routes.EVENTS)));
      }
    })
    .catch(async (error) => {
      console.log(error);
      const token = getLocalStorageItem(localStorageVariable.token);

      if (error?.includes('No current user') && Boolean(token?.length)) {
        await fetch
          .graphql({
            query: verifyToken,
            variables: { args: { token, shouldCreateNewOne: true } },
          })
          .then(async (response) => {
            const payloadResponse = JSON.parse(response?.data?.verifyToken) || {};
            const payloadResponseData = payloadResponse?.data?.verifyToken || {};
            const payloadResponseAttributes = payloadResponse?.data?.attributes || {};
            const user = payloadResponseData?.user || {};
            const newToken = payloadResponseAttributes?.newToken || '';

            if (user?.email || user?.user?.email) {
              const filter = { email: { eq: `${user?.email || user?.user?.email}` } };

              const userResponse = await fetch.graphql({
                query: listUsers,
                variables: { filter },
              });
              const userDataFromDynamo = userResponse?.data?.listUsers?.items?.[0] || null;
              if (userDataFromDynamo) {
                dispatch(dispatchUserData(null, true, true, { ...userDataFromDynamo, isEmailVerified: false }));

                if (!isRouteVerification) {
                  dispatch(push(currentPath ?? getPath(Routes.EVENTS)));
                }
              } else {
                logger.info('No user found in database from token');
                dispatch(push(getPath(Routes.LOGIN)));
              }
            } else {
              console.log('Session Token Expired');
              dispatch(push(getPath(Routes.LOGIN)));
            }

            // save token locally
            if (newToken) {
              setLocalStorageItem({ [localStorageVariable.token]: newToken });
            }
          })
          .catch((err) => {
            console.log('Testing', err);
            console.log('Session Token Expired', err);
            dispatch(push(getPath(Routes.LOGIN)));
          });

        return;
      }

      // if (currentPath && isOrganizationsRoute(currentPath)) {
      //   const queryParams = { [QueryParams.REDIRECT_PATH_AFTER_LOGIN]: currentPath };
      //   return dispatch(
      //     push(`${getPath(Routes.LOGIN)}?${qs.stringify(queryParams)}#${GeneralHashValue.ORGANIZATION_ROUTE}`)
      //   );
      // }

      await Auth.signOut();
      clearLocalStorage();
      dispatch(noUserIsLoaded());
      if (isAdminPortal) {
        dispatch(push(getPath(Routes.LOGIN)));
      }
    });
  dispatch(setUserLoading(false));
});

export const isUserExist = createAction(CHECK_USER, async (email = '') => async () => {
  const filter = { email: { eq: `${email}` } };

  const userResponse = await fetch.graphql({
    query: listUsers,
    variables: { filter },
  });

  const userDataFromDynamo = userResponse?.data?.listUsers?.items?.[0] || null;

  return Boolean(userDataFromDynamo);
});

export const noUserIsLoaded = createAction(NO_USER_IS_LOADED);

export const dispatchUserOrganizedEvents = createAction(USER_ORGANIZED_EVENTS, (organizedEvents) => organizedEvents);

export const dispatchUserOrganization = createAction(USER_ORGANIZATIONS, (organizations) => organizations);

export const dispatchUserData = createAction(
  GET_USER_DATA,
  (userData, shouldAuthenticate = true, hasCustomData = false, customData = {}) =>
    (dispatch, getState) => {
      if (!hasCustomData) {
        const {
          primaryAddress,
          id,
          email,
          address,
          email_verified: isEmailVerifiedCognito,
          phone_number: phoneNumberCognito,
          phone_number_verified: isPhoneNumberVerified,
          auth_time: authTime,
          [userCustomAttributes.firstName]: firstName,
          [userCustomAttributes.lastName]: lastName,
          ['sub' || 'cognito:username']: userSub,
          profilePhoto,
          about,
          subscription,
          isEmailConfirmed,
          phoneNumber,
          isUserImpersonated = false,
        } = userData;
        return {
          user: {
            id,
            email: email || '',
            isEmailVerified: isEmailVerifiedCognito || isEmailConfirmed || false,
            userId: userSub || '',
            phoneNumber: phoneNumberCognito || phoneNumber || '',
            isPhoneNumberVerified: isPhoneNumberVerified || false,
            authTime: authTime || '',
            firstName: firstName || '',
            lastName: lastName || '',
            address: {
              ...address,
              ...(primaryAddress && primaryAddress),
            },
            profilePhoto,
            about,
            ...(isUserImpersonated && userData),
          },
          subscription,
          shouldAuthenticate,
        };
      }

      const user = { ...customData, userId: customData?.userSub || '' };
      if (user?.subscription !== undefined) {
        delete user?.subscription;
      }

      return {
        user,
        subscription: customData?.subscription,
        shouldAuthenticate,
      };
    }
);

export const signUpUser = createAction(
  REGISTER_USER,
  async (formData, hasOwnRedirect = false) =>
    async (dispatch, getState) => {
      const { firstName, lastName, email, password, phoneNumber } = formData;
      dispatch(setUserLoading(true));
      console.log(firstName, lastName, email, password, phoneNumber);

      let userDataFromDynamo = {};

      // 1. Register user in Cognito
      return await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          [userCustomAttributes.firstName]: capitalizerOfWords(firstName),
          [userCustomAttributes.lastName]: capitalizerOfWords(lastName),
          phone_number: `+${phoneNumber}`,
          [userCustomAttributes.country]: 'Philippines', // TODO country mapping
        },
        autoSignIn: {
          enabled: true,
        },
      })
        .then(async (user) => {
          const { userSub } = user;

          // 2. Provision user data in dynamo
          // 2.1 Hash
          const salt = await bcryptjs.genSalt(10);

          const hashedPassword = await bcryptjs.hash(password, salt);

          const fullName = `${firstName} ${lastName}`.toLowerCase();

          const useToStore = {
            email,
            firstName: capitalizerOfWords(firstName),
            lastName: capitalizerOfWords(lastName),
            fullName,
            phoneNumber: `+${phoneNumber}`,
            isEmailConfirmed: false,
            password: hashedPassword,
            subscription: {
              organizations: 1,
              events: 1,
              organizationStaff: 10,
            },
            userSub,
            primaryAddress: {
              country: 'Philippines',
            },
          };

          await fetch
            .graphql({
              query: createUser,
              variables: { input: useToStore },
            })
            .then(async (response) => {
              userDataFromDynamo = response?.data?.createUser || {};
              console.log('User', response);

              // 3. Create token for the user - Call lambda
              const createPromises = [];

              createPromises.push(
                await fetch
                  .graphql({
                    query: createToken,
                    variables: { args: { user: { email, userSub } } },
                  })
                  .then((response) => {
                    // 4. Set token to local storage
                    const generatedToken = JSON.parse(response?.data?.createToken)?.data?.createToken?.token || '';
                    setLocalStorageItem({ [localStorageVariable.token]: generatedToken });
                  })
                  .catch((error) => {
                    console.log('Error', error);
                    dispatch(createAlert('CUSTOM_WARNING', 'Cannot Create a token for the moment'));
                  })
              );

              // 3.1. Create User Agreements Privacy Policy and Terms of Service
              createPromises.push(
                ...Object.entries(AttestationValues).reduce(async (acc, [key, attestation]) => {
                  acc.push(
                    await fetch.graphql({
                      query: createAgreement,
                      variables: {
                        input: {
                          belongsTo: userDataFromDynamo?.id,
                          type: attestation.type,
                          agreement: JSON.stringify({
                            plainText: attestation?.textVal || '',
                            htmlText: attestation?.markDownValue || '',
                          }),
                        },
                      },
                    })
                  );

                  return acc;
                }, [])
              );

              await Promise.all(createPromises);
            })
            .catch((err) => {
              logger.error('Error in creating user in the data base:', err);
            });

          dispatch(
            dispatchUserData(null, true, true, {
              ...useToStore,
              id: userDataFromDynamo?.id || '',
              isEmailVerified: false,
            })
          );

          // 4. Redirect user to verification
          dispatch(createAlert('CUSTOM_WARNING', 'We need to verify that your email is active'));
          if (!hasOwnRedirect) dispatch(push(getPath(Routes.VERIFICATION)));

          dispatch(setUserLoading(false));
        })
        .catch((error) => {
          logger.error('error signing up:', error);

          const errorToCheck = error.toString();
          const errorType = errorToCheck.split(':')[0];

          if (errorType === CognitoConstantErrors.UsernameExistsException) {
            return {
              success: false,
              errors: [
                {
                  type: 'error', // for Form
                  message: 'Username already exists, try to login',
                },
              ],
            };
          }
          errorMapping(error, REGISTER_USER, dispatch);
          dispatch(noUserIsLoaded());
          dispatch(setUserLoading(false));
        });
    }
);

export const verifySignUp = createAction(
  CONFIRM_SIGNUP,
  async (email, code, hasOwnRedirect = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const isAdminPortal = isAdminPortalSelector(state);
      try {
        await Auth.confirmSignUp(email, String(code));
        removeLocalStorageItem(localStorageVariable.token);
        if (isAdminPortal) {
          return dispatch(hydrateUser());
        }
        await dispatch(updateUserData({ isEmailConfirmed: true }));
        if (!hasOwnRedirect) {
          dispatch(createAlert(UserSuccessTypes.emailSuccessfullyVerified));
        } else {
          dispatch(createAlert('CUSTOM_SUCCESS', 'We successfully verify your email please login now!'));
        }
        if (!hasOwnRedirect) {
          dispatch(push(getPath(Routes.LOGIN)));
        }
      } catch (error) {
        logger.error('error confirming sign up:', error);
        errorMapping(error, CONFIRM_SIGNUP, dispatch);
      }
    }
);

export const signInUser = createAction(
  SIGN_IN_USER,
  async (email, password, hasOwnRedirect = false, locationParams = null, options = {}) =>
    async (dispatch, getState) => {
      let hasErrors = false;
      const actionResponse = {
        type: '',
        message: '',
      };

      const redirectPath = options?.redirectPath;
      const state = getState();
      const isAdminPortal = isAdminPortalSelector(state);
      const profile = profileSelector(state);
      try {
        let userDataFromDynamo = {};

        dispatch(setUserLoading(true));
        clearLocalStorage();
        return await Auth.signIn(email, password)
          .then(async (user) => {
            removeLocalStorageItem(localStorageVariable.token);

            if (isAdminPortal) {
              return dispatch(hydrateUser());
            }
            const filter = { email: { eq: `${email}` } };

            await fetch
              .graphql({
                query: listUsers,
                variables: { filter },
              })
              .then(async (response) => {
                const items = response?.data?.listUsers?.items || null;
                if (items && items?.length) {
                  userDataFromDynamo = items?.[0];
                } else {
                  const userRes = await fetch.graphql({
                    query: createUser,
                    variables: { input: { email } },
                  });
                  userDataFromDynamo = userRes?.data?.createUser || {};
                }
              });

            // dispatch(dispatchUserData({ ...user.attributes, ...userDataFromDynamo }));
            dispatch(dispatchUserOrganizedEvents(userDataFromDynamo?.organizedEvents?.items || []));
            dispatch(dispatchUserOrganization(userDataFromDynamo?.organization?.items || []));

            if (locationParams && locationParams?.eventId) {
              await dispatch(fetchUserEventOrder(locationParams?.eventId));
            }

            // Has Organization redirect path
            // if (redirectPath) {
            //   setLocalStorageItem({ [localStorageVariable.REDIRECT_PATH_AFTER_LOGIN]: redirectPath });
            // } else

            if (!hasOwnRedirect) {
              if (profile === ProfileView.organization) {
                dispatch(removeSelectedOrganizationId());
                dispatch(removeSelectedEventId());
                dispatch(push(getPath(Routes.ORGANIZATIONS_DASHBOARD)));
              } else {
                dispatch(push(getPath(Routes.EVENTS)));
              }
            }
            window.location.reload();
            dispatch(setUserLoading(false));
          })
          .catch(async (error) => {
            const errorToCheck = error.toString();
            const errorType = errorToCheck.split(':')[0];

            if (errorType === CognitoConstantErrors.UserNotConfirmedException) {
              // 1. Get user data from dynamo if user doesn't confirm yet
              const filter = { email: { eq: `${email}` } };

              await fetch
                .graphql({
                  query: listUsers,
                  variables: { filter },
                })
                .then(async (response) => {
                  const items = response?.data?.listUsers?.items || null;
                  const promises = [];

                  const user = items?.[0];

                  if (user && user?.password) {
                    // 1.1 Check authentication
                    const isMatched = await bcryptjs.compare(password, user?.password);

                    if (isMatched) {
                      const { password: passwordInDb, ...restUserData } = user;
                      userDataFromDynamo = { ...restUserData };
                    } else {
                      dispatch(noUserIsLoaded());
                      hasErrors = true;
                      actionResponse.type = 'Error';
                      actionResponse.message = 'Wrong email or password.';
                      dispatch(createAlert('Error', actionResponse.message));
                      return;
                    }
                  }

                  // 1.2. create new token for the user
                  promises.push(
                    await fetch.graphql({
                      query: createToken,
                      variables: { args: { user: { email } } },
                    })
                  );

                  if (!user) {
                    // 1.3. Create user data in dynamo if user wasn't found
                    // 2.1 Hash
                    const salt = await bcryptjs.genSalt(10);

                    const hashedPassword = await bcryptjs.hash(password, salt);

                    promises.push(
                      await fetch.graphql({
                        query: createUser,
                        variables: { input: { email, password: hashedPassword } },
                      })
                    );
                  }

                  const promiseResponse = await Promise.all(promises);
                  const generatedToken =
                    JSON.parse(promiseResponse?.[0]?.data?.createToken)?.data?.createToken?.token || '';
                  const createdUserInDynamo = promiseResponse?.[1]?.data?.createUser || null;

                  if (generatedToken) {
                    setLocalStorageItem({ [localStorageVariable.token]: generatedToken });
                  }
                  if (createdUserInDynamo) {
                    userDataFromDynamo = createdUserInDynamo;
                  }
                });

              if (!hasErrors) {
                dispatch(dispatchUserData(null, true, true, { ...userDataFromDynamo, isEmailVerified: false }));
                if (hasOwnRedirect) {
                  return {
                    success: false,
                    type: 'Error',
                    cognitoErrorType: CognitoConstantErrors.UserNotConfirmedException,
                    message: 'We need to verify your Email first!',
                  };
                }

                dispatch(push(getPath(Routes.EVENTS)));
              }

              return actionResponse;
            }
            dispatch(noUserIsLoaded());
            return errorMapping(error, SIGN_IN_USER, dispatch);
          });
      } catch (error) {
        dispatch(setUserLoading(false));
        dispatch(noUserIsLoaded());
        logger.error('error confirming sign up:', error);
        errorMapping(error, SIGN_IN_USER, dispatch);
      }
    }
);

export const resendConfirmation = createAction(RESEND_CONFIRMATION, async (email) => async (dispatch, getState) => {
  try {
    await Auth.resendSignUp(email);
    logger.info('code resent successfully');
    dispatch(createAlert(UserSuccessTypes.resendConfirmationSuccess));
  } catch (error) {
    logger.error('error resendConfirmation :', error);
    errorMapping(error, RESEND_CONFIRMATION, dispatch);
  }
});

export const updateUserData = createAction(UPDATE_USER_DATA, async (formData) => async (dispatch, getState) => {
  const reduxState = getState();
  const userDynamoId = userDynamoIdSelector(reduxState);
  const userPrimaryAddress = primaryAddressSelector(reduxState);
  const userBillingShippingAddress = billingShippingAddressSelector(reduxState);
  const userData = userDataSelector(reduxState);
  const addresses = addressesSelector(reduxState);
  try {
    const {
      about,
      addressLine1,
      barangay,
      barangayCode,
      city,
      cityCode,
      country,
      firstName,
      isPublic,
      lastName,
      phoneNumber,
      region,
      regionCode,
      state,
      stateCode,
      zipCode,
      primaryAddress = true,
      billingShippingAddress = false,
      isEmailConfirmed = false,
    } = formData;

    const dataToUpdateInCognito = {
      ...(firstName && { [userCustomAttributes.firstName]: capitalizerOfWords(firstName) }),
      ...(lastName && { [userCustomAttributes.lastName]: capitalizerOfWords(lastName) }),
      ...(about && { [userCustomAttributes.about]: about }),
      ...(phoneNumber && { phone_number: phoneNumber }),
      ...(region && primaryAddress && { [userCustomAttributes.region]: region }),
      ...(regionCode && primaryAddress && { [userCustomAttributes.regionCode]: regionCode }),
      ...(state && primaryAddress && { [userCustomAttributes.state]: state }),
      ...(stateCode && primaryAddress && { [userCustomAttributes.stateCode]: stateCode }),
      ...(city && primaryAddress && { [userCustomAttributes.city]: city }),
      ...(cityCode && primaryAddress && { [userCustomAttributes.cityCode]: cityCode }),
      ...(country && primaryAddress && { [userCustomAttributes.country]: country }),
      ...(barangay && primaryAddress && { [userCustomAttributes.barangay]: barangay }),
      ...(barangayCode && primaryAddress && { [userCustomAttributes.barangayCode]: barangayCode }),
      ...(isPublic && primaryAddress && { [userCustomAttributes.isPublic]: isPublic }),
      ...(zipCode && primaryAddress && { [userCustomAttributes.zipCode]: zipCode }),
      ...(addressLine1 && primaryAddress && { [userCustomAttributes.addressLine1]: addressLine1 }),
    };

    const fullName = `${firstName} ${lastName}`.toLowerCase();

    const dataToUpdateInDynamo = {
      id: userDynamoId,
      ...(about && { about }),
      ...(firstName && { firstName: capitalizerOfWords(firstName) }),
      ...(isPublic && { isPublic }),
      ...(lastName && { lastName: capitalizerOfWords(lastName) }),
      ...(lastName && firstName && { fullName }),
      ...(phoneNumber && { phoneNumber }),
      ...(primaryAddress &&
        addressLine1 && {
          primaryAddress: {
            addressLine1,
            barangay,
            barangayCode,
            city,
            cityCode,
            country,
            region,
            regionCode,
            state,
            stateCode,
            zipCode,
          },
        }),
      ...(billingShippingAddress &&
        addressLine1 && {
          billingShippingAddress: {
            type: 'billingShippingAddress',
            addressLine1,
            barangay,
            barangayCode,
            city,
            cityCode,
            country,
            region,
            regionCode,
            state,
            stateCode,
            zipCode,
          },
        }),
      ...(isEmailConfirmed && { isEmailConfirmed }),
    };

    const promises = [];

    if (Object.keys(dataToUpdateInCognito).length) {
      const user = await Auth.currentAuthenticatedUser();
      promises.push(await Auth.updateUserAttributes(user, dataToUpdateInCognito));
    }
    if (Object.keys(dataToUpdateInDynamo).length) {
      promises.push(await fetch.graphql({ query: updateUser, variables: { input: dataToUpdateInDynamo } }));
    }

    await Promise.all(promises);

    dispatch(
      dispatchUserData(null, true, true, {
        ...userData,
        id: userDynamoId,
        ...(about && { about }),
        ...(firstName && { firstName }),
        ...(isPublic && { isPublic }),
        ...(lastName && { lastName }),
        ...(phoneNumber && { phoneNumber }),
        ...(isEmailConfirmed && { isEmailVerified: isEmailConfirmed }),
        address: {
          ...addresses,
          ...(primaryAddress && {
            primaryAddress: {
              ...userPrimaryAddress,
              ...dataToUpdateInDynamo.primaryAddress,
            },
          }),
          ...(billingShippingAddress && {
            billingShippingAddress: {
              ...userBillingShippingAddress,
              ...dataToUpdateInDynamo.billingShippingAddress,
            },
          }),
        },
      })
    );
    return ResponseType.success;
  } catch (error) {
    logger.error('Error on updating user info :', error);
    errorMapping(error, UPDATE_USER_DATA, dispatch);
    return ResponseType.failed;
  }
});

export const logoutUser = createAction(LOGOUT_USER, (isAdminChooseUserRoute = false) => async (dispatch, getState) => {
  try {
    const state = getState();
    const isAdminPortal = isAdminPortalSelector(state);
    const profile = profileSelector(state);

    const impersonatedUser = getLocalStorageItem(localStorageVariable.USER_TO_IMPERSONATE);

    if (isAdminPortal) {
      if (impersonatedUser) {
        removeLocalStorageItem(localStorageVariable.USER_TO_IMPERSONATE);
        dispatch(setProfileView(ProfileView.user));
      }
      if (!isAdminChooseUserRoute) {
        return window.location.reload(false);
      }
    }

    await Auth.signOut();
    clearLocalStorage();

    if (isAdminPortal) {
      // Refresh the page
      return window.location.reload(false);
    }

    const isOrganizationProfile = profile === ProfileView.organization;

    if (isOrganizationProfile) {
      dispatch(setProfileView(ProfileView.user));
    }

    dispatch(push(getPath(Routes.EVENTS)));
  } catch (error) {
    logger.error('error logging out: ', error);
    errorMapping(error, LOGOUT_USER, dispatch);
  }
});

export const changeUserPassword = createAction(
  CHANGE_USER_PASSWORD,
  async (oldPassword, newPassword) => async (dispatch, getState) => {
    await Auth.currentAuthenticatedUser()
      .then(async (user) => {
        await Auth.changePassword(user, oldPassword, newPassword)
          .then(() => {
            dispatch(createAlert('CUSTOM_SUCCESS', 'Password Successfully Updated'));
          })
          .catch((error) => {
            console.log('Error', error);
            errorMapping(error, CHANGE_USER_PASSWORD, dispatch);
          });
      })
      .catch((error) => {
        console.log('Error 2nd', error);
        errorMapping(error, CHANGE_USER_PASSWORD, dispatch);
      });
  }
);

export const forgotUserPassword = createAction(FORGOT_PASSWORD, async (email) => async (dispatch, getState) => {
  try {
    await Auth.forgotPassword(email);
    logger.info('Email resent successfully');
    dispatch(createAlert(UserSuccessTypes.resendConfirmationSuccess));
  } catch (error) {
    logger.error('error forgotUserPassword :', error);
    errorMapping(error, FORGOT_PASSWORD, dispatch);
  }
});

export const forgotUserPasswordSubmit = createAction(
  FORGOT_PASSWORD_SUBMIT,
  async (email, code, newPassword, hasOwnRedirect = false) =>
    async (dispatch, getState) => {
      try {
        await Auth.forgotPasswordSubmit(email, String(code), newPassword);
        logger.info('Password Changed successfully');
        dispatch(createAlert(UserSuccessTypes.forgotPasswordChangeSuccess));
        if (!hasOwnRedirect) dispatch(push(getPath(Routes.LOGIN)));
      } catch (error) {
        logger.error('error forgotUserPasswordSubmit :', error);
        errorMapping(error, FORGOT_PASSWORD_SUBMIT, dispatch);
      }
    }
);

export const addUserToGroup = createAction(
  ADD_USER_TO_GROUP,
  async (email, groupName) => async (dispatch, getState) => {
    const state = getState();

    try {
      // await Auth.confirmSignUp(email, String(code));
      removeLocalStorageItem(localStorageVariable.token);
      // if (!hasOwnRedirect) dispatch(createAlert(UserSuccessTypes.emailSuccessfullyVerified));
      // else dispatch(createAlert('CUSTOM_SUCCESS', 'We successfully verify your email please login now!'));
      // if (!hasOwnRedirect) dispatch(push(getPath(Routes.LOGIN)));
    } catch (error) {
      logger.error('error confirming sign up:', error);
      errorMapping(error, CONFIRM_SIGNUP, dispatch);
    }
  }
);

export const searchUserByKey = createAction(
  SEARCH_USER,
  async (searchString, searchType = userSearchType.email, isEmailConfirmed) =>
    async (dispatch, getState) => {
      const state = getState();

      try {
        const filterByEmail = { email: { eq: `${searchString.trim().toLowerCase()}` } };
        const filterByName = { fullName: { contains: `${searchString}`.trim().toLowerCase() } };

        const filter =
          searchType === userSearchType.email ? filterByEmail : searchType === userSearchType.name ? filterByName : {};

        const addedFilter = isEmailConfirmed !== undefined ? { isEmailConfirmed: { eq: isEmailConfirmed } } : {};

        const userResponse = await fetch.graphql({
          query: listUsers,
          variables: { filter: { ...filter, ...addedFilter } },
        });

        return { error: '', list: userResponse?.data?.listUsers ?? [] };
      } catch (error) {
        console.log('Error:', error.message);
        return { error: 'No user found', list: [] };
      }
    }
);

export const addNewOrganizationToList = createAction(
  ADD_NEW_ORGANIZATION_TO_LIST,
  async (organizationJunctionData) => async (dispatch) => organizationJunctionData
);

export const addToUserOrganizationList = createAction(
  ADD_TO_USER_ORGANIZATION_LIST,
  async (organizationUserJunction) => async () => organizationUserJunction
);

export const removeOrganizationJunction = createAction(
  REMOVE_JUNCTION_ORGANIZATION,
  async (junctionId) => async () => junctionId
);

export const fetchUserEventJunctions = createAction(
  FETCH_USER_EVENT_JUNCTION,
  async () => async (dispatch, getState) => {
    const state = getState();

    const userId = userIdSelector(state);
    try {
      const filter = userId ? { userId: { eq: userId } } : undefined;
      const list = await dispatch(getStaffEventsList(filter));
      dispatch(dispatchUserOrganizedEvents(list?.payload || []));
    } catch (err) {
      console.log('err:', err);
      dispatch(createAlert('CUSTOM_ERROR', `We are having trouble`));
      return false;
    }
  }
);

const errorMapping = (error, actionType, dispatch) => {
  const errorToCheck = error.toString();

  if (errorToCheck.includes(': ')) {
    const getMessage = errorToCheck.split(': ')[1];
    dispatch(createAlert('CustomAlert', getMessage));
  } else if (errorToCheck.includes(CognitoConstantErrors.InvalidParameterException)) {
    dispatch(createAlert(CognitoConstantErrors.InvalidParameterException));
  } else if (errorToCheck.includes(CognitoConstantErrors.LimitExceededException)) {
    dispatch(createAlert(CognitoConstantErrors.LimitExceededException));
  } else if (errorToCheck.includes(CognitoConstantErrors.UsernameExistsException)) {
    dispatch(createAlert(CognitoConstantErrors.UsernameExistsException));
  } else if (errorToCheck.includes(CognitoConstantErrors.UserNotFoundException)) {
    dispatch(createAlert(CognitoConstantErrors.UserNotFoundException));
  } else if (errorToCheck.includes(CognitoConstantErrors.CodeMismatchException)) {
    dispatch(createAlert(CognitoConstantErrors.CodeMismatchException));
  } else if (actionType === RESEND_CONFIRMATION) {
    dispatch(createAlert(UserErrorTypes.resendConfirmationFail));
  } else if (actionType === FORGOT_PASSWORD) {
    dispatch(createAlert(UserErrorTypes.resendConfirmationFail));
  } else if (actionType === REGISTER_USER) {
    dispatch(createAlert(UserErrorTypes.signUpError));
  } else {
    dispatch(createAlert(UserErrorTypes.defaultError));
  }
};
