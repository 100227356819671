import moment from 'moment-timezone';
import {
  ColorType,
  UIColors,
  OrganizationStaffRoleType,
  Status,
  StaffEventRole,
  Routes,
  countries,
} from 'constants/index';
import { getPath } from './routes';

export * from './localStorage';
export * from './routes';
export * from './common/formatNumber';
export * from './timeHelper';
export * from './text';
export * from './formValidation';
export * from './categoryHelper';
export * from './common/helpers/priceHelper';
export * from './currencyHelper';
export * from './stringHelper';
export * from './eventHelper';
export * from './userHelper';
export * from './imageUtils';
export * from './appHelper';
export * from './common';
export * from './common/helpers/categoryHelper';
export * from './arrayHelper';
export * from './objectHelper';
export * from './fileHelper';
export * from './ageHelper';

export const emptyFunction = () => {};

const adminConstantValues = [Status.APPROVED, OrganizationStaffRoleType.ADMIN, StaffEventRole.ADMIN];
const memberConstantValues = [Status.PENDING, OrganizationStaffRoleType.MEMBER, StaffEventRole.MEMBER];

export const removeEmptyFieldsInObj = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const getConstantValueByKeyOrValue = (Constant, keyOrValue) =>
  Object.entries(Constant)
    ?.map(([key, value]) => ({ key, value }))
    ?.find((item) => item.key === keyOrValue || item.value === keyOrValue) || { key: '', value: '' };

export const getLabelColor = (key) =>
  adminConstantValues.includes(key) ? 'success' : memberConstantValues.includes(key) ? 'warning' : 'default';

export const colorMapper = (type) => {
  const colors = Object.keys(UIColors);
  const getColor = colors.find((key) => ColorType[key].list.includes(type));

  return ColorType?.[getColor]?.color || 'default';
};

export const getRandomColor = () => {
  const colors = Object.keys(UIColors);

  return shuffle(colors)[0];
};

export const equateTheColorArrFromOtherArray = (array) => {
  const colors = Object.keys(UIColors);
  return array.map((_, i) => colors[i % colors.length]);
};

export function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const whiteListedRoutes = [
  getPath(Routes.VERIFICATION),
  getPath(Routes.PRIVACY_POLICY),
  getPath(Routes.TERMS_OF_SERVICE),
  getPath(Routes.Page403),
  getPath(Routes.Page404),
  getPath(Routes.Page500),
];

export const isAdminHelper = (roles = []) =>
  (roles || []).some(
    (role) => role?.role === OrganizationStaffRoleType.ADMIN || role?.role === OrganizationStaffRoleType.CREATOR
  );

export const hasCreatorRole = (roles = []) =>
  (roles || []).some((role) => role?.role === OrganizationStaffRoleType.CREATOR);

export const filterByRoleHelper = (roles = [], type = OrganizationStaffRoleType.ADMIN) =>
  (roles || []).some((role) => role?.role === type);

export const filterWithObjectFieldInObject = (objectToPopulate = {}, objectToCheck = {}) => {
  const newObj = Object.entries(objectToPopulate)?.reduce((acc, [key, value]) => {
    if (Object.prototype.hasOwnProperty.call(objectToCheck, key)) {
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, []);

  return newObj;
};

/**
 * Empty Items
 */
export const emptyObject = Object.freeze({});

/**
 * Get object without particular keys
 */
export const omitProps = (object, propNames) => {
  return Object.keys(object).reduce((acc, key) => {
    if (propNames.includes(key)) return acc;
    acc[key] = object[key];
    return acc;
  }, {});
};

/**
 * Get object without all keys except particular list
 */
export const pickProps = (object, propNames) => {
  return propNames.reduce((acc, key) => {
    acc[key] = object[key];
    return acc;
  }, {});
};

export const removeDuplicateObject = (array = [], indicator = '') => {
  if (!indicator) {
    return array;
  }

  return array?.filter((value, index, self) => index === self.findIndex((t) => t?.[indicator] === value?.[indicator]));
};

export const htmlToPlainText = (html) => {
  return html.replace(/<[^>]*>/g, '');
};

export const sortByField = (arr = [], field = 'createdAt', sortType = 'DESC') => {
  return arr.sort((a, b) => {
    let valueA = a?.[field];
    let valueB = b?.[field];

    const areValidValues = Boolean(valueA && valueB);

    // Check if the field is a date type
    if (
      areValidValues &&
      moment(valueA, moment.ISO_8601, true).isValid() &&
      moment(valueB, moment.ISO_8601, true).isValid()
    ) {
      valueA = moment(valueA);
      valueB = moment(valueB);
    } else {
      valueA = valueA || '';
      valueB = valueB || '';
    }

    if (sortType === 'ASC') {
      return valueA - valueB;
    }
    return valueB - valueA;
  });
};

export const isSameDateOrAfterToday = (date) => {
  const eventDate = moment(date).startOf('day');
  const today = moment().startOf('day'); // Start of today

  return today.isSameOrAfter(eventDate);
};

export const sortObjectsByFieldAndOrder = (inputArray = [], typeOrder = [], fieldName) => {
  // Create a mapping from type to index
  const typeIndexMap = {};
  typeOrder?.forEach((type, index) => {
    typeIndexMap[type] = index;
  });

  // Sort the input array based on the mapped index of the specified field
  const sortedArray = inputArray?.sort((a, b) => {
    const aIndex = typeIndexMap?.[a?.[fieldName]];
    const bIndex = typeIndexMap?.[b?.[fieldName]];
    return aIndex - bIndex;
  });

  return sortedArray;
};

export const humanReadableList = (arr, formatWith = 'and') => {
  let outStr = '';
  if (arr.length === 1) {
    outStr = arr[0];
  } else if (arr.length === 2) {
    // Joins all with "and" but no commas
    // Example: "bob and sam"
    outStr = arr.join(` ${formatWith} `);
  } else {
    // Joins elements with commas and adds "and" before the last element
    // Example: "alice, bob, and sam"
    outStr = `${arr.slice(0, -1).join(', ')} ${formatWith} ${arr[arr.length - 1]}`;
  }
  return outStr;
};

export const arrayInArrayOrderingIsInValid = (arrOne, arrTwo) => {
  return arrOne?.some((item, index) => item && item !== arrTwo?.[index]);
};

export const checkDuplicateInArray = (arr) => {
  return [...new Set(arr.filter((elem, idx, arr) => arr.indexOf(elem) !== idx))];
};

export const arraysMatch = (array1 = [], array2 = []) =>
  array1.length === array2.length && array1.every((item) => array2.includes(item));

export const sortArrayByArray = (array1 = [], array2 = []) => {
  const orderMap = new Map();
  array2.forEach((item, index) => orderMap.set(item, index));

  return array1.sort((a, b) => {
    if (orderMap.has(a) && orderMap.has(b)) {
      return orderMap.get(a) - orderMap.get(b);
    }

    if (orderMap.has(a)) {
      return -1;
    }

    if (orderMap.has(b)) {
      return 1;
    }

    return 0;
  });
};

// NOTE: Below Logic goes like this
// const splitsHeaders = [1, 2, "", "", 5, 6, 7, 8];
// const splitIndex = 3;
// const output = getNearestValueBefore(splitsHeaders, splitIndex);
// console.log(output); // Output: 2

export const getNearestValueBefore = (arr = [], index = 0) => {
  const nearestIndex = arr.slice(0, index).findLastIndex((value) => {
    return !!value;
  });
  return nearestIndex !== -1 ? { value: arr[nearestIndex], index: nearestIndex } : null;
};

export const getCountryISOCode = (countryName) => {
  const countryCode = countries.find((country) => country.label === countryName)?.code;
  return countryCode;
};

export const getHostUrl = () => {
  const host = `${window.location.protocol}//${window.location.host}`;
  return host;
};
