import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { DynamicForm as DynamicFormCreator } from 'components/hook-form';
import { ProgressPage, defaultCategoryForm } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  upsertRegistrantData,
  updateEventRegistrationOrder,
  getUserRegisteredParticipantsData,
  deleteLineItem,
} from 'modules/registration/actions';
import { Box, Grid, Button, Typography, Stack, Collapse } from '@mui/material';
import { userDataSelector } from 'modules/user/selector';
import { LoadingButton } from '@mui/lab';
import { _addressBooks } from '_mock';
import Label from 'components/Label';
import Iconify from 'components/Iconify';
import { isAllFormsFilledInSelector, userRegisteredParticipantsDataSelector } from 'modules/registration/selector';
import useIsMountedRef from 'hooks/useIsMountedRef';
import CardWithDelete from 'components/CardWithDelete';
import GeneralModal from 'components/GeneralModal';
import {
  formatCategoryValue,
  sortDistances,
  sortFormFields,
  sortForms,
  sortFormsByLatestUpdate,
  sortSortedDistanceWithData,
} from 'utils';
import ModalResetProgress from './ModalResetProgress';
import RegistrationCartSummary from './RegistrationCartSummary';

export default function RegistrationForm({
  registrationDetailLineItems = [],
  eventCategories = [],
  metricUsedIsKm,
  isCartSummaryFetchedSuccess = false,
  handleBackClick: handleBackClickProps = null,
  shouldBackToEvent: handleShouldBackToEvent = false,
}) {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();

  const authUserData = useSelector(userDataSelector);
  const participants = useSelector(userRegisteredParticipantsDataSelector);
  const isAllFormsFilledIn = useSelector(isAllFormsFilledInSelector);

  const [openBackModalForReset, setOpenBackModalForReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enabledNext, setEnabledNext] = useState(false);
  const [editableCard, setEditableCard] = useState(isAllFormsFilledIn ? null : 0);

  const shouldBackToEvent = handleShouldBackToEvent && handleBackClickProps;

  const sortedLineItemsByDistance = sortSortedDistanceWithData(
    sortDistances(
      registrationDetailLineItems?.reduce(
        (acc, item) => [
          ...acc,
          {
            ...item,
            distance: item?.category?.distance || '',
            registrantDataLength: item?.registrantData?.items?.length || 0,
          },
        ],
        []
      )
    )
  );

  const eventCategoryMapping = useCallback(
    (categoryId) => eventCategories.find((category) => category?.id === categoryId),
    [eventCategories]
  );

  useEffect(
    () => {
      if (isMountedRef.current) {
        (async () => {
          await dispatch(getUserRegisteredParticipantsData());
        })();
      }

      return () => {
        isMountedRef.current = false;
      };
    },
    // eslint-disable-next-line
    [dispatch, isMountedRef.current]
  );

  // enable disable next button
  useEffect(() => {
    if (sortedLineItemsByDistance?.length && eventCategories?.length) {
      const booleanArray = sortedLineItemsByDistance?.map((item) => {
        const registrantsList = item?.registrantData?.items || [];
        // TODO display only one Form and sort it to most updated as sometimes setting up for form in admins side has some issues in deleting
        // FIXME Redo this logic once multiple forms are implemented
        // const categoryFormLength = eventCategoryMapping(item?.categoryId)?.form?.items?.length || null;
        const categoryFormLength = 1;
        if (categoryFormLength) {
          return registrantsList?.length === categoryFormLength;
        }
        return false;
      });
      setEnabledNext(booleanArray.some((val) => !val)); // if there's a false in array
    }
  }, [sortedLineItemsByDistance, eventCategories, eventCategoryMapping]);

  const handleModalState = useCallback(() => {
    setOpenBackModalForReset((prev) => !prev);
  }, []);

  const handleNextStep = useCallback(async () => {
    setLoading(true);
    await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
    setLoading(false);
  }, []);

  return (
    <>
      {openBackModalForReset && <ModalResetProgress onClose={handleModalState} open={openBackModalForReset} />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              size="small"
              color="inherit"
              onClick={shouldBackToEvent ? handleBackClickProps : handleModalState}
              startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}
            >
              Back
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          {sortedLineItemsByDistance?.map((lineItem, index) => (
            <FormDetails
              key={index}
              index={index}
              lineItemCategory={lineItem}
              lineItemsLength={sortedLineItemsByDistance?.length || 0}
              category={eventCategoryMapping(lineItem?.categoryId) || {}}
              handleNextStep={handleNextStep}
              setEnabledNext={setEnabledNext}
              metricUsedIsKm={metricUsedIsKm}
              authUserData={authUserData}
              prefillOptions={participants || []}
              isAllFormsFilledIn={isAllFormsFilledIn}
              setEditableCard={setEditableCard}
              editableCard={editableCard}
            />
          ))}
        </Grid>

        <Grid item xs={12} md={4}>
          <RegistrationCartSummary sx={{ mb: 6 }} />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading}
            onClick={handleNextStep}
            disabled={enabledNext || !isCartSummaryFetchedSuccess}
            sx={{ mb: 4 }}
          >
            Next Step
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}

const FormDetails = ({
  lineItemCategory,
  category,
  index,
  lineItemsLength,
  handleNextStep,
  setEnabledNext,
  metricUsedIsKm,
  authUserData,
  prefillOptions = [],
  isAllFormsFilledIn,
  editableCard = 0,
  setEditableCard,
}) => {
  const dynamicFormCreatorRef = useRef();

  const dispatch = useDispatch();
  const [editable, setEditable] = useState(index === 0); // Open first collapsable
  const [openForm, setOpenForm] = useState(1); // Open first collapsable
  const [removeItemOpen, setRemoveItemOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const registrantsData = lineItemCategory?.registrantData?.items || [];

  const openCard = editable || editableCard === index || lineItemsLength === 1;

  const lastCard = lineItemsLength === index + 1;

  const lineItemId = lineItemCategory?.id;

  const shouldDisplayName =
    !editable &&
    lineItemsLength > 1 &&
    registrantsData?.length &&
    registrantsData?.some((item) => item?.lineItemId === lineItemId);

  const sortedCategoryFormByFormNumber = sortForms(
    category?.form?.items?.length ? category?.form?.items : [defaultCategoryForm]
  );

  // TODO display only one Form and sort it to most updated as sometimes setting up for form in admins side has some issues in deleting
  // FIXME Redo this logic once multiple forms are implemented

  const categoryForms = [sortFormsByLatestUpdate(sortedCategoryFormByFormNumber)?.[0]];

  const categoryFormFields = (form) => sortFormFields(form?.formFields || []);

  const onSubmitActionHandler = useCallback(
    async (
      values,
      valuesHasChanged,
      formNumber,
      registrantDataId,
      teamTitleHasChanged,
      currentLineItemIndexPlusOne,
      formIndex
    ) => {
      // TODO check if there's really a change of data
      await dispatch(
        upsertRegistrantData(
          values,
          valuesHasChanged,
          formNumber,
          lineItemCategory?.id || '',
          category?.id,
          registrantDataId,
          teamTitleHasChanged,
          registrantsData?.map((item) => item?.id)
        )
      ).then(() => {
        const lastCategoryForm = categoryForms?.length === formIndex + 1;
        const lastFormNotLastCard = !lastCard && lastCategoryForm;
        const currentCardIndexOpened = currentLineItemIndexPlusOne - 1;
        const nextCardToOpen = !lastCard ? currentLineItemIndexPlusOne : currentCardIndexOpened;

        if (lastFormNotLastCard) {
          handleOpeCard(nextCardToOpen);
        }

        setOpenForm(lastCategoryForm ? currentLineItemIndexPlusOne + 1 : 1);
      });
    },
    [dispatch, lineItemCategory, category, lastCard, categoryForms]
  );

  const handleRemoveItem = useCallback(async () => {
    setIsDeleting(true);
    await dispatch(deleteLineItem(lineItemCategory?.id));
    triggerChildFunction();
    setIsDeleting(false);
    removalConfirmation();
  }, [dispatch, lineItemCategory]);

  const removalConfirmation = useCallback(async () => {
    setRemoveItemOpen((prev) => !prev);
  }, []);

  const handleOpeCard = useCallback(
    (nextCardToOpen) => {
      let updatedEditableValue = !editable;

      if (nextCardToOpen !== undefined) {
        updatedEditableValue = false;
        setEditableCard(nextCardToOpen);
      }

      setEditable(updatedEditableValue);
    },
    [editable]
  );

  const handleEditableClick = useCallback(() => {
    setEditable((prev) => !prev);
  }, []);

  const triggerChildFunction = () => {
    if (dynamicFormCreatorRef.current) {
      dynamicFormCreatorRef.current.handleResetForm();
    }
  };

  const registrantData = (form) =>
    registrantsData?.length
      ? registrantsData.find(
          (registrant) =>
            registrant?.formOrderNumber === form?.formOrderNumber &&
            registrant?.lineItemId === lineItemId &&
            registrant?.categoryId === category?.id
        )
      : {};

  return (
    <>
      <GeneralModal
        open={Boolean(removeItemOpen)}
        id="delete-item-modal-display"
        title="Confirm removal of item"
        message={'Are you sure you want to remove this item ?'}
        buttonOneText="Cancel"
        buttonTwoText="Remove"
        buttonOneClick={removalConfirmation}
        buttonTwoClick={handleRemoveItem}
        loading={isDeleting}
      />
      <CardWithDelete
        sx={{ p: 3, mb: 3, position: 'relative', border: 1 }}
        onDelete={removalConfirmation}
        displayDelete={lineItemsLength > 1}
      >
        <Label color="info" sx={{ ml: 1 }}>
          {formatCategoryValue(metricUsedIsKm, category)}
        </Label>

        <Stack spacing={1} direction="column" sx={{ m: 1, position: 'relative' }}>
          {shouldDisplayName
            ? registrantsData?.map((data) => {
                const userData = data?.userData
                  ? typeof data?.userData === 'string'
                    ? JSON.parse(data?.userData)
                    : data?.userData
                  : {};
                return (
                  <Box key={data.id} sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">{`${userData?.firstName || ''} ${
                      userData?.lastName || ''
                    }`}</Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      &nbsp;({userData?.gender || ''})
                    </Typography>
                  </Box>
                );
              })
            : null}
        </Stack>

        {categoryForms?.map((form, ind) => {
          return (
            <Collapse in={openCard} sx={{ width: 1 }} key={ind}>
              <DynamicFormCreator
                formFields={categoryFormFields(form)}
                currentLineItemIndexPlusOne={index + 1}
                formIndex={ind}
                registrantData={registrantData(form)}
                teamName={registrantsData?.find((registrant) => registrant?.teamName)?.teamName ?? ''}
                numberOfForms={categoryForms?.length}
                formNumber={form?.formOrderNumber}
                openForm={openForm}
                setOpenForm={setOpenForm}
                lineItemsLength={lineItemsLength}
                onSubmitActionHandler={onSubmitActionHandler}
                nextPageCall={handleNextStep}
                setEnabledNext={setEnabledNext}
                authUserData={authUserData}
                prefillOptions={prefillOptions}
                isAllFormsFilledIn={isAllFormsFilledIn}
                lastCard={lastCard}
                ref={dynamicFormCreatorRef}
              />
            </Collapse>
          );
        })}

        {lineItemsLength > 1 && (
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              ...(!openCard && { position: { sm: 'absolute' }, right: { sm: 24 }, bottom: { sm: 24 } }),
            }}
          >
            <Button variant="outlined" size="small" onClick={handleEditableClick}>
              {openCard ? 'Minimize' : 'Edit'}
            </Button>
            <Box sx={{ mx: 0.5 }} />
          </Box>
        )}
      </CardWithDelete>
    </>
  );
};

FormDetails.propTypes = {
  lineItemCategory: PropTypes.any,
  category: PropTypes.any,
  index: PropTypes.number,
};
