import { useState, useEffect, useCallback } from 'react';

const PAYMONGO_API_URL = 'https://api.paymongo.com/v1';

// Payment intent status types
const PAYMENT_STATUS = {
  AWAITING_PAYMENT_METHOD: 'awaiting_payment_method',
  AWAITING_NEXT_ACTION: 'awaiting_next_action',
  PROCESSING: 'processing',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  CANCELLED: 'cancelled',
};

const usePaymentIntentStatus = (paymentIntentId, base64PaymongoPrivateKey) => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Function to fetch payment intent status
  const fetchPaymentIntent = useCallback(async () => {
    if (!paymentIntentId || !base64PaymongoPrivateKey) {
      setError('Missing required parameters');
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(`${PAYMONGO_API_URL}/payment_intents/${paymentIntentId}`, {
        headers: {
          Authorization: `Basic ${base64PaymongoPrivateKey}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.errors?.[0]?.detail || 'Failed to fetch payment intent');
      }

      const { data } = await response.json();
      const paymentIntentStatus = data.attributes.status;

      console.log('Payment Intent Status:', paymentIntentStatus);
      setStatus(paymentIntentStatus);

      return paymentIntentStatus === PAYMENT_STATUS.PROCESSING;
    } catch (err) {
      console.error('Payment Intent Error:', err);
      setError(err.message);
      return false;
    } finally {
      setIsLoading(false);
    }
  }, [paymentIntentId, base64PaymongoPrivateKey]);

  // Handle iframe messages
  const handleMessage = useCallback(
    async (event) => {
      // Log all incoming messages for debugging
      console.log('Received message:', event.origin, event.data);

      // Check if the message is from PayMongo's domain
      if (!event.origin.includes('paymongo.com')) {
        return;
      }

      // Check for successful 3DS authentication message
      if (typeof event.data === 'string' && event.data.includes('3DS-authentication-complete')) {
        console.log('3DS authentication completed');

        // Start polling for payment status
        let attempts = 0;
        const maxAttempts = 15; // 30 seconds total
        const pollInterval = setInterval(async () => {
          attempts += 1;

          try {
            const shouldContinue = await fetchPaymentIntent();

            // Stop polling if we're no longer processing or reached max attempts
            if (!shouldContinue || attempts >= maxAttempts) {
              clearInterval(pollInterval);

              if (attempts >= maxAttempts && status === PAYMENT_STATUS.PROCESSING) {
                setError('Payment processing timeout');
              }
            }
          } catch (err) {
            clearInterval(pollInterval);
            console.error('Polling error:', err);
          }
        }, 2000);

        // Cleanup interval after 30 seconds
        setTimeout(() => {
          clearInterval(pollInterval);
        }, 30000);
      }
    },
    [fetchPaymentIntent, status]
  );

  // Set up event listener
  useEffect(() => {
    if (!paymentIntentId || !base64PaymongoPrivateKey) {
      return;
    }

    // Add event listener for iframe messages
    window.addEventListener('message', handleMessage);

    // Initial fetch
    fetchPaymentIntent();

    // Cleanup
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [paymentIntentId, base64PaymongoPrivateKey, handleMessage, fetchPaymentIntent]);

  // Reset state when payment intent ID changes
  useEffect(() => {
    setStatus(null);
    setError(null);
    setIsLoading(false);
  }, [paymentIntentId]);

  return {
    status,
    error,
    isLoading,
    isProcessing: status === PAYMENT_STATUS.PROCESSING,
    isSucceeded: status === PAYMENT_STATUS.SUCCEEDED,
    isFailed: status === PAYMENT_STATUS.FAILED,
    isCancelled: status === PAYMENT_STATUS.CANCELLED,
  };
};

export default usePaymentIntentStatus;
