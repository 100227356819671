import { createAction } from 'redux-actions';
import { Logger } from 'aws-amplify';
import { createBrowserHistory } from 'history';
import {
  INITIALIZE_APP,
  SET_PROFILE_VIEW,
  SET_FEATURE_TOGGLES,
  GET_APP,
  SET_APP_CONSTANTS,
  SET_USER_ADMIN_ROLES,
  SET_USER_ADMIN_EMAIL,
  SET_REDIRECT,
} from 'modules/app/types';
import { createAlert } from 'modules/alerts/actions';
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from 'utils/index';
import { localStorageVariable, ProfileView } from 'constants/index';
import { hydrateUser } from 'modules/user/actions';
import { getOrganization, setSelectedOrganizationId, setSelectedEventId } from 'modules/organization/actions';
import { fetchEventData } from 'modules/event/actions';
import { listApps } from 'graphql/customQueries';
import fetch from 'utils/fetch';

const logger = new Logger('user/actions');

export const browserHistory = createBrowserHistory({ forceRefresh: true });

export const initializeApp = createAction(INITIALIZE_APP, async (path) => async (dispatch) => {
  try {
    const promises = [];

    await dispatch(getApp());
    promises.push(await dispatch(hydrateUser(path)));

    // set profile
    const profile = getLocalStorageItem(localStorageVariable.profile);
    dispatch(setProfileView(profile));

    const selectedOrganizationId = getLocalStorageItem(localStorageVariable.selectedOrganizationId);
    const selectedEventId = getLocalStorageItem(localStorageVariable.selectedEventId);

    if (profile === ProfileView.organization) {
      if (selectedOrganizationId) {
        dispatch(setSelectedOrganizationId(selectedOrganizationId));
        promises.push(await dispatch(getOrganization(path))); // getOrganization
      }
      if (selectedEventId) {
        dispatch(setSelectedEventId(selectedEventId));
        promises.push(await dispatch(fetchEventData(selectedEventId))); // getEvent
      }
    }

    await Promise.all(promises);
    return true;
  } catch (err) {
    dispatch(createAlert('CustomAlert', `We can't load page as of the moment"`));
    return false;
  }
});

export const setProfileView = createAction(SET_PROFILE_VIEW, async (profile) => async () => {
  setLocalStorageItem({ [localStorageVariable.profile]: profile ?? ProfileView.user });
  if (profile === ProfileView.user) {
    removeLocalStorageItem(localStorageVariable.selectedEventId);
    removeLocalStorageItem(localStorageVariable.selectedOrganizationId);
  }
  return profile;
});

export const setRedirect = createAction(SET_REDIRECT, async (path) => async () => path);

export const getApp = createAction(GET_APP, async () => async (dispatch) => {
  const response = await fetch.graphql({
    query: listApps,
  });
  const app = response?.data?.listApps?.items?.[0];

  if (app?.featureToggles) {
    dispatch(setFeatureToggles(app?.featureToggles));
  }

  if (app?.constants) {
    dispatch(setAppConstants(app?.constants));
  }
});

export const setFeatureToggles = createAction(SET_FEATURE_TOGGLES, async (featureToggles) => async () => {
  return JSON.parse(featureToggles || '');
});

export const setAppConstants = createAction(SET_APP_CONSTANTS, async (constants) => async () => {
  return JSON.parse(constants || '');
});

export const setUserAdminEmail = createAction(SET_USER_ADMIN_EMAIL, async (email) => async () => {
  return email;
});

export const setUserAdminRoles = createAction(SET_USER_ADMIN_ROLES, async (roles = []) => async () => {
  return roles;
});
