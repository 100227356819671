import { EVENT_STATUS, PaymentMethod } from './common';

export * from './user';
export * from './app';
export * from './longTextConstants';
export * from './event';
export * from './common';

export const QueryParams = {
  SHOULD_UPDATE_ALL_WAIVER: 'shouldUpdateAllWavier',
  OPEN_WAIVER_UPDATE_MODAL: 'openWavierUpdateModal',
  SHOULD_UPDATE_ALL_FORM: 'shouldUpdateAllForms',
  REDIRECT_PATH_AFTER_LOGIN: 'redirectPath',
  BACK_TO_EVENT: 'backToEvent',
  REGISTRANT_NAME: 'registrantName',
  AUTO_ADD_QUANTITY: 'quantity',
  CATEGORY: 'category',
};

export const Routes = {
  // User's Routes
  MAINTENANCE: 'maintenance',
  LOGIN: 'login',
  REGISTER: 'register',
  FEED: 'feed',
  FORGOTPASSWORD: 'forgot-password',
  RESETPASSWORD: 'reset-password/:token',
  VERIFICATION: 'verification',
  EVENTS: 'events',
  EVENT: 'event',
  MY_RACES: 'registrations',
  PROFILE: 'profile',
  CALCULATOR: 'calculator',
  SETTINGS: 'settings',
  MY_PREVIOUS_EVENTS: 'previous-events',
  MY_UPCOMING_EVENTS: 'upcoming-events',
  EVENTREGISTRATION: 'event-registration',
  REGISTRATION_CONFIRMATION: 'event-registration-confirmation',
  EVENT_RACE_RESULTS: 'event-race-results',
  REPORT_BUG: 'report-a-bug',
  REPORT_BUG_EVENT: 'report-a-bug-to-an-event',
  REPORT_BUG_ORG: 'report-a-bug-to-an-organization',

  // Organization's Portal routes
  ORGANIZATION_DASHBOARD: 'organization-dashboard',
  ORGANIZATION_STAFFS: 'organization-staffs',
  ORGANIZATION_EVENTS: 'organization-events',
  ORGANIZATION_SETTINGS: 'organized-settings',
  ORGANIZATIONS_DASHBOARD: 'organization-selection',

  // Event's Portal Route
  EVENT_PORTAL: 'event-portal',
  EVENT_DASHBOARD: 'event-dashboard',
  EVENT_STAFFS: 'event-staffs',
  EVENT_REGISTRANTS: 'event-registrants',
  EVENT_SETUP_GENERAL: 'event-setup-general',
  EVENT_SETUP_CONTACT: 'event-setup-contact',
  EVENT_SETUP_PAYOUT: 'event-setup-payout',
  EVENT_ADDITIONAL_NOTES: 'event-additional-notes',
  EVENT_SETUP_OVERVIEW: 'event-setup-overview',
  EVENT_SETUP_IMAGES: 'event-setup-images',
  EVENT_PAYOUT: 'event-payout',

  // Categories
  EVENT_CATEGORIES_LIST: 'event-categories-list',
  EVENT_CATEGORIES_GENERAL_SETUP: 'event-categories-general-setup',
  EVENT_SETUP_CATEGORY_SETUP: 'event-category-setup-general',
  EVENT_SETUP_CATEGORY_RESULTS: 'event-category-race-results',
  EVENT_SETUP_CATEGORY_PRIZES: 'event-category-setup-prizes',
  EVENT_SETUP_CATEGORY_FORM: 'event-category-setup-form',
  EVENT_SETUP_CATEGORY_WAIVER: 'event-category-setup-wavier',
  EVENT_SETUP_CATEGORY_FEE: 'event-category-setup-fee',
  EVENT_SETUP_CATEGORY_SLOTS: 'event-category-setup-slots',
  EVENT_SETUP_CATEGORY_ROUTE: 'event-category-setup-route',

  // AdminRoutes
  ADMIN_CHOOSE_USER: 'admin-choose-user',

  // GENERAL
  TERMS_OF_SERVICE: 'terms-of-service',
  PRIVACY_POLICY: 'privacy-policy',
  Page404: '40',
  Page403: '403',
  Page500: '500',
};

export const adminRoutes = {
  [Routes.ADMIN_CHOOSE_USER]: {
    path: '/admin',
    title: 'Admin | Pick Pacer',
  },
};

export const OrganizationsPrimaryPath = 'organizations';
export const OrganizationPrimaryPath = 'organization';
export const EventPrimaryPath = 'event';

export const eventCategoriesSetupRoutes = {
  [Routes.EVENT_CATEGORIES_GENERAL_SETUP]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/categories/general-setup/:addNewCategoryModal?`,
    title: 'Categories | Pick Pacer',
  },
  [Routes.EVENT_CATEGORIES_LIST]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/categories/list/:categoryId?`,
    title: 'Categories | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_SETUP]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/setup`,
    title: 'Categories | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_RESULTS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/results`,
    title: 'Categories | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_FEE]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/registration-fee`,
    title: 'Setup: Fees | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_SLOTS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/slots`,
    title: 'Setup: Slots | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_ROUTE]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/route`,
    title: 'Setup: Fees | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_PRIZES]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/prizes`,
    title: 'Setup: Prizes | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_FORM]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/form`,
    title: 'Setup: Form | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CATEGORY_WAIVER]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/category/:categoryId/waiver`,
    title: 'Setup: Waiver | Pick Pacer',
  },
  [Routes.REPORT_BUG_EVENT]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/report-a-bug`,
    title: 'Report a bug | Pick Pacer',
  },
};

export const eventAdminPortalRoutes = {
  [Routes.EVENT_STAFFS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/staffs/:staffId?`,
    title: 'Event | Pick Pacer',
  },
  [Routes.EVENT_SETUP_GENERAL]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/setup-general`,
    title: 'Setup: General | Pick Pacer',
  },
  [Routes.EVENT_SETUP_PAYOUT]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/setup-payout`,
    title: 'Setup: General | Pick Pacer',
  },
  [Routes.EVENT_ADDITIONAL_NOTES]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/additional-notes`,
    title: 'Setup: Additional Notes | Pick Pacer',
  },
  [Routes.EVENT_SETUP_OVERVIEW]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/setup-event-overview`,
    title: 'Setup: General | Pick Pacer',
  },
  [Routes.EVENT_SETUP_IMAGES]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/setup-event-images`,
    title: 'Setup: General | Pick Pacer',
  },
  [Routes.EVENT_SETUP_CONTACT]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/setup-event-contact`,
    title: 'Setup: General | Pick Pacer',
  },
  [Routes.EVENT_PAYOUT]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/payout/:payoutModalOpen?`,
    title: 'Payout | Pick Pacer',
  },
  ...eventCategoriesSetupRoutes,
};

export const eventPortalRoutes = {
  [Routes.EVENT_PORTAL]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId`,
    title: 'Event | Pick Pacer',
  },
  [Routes.EVENT_DASHBOARD]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/dashboard`,
    title: 'Event | Pick Pacer',
  },
  [Routes.EVENT_REGISTRANTS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/${EventPrimaryPath}/:selectedEventId/registrants`,
    title: 'Event | Pick Pacer',
  },
  ...eventAdminPortalRoutes,
};

export const organizationAdminRoutes = {
  [Routes.ORGANIZATION_STAFFS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/staffs/:staffId?`,
    title: 'Organization | Staffs',
  },
  [Routes.ORGANIZATION_SETTINGS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/settings`,
    title: 'Organization | Settings',
  },
};

export const orgAdminRoutes = {
  ...eventAdminPortalRoutes,
  ...organizationAdminRoutes,
};

export const orgAdminRoutesConfig = Object.entries(organizationAdminRoutes).reduce(
  (acc, [key, value]) => [...acc, { path: value.path, exact: true }],
  []
);

export const orgEventAdminRoutesConfig = Object.entries(eventAdminPortalRoutes).reduce(
  (acc, [key, value]) => [...acc, { path: value.path, exact: true }],
  []
);

export const S3ImageSizes = {
  original: 'original',
  _300x300: '300x300',
  _500x500: '500x500',
  _1000x1000: '1000x1000',
};

export const S3FileType = {
  profile: 'profile',
  files: 'files',
  results: 'results',
  receipts: 'receipts',
  common: 'common',
};

export const AddedFileNames = {
  results_view: 'results_view',
  results_original: 'results_original',
};

export const OriginalFiles = [AddedFileNames.results_original, S3ImageSizes.original];

export const AcceptedFiles = {
  images: {
    'image/jpeg': [],
    'image/jpg': [],
    'image/gif': [],
    'image/png': [],
  },
  text: {
    'text/csv': [],
  },
};

export const acceptedFileImageExtensions = Object.keys(AcceptedFiles.images).map((item) => item?.split('/')[1]);

export const FileMaxSize = {
  images: 4145728,
};

export const organizationRoutes = {
  // organization
  [Routes.ORGANIZATION_EVENTS]: {
    path: `/${OrganizationPrimaryPath}/:organizationId/events/:eventId?`,
    title: 'Organization | Events',
  },
  [Routes.ORGANIZATION_DASHBOARD]: {
    path: `/${OrganizationPrimaryPath}/:organizationId`,
    title: 'Organization | Dashboard',
  },
  [Routes.ORGANIZATIONS_DASHBOARD]: {
    path: `/${OrganizationsPrimaryPath}`,
    title: 'Organizers | Selections',
  },
  ...organizationAdminRoutes,
  [Routes.REPORT_BUG_ORG]: {
    path: `/${OrganizationsPrimaryPath}/:organizationId/report-a-bug`,
    title: 'Report a bug | Pick Pacer',
  },
};

export const organizationsRoutes = {
  ...organizationRoutes,
  ...eventPortalRoutes,
};

export const RoutesValue = {
  [Routes.MAINTENANCE]: {
    path: '/website-maintenance',
    title: 'Website Maintenance',
  },
  [Routes.PRIVACY_POLICY]: {
    path: '/privacy-policy',
    title: 'Privacy Policy',
  },
  [Routes.TERMS_OF_SERVICE]: {
    path: '/terms-of-service',
    title: 'Terms of Service',
  },
  [Routes.Page500]: {
    path: '/500',
    title: '500',
  },
  [Routes.Page403]: {
    path: '/403',
    title: '403',
  },
  [Routes.Page404]: {
    path: '/404',
    title: '404',
  },
  [Routes.LOGIN]: {
    path: '/login',
    title: 'Login',
  },
  [Routes.REGISTER]: {
    path: '/register',
    title: 'Register',
  },
  [Routes.FORGOTPASSWORD]: {
    path: '/forgot-password',
    title: 'Forgot Password',
  },
  [Routes.RESETPASSWORD]: {
    path: '/reset-password/:token',
    title: 'Reset Password',
  },
  [Routes.VERIFICATION]: {
    path: '/confirmation',
    title: 'Sign Up Confirmation',
  },
  [Routes.CALCULATOR]: {
    path: '/calculator',
    title: 'Pace Calculator',
  },
  [Routes.EVENTS]: {
    path: '/events',
    title: 'Events',
  },
  [Routes.EVENT]: {
    path: '/event/:title/:id',
    title: 'Events',
  },
  [Routes.EVENTREGISTRATION]: {
    path: '/event/:title/registration/:id/:progressPage',
    title: 'Registration',
  },
  [Routes.REGISTRATION_CONFIRMATION]: {
    path: '/confirmation/event/:eventName/:eventId/order/:orderId/:checkoutState',
    title: 'Registration',
  },
  [Routes.EVENT_RACE_RESULTS]: {
    path: '/event/:eventId/race-results',
    title: 'Race Results',
  },
  [Routes.REPORT_BUG]: {
    path: '/report-bug',
    title: 'Report a bug',
  },
  [Routes.FEED]: {
    path: '/feeds',
    title: 'Feeds | Pick Pacer',
  },
  [Routes.HOME]: {
    path: '/home',
    title: 'Home | Pick Pacer',
  },
  [Routes.MY_RACES]: {
    path: '/my-races/:orderId?',
    title: 'My Races | Pick Pacer',
  },
  [Routes.PROFILE]: {
    path: '/profile',
    title: 'Profile | Pick Pacer',
  },
  [Routes.SETTINGS]: {
    path: '/settings',
    title: 'Settings | Pick Pacer',
  },
  [Routes.MY_PREVIOUS_EVENTS]: {
    path: '/my-races/my-previous-events',
    title: 'Previous Races | Pick Pacer',
  },
  [Routes.MY_UPCOMING_EVENTS]: {
    path: '/my-races/my-upcoming-events',
    title: 'Upcoming Races | Pick Pacer',
  },
  ...organizationsRoutes,
  ...adminRoutes,
};

export const NavigationType = {
  SWITCH: 'switch-between-organization-user',
  EXIT_ORG: 'switch-back-organizations',
  EXIT_EVENT: 'switch-back-organization',
};

export const ProgressPage = {
  numberOfRegistrants: 'number-of-registrants',
  formFilling: 'form-filling',
  summary: 'summary',
  checkout: 'checkout',
};

export const CheckoutState = {
  success: 'success',
  failed: 'failed',
};

export const CheckoutErrorType = {
  SlotsAvailability: 'SlotsAvailability',
  PriceChange: 'PriceChange',
};

export const SORT_OPTIONS = [
  { value: 'everywhere', label: 'Everywhere' },
  { value: 'luzon', label: 'Luzon' },
  { value: 'visayas', label: 'Visayas' },
  { value: 'mindanao', label: 'Mindanao' },
];

// TODO Transfer Values to process.env
export const ConstantValues = {
  jwtTokenExp: '5s',
  jwtSecret: 'Secret',
};

export const ResponseType = {
  success: 'success',
  failed: 'failed',
};

export const TimeReturnType = {
  MinuteSecAndInWord: 'MinuteSecAndInWord',
};

export const Status = {
  CONFIRMED: 'Confirmed',
  APPROVED: 'Approved',
  PENDING: 'Pending',
  DENIED: 'Denied',
};

export const PaymentStatus = {
  UNPAID: 'unpaid',
  PAID: 'paid',
};

export const StaticImages = {
  profileAvatar: `/static/mock-images/avatars/avatar_20.jpg`,
  profileAvatar2: '/static/mock-images/avatars/avatar_default.jpg',
  svgIconStyle: '/static/icons/shape-avatar.svg',
};

export const Roles = {
  ADMIN: 'Admin',
  MEMBER: 'Member',
  ORGANIZER: 'Organizer',
  CREATOR: 'Creator',
};

export const successColorTypes = [
  EVENT_STATUS.OPEN,
  Status.APPROVED,
  Roles.CREATOR,
  Status.CONFIRMED,
  PaymentStatus.PAID,
];

export const errorColorTypes = [EVENT_STATUS.CLOSE, EVENT_STATUS.NO_SLOTS_AVAILABLE, Status.DENIED];
export const warningColorTypes = [EVENT_STATUS.DONE, Status.PENDING, PaymentStatus.UNPAID];

export const UIColors = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  info: 'info',
};

export const ColorType = {
  [UIColors.success]: {
    list: successColorTypes,
    color: 'success',
  },
  [UIColors.error]: {
    list: errorColorTypes,
    color: 'error',
  },
  [UIColors.warning]: {
    list: warningColorTypes,
    color: 'warning',
  },
  [UIColors.secondary]: {
    list: successColorTypes,
    color: 'success',
  },
  [UIColors.primary]: {
    list: errorColorTypes,
    color: 'error',
  },
  [UIColors.info]: {
    list: warningColorTypes,
    color: 'warning',
  },
  [UIColors.default]: {
    list: warningColorTypes,
    color: 'warning',
  },
};

export const OrganizationStaffEventStatus = {
  [Status.APPROVED]: Status.APPROVED,
  [Status.PENDING]: Status.PENDING,
};

export const RegistrationState = {
  PENDING: 'pending',
  PAYMENT: 'payment',
  COMPLETED: 'completed',
};

export const OrganizationRole = {
  ADMIN: Roles.ADMIN,
  CREATOR: Roles.CREATOR,
  MEMBER: Roles.MEMBER,
};

export const StaffEventRole = {
  ADMIN: Roles.ADMIN,
  MEMBER: Roles.MEMBER,
};

export const Scopes = {
  [Roles.CREATOR]: {
    type: 'Creator',
    description: 'This is an creator scope',
  },
  [Roles.MEMBER]: {
    type: 'Member',
    description: 'This is an member scope',
  },
  [Roles.ADMIN]: {
    type: 'Admin',
    description: 'This is an admin scope',
  },
  [Roles.ORGANIZER]: {
    type: 'Organizer',
    description: 'This is an organizer scope',
  },
};

export const PAYMENT_OPTIONS = [
  {
    value: PaymentMethod.GCASH,
    title: PaymentMethod.GCASH,
    // description: 'You will be redirected to GCASH website to complete your purchase securely.',
    icons: [],
  },
  {
    value: PaymentMethod.CARD,
    title: 'Credit / Debit Card',
    description: 'We support Mastercard and Visa.',
    icons: ['/assets/master-card.svg'],
  },

  {
    value: PaymentMethod.PAYMAYA,
    title: 'Paymaya',
    // description: 'You will be redirected to Paymaya website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },

  {
    value: PaymentMethod.BANKTRANSFER_UNION,
    title: 'Union Bank',
    // description: 'You will be redirected to Union Bank website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },

  {
    value: PaymentMethod.BANKTRANSFER_BPI,
    title: 'BPI',
    // description: 'You will be redirected to BPI website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },

  {
    value: PaymentMethod.BILLEASE,
    title: 'BILLEASE',
    // description: 'You will be redirected to BILL EASE website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },

  {
    value: PaymentMethod.GRAB_PAY,
    title: 'GRAB_PAY',
    // description: 'You will be redirected to GRAB PAY website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },
  {
    value: PaymentMethod.BRANKAS_BDO,
    title: 'BDO',
    // description: 'You will be redirected to BDO website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },
  {
    value: PaymentMethod.BRANKAS_LANDBANK,
    title: 'Land Bank',
    // description: 'You will be redirected to Land bank website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },
  {
    value: PaymentMethod.BRANKAS_METROBANK,
    title: 'Metro Bank',
    // description: 'You will be redirected to METRO BANK website to complete your purchase securely.',
    icons: ['https://minimal-assets-api-dev.vercel.app/assets/icons/ic_mastercard.svg'],
  },
];

// USER

// TODO Remove
export const USER_GROUP = {
  ORGANIZER: 'Organizers',
  ADMIN: 'Admin',
  USER: 'User',
};

export const RoleType = {
  ORGANIZATION: 'organization-role',
};

export const ResponseStatus = {
  ALREADY_CREATED: 'already-created',
  SUCCESSFULLY_CREATED: 'successfully-created',
};

export const OrganizationStaffRoleType = {
  ...OrganizationRole,
  ORGANIZER: 'Organizer',
  CREATOR: 'Creator',
};

export const localStorageVariable = {
  token: 'token',
  profile: 'profile',
  selectedEventId: 'selectedEventId',
  selectedOrganizationId: 'selectedOrganizationId',
  USER_TO_IMPERSONATE: 'USER_TO_IMPERSONATE',
  REDIRECT_PATH_AFTER_LOGIN: 'REDIRECT_PATH_AFTER_LOGIN',
};

export const ProfileView = {
  user: 'user',
  organization: 'organization',
};

export const GraphqlSortDirections = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const countries = [
  { code: 'AD', label: 'Andorra', phone: '376' },
  { code: 'AE', label: 'United Arab Emirates', phone: '971' },
  { code: 'AF', label: 'Afghanistan', phone: '93' },
  { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
  { code: 'AI', label: 'Anguilla', phone: '1-264' },
  { code: 'AL', label: 'Albania', phone: '355' },
  { code: 'AM', label: 'Armenia', phone: '374' },
  { code: 'AO', label: 'Angola', phone: '244' },
  { code: 'AQ', label: 'Antarctica', phone: '672' },
  { code: 'AR', label: 'Argentina', phone: '54' },
  { code: 'AS', label: 'American Samoa', phone: '1-684' },
  { code: 'AT', label: 'Austria', phone: '43' },
  { code: 'AU', label: 'Australia', phone: '61' },
  { code: 'AW', label: 'Aruba', phone: '297' },
  { code: 'AX', label: 'Alland Islands', phone: '358' },
  { code: 'AZ', label: 'Azerbaijan', phone: '994' },
  { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
  { code: 'BB', label: 'Barbados', phone: '1-246' },
  { code: 'BD', label: 'Bangladesh', phone: '880' },
  { code: 'BE', label: 'Belgium', phone: '32' },
  { code: 'BF', label: 'Burkina Faso', phone: '226' },
  { code: 'BG', label: 'Bulgaria', phone: '359' },
  { code: 'BH', label: 'Bahrain', phone: '973' },
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
  { code: 'BM', label: 'Bermuda', phone: '1-441' },
  { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  { code: 'BS', label: 'Bahamas', phone: '1-242' },
  { code: 'BT', label: 'Bhutan', phone: '975' },
  { code: 'BV', label: 'Bouvet Island', phone: '47' },
  { code: 'BW', label: 'Botswana', phone: '267' },
  { code: 'BY', label: 'Belarus', phone: '375' },
  { code: 'BZ', label: 'Belize', phone: '501' },
  { code: 'CA', label: 'Canada', phone: '1' },
  { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
  { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
  { code: 'CF', label: 'Central African Republic', phone: '236' },
  { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
  { code: 'CH', label: 'Switzerland', phone: '41' },
  { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
  { code: 'CK', label: 'Cook Islands', phone: '682' },
  { code: 'CL', label: 'Chile', phone: '56' },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  { code: 'CV', label: 'Cape Verde', phone: '238' },
  { code: 'CW', label: 'Curacao', phone: '599' },
  { code: 'CX', label: 'Christmas Island', phone: '61' },
  { code: 'CY', label: 'Cyprus', phone: '357' },
  { code: 'CZ', label: 'Czech Republic', phone: '420' },
  { code: 'DE', label: 'Germany', phone: '49' },
  { code: 'DJ', label: 'Djibouti', phone: '253' },
  { code: 'DK', label: 'Denmark', phone: '45' },
  { code: 'DM', label: 'Dominica', phone: '1-767' },
  { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
  { code: 'DZ', label: 'Algeria', phone: '213' },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'EE', label: 'Estonia', phone: '372' },
  { code: 'EG', label: 'Egypt', phone: '20' },
  { code: 'EH', label: 'Western Sahara', phone: '212' },
  { code: 'ER', label: 'Eritrea', phone: '291' },
  { code: 'ES', label: 'Spain', phone: '34' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'FI', label: 'Finland', phone: '358' },
  { code: 'FJ', label: 'Fiji', phone: '679' },
  { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
  { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
  { code: 'FO', label: 'Faroe Islands', phone: '298' },
  { code: 'FR', label: 'France', phone: '33' },
  { code: 'GA', label: 'Gabon', phone: '241' },
  { code: 'GB', label: 'United Kingdom', phone: '44' },
  { code: 'GD', label: 'Grenada', phone: '1-473' },
  { code: 'GE', label: 'Georgia', phone: '995' },
  { code: 'GF', label: 'French Guiana', phone: '594' },
  { code: 'GG', label: 'Guernsey', phone: '44' },
  { code: 'GH', label: 'Ghana', phone: '233' },
  { code: 'GI', label: 'Gibraltar', phone: '350' },
  { code: 'GL', label: 'Greenland', phone: '299' },
  { code: 'GM', label: 'Gambia', phone: '220' },
  { code: 'GN', label: 'Guinea', phone: '224' },
  { code: 'GP', label: 'Guadeloupe', phone: '590' },
  { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
  { code: 'GR', label: 'Greece', phone: '30' },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
  },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GU', label: 'Guam', phone: '1-671' },
  { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HK', label: 'Hong Kong', phone: '852' },
  { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HR', label: 'Croatia', phone: '385' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'HU', label: 'Hungary', phone: '36' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },
  { code: 'IL', label: 'Israel', phone: '972' },
  { code: 'IM', label: 'Isle of Man', phone: '44' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
  { code: 'IQ', label: 'Iraq', phone: '964' },
  { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
  { code: 'IS', label: 'Iceland', phone: '354' },
  { code: 'IT', label: 'Italy', phone: '39' },
  { code: 'JE', label: 'Jersey', phone: '44' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'JO', label: 'Jordan', phone: '962' },
  { code: 'JP', label: 'Japan', phone: '81' },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'KI', label: 'Kiribati', phone: '686' },
  { code: 'KM', label: 'Comoros', phone: '269' },
  { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
  { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
  { code: 'KR', label: 'Korea, Republic of', phone: '82' },
  { code: 'KW', label: 'Kuwait', phone: '965' },
  { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
  { code: 'KZ', label: 'Kazakhstan', phone: '7' },
  { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
  { code: 'LB', label: 'Lebanon', phone: '961' },
  { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
  { code: 'LI', label: 'Liechtenstein', phone: '423' },
  { code: 'LK', label: 'Sri Lanka', phone: '94' },
  { code: 'LR', label: 'Liberia', phone: '231' },
  { code: 'LS', label: 'Lesotho', phone: '266' },
  { code: 'LT', label: 'Lithuania', phone: '370' },
  { code: 'LU', label: 'Luxembourg', phone: '352' },
  { code: 'LV', label: 'Latvia', phone: '371' },
  { code: 'LY', label: 'Libya', phone: '218' },
  { code: 'MA', label: 'Morocco', phone: '212' },
  { code: 'MC', label: 'Monaco', phone: '377' },
  { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
  { code: 'ME', label: 'Montenegro', phone: '382' },
  { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MH', label: 'Marshall Islands', phone: '692' },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
  },
  { code: 'ML', label: 'Mali', phone: '223' },
  { code: 'MM', label: 'Myanmar', phone: '95' },
  { code: 'MN', label: 'Mongolia', phone: '976' },
  { code: 'MO', label: 'Macao', phone: '853' },
  { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
  { code: 'MQ', label: 'Martinique', phone: '596' },
  { code: 'MR', label: 'Mauritania', phone: '222' },
  { code: 'MS', label: 'Montserrat', phone: '1-664' },
  { code: 'MT', label: 'Malta', phone: '356' },
  { code: 'MU', label: 'Mauritius', phone: '230' },
  { code: 'MV', label: 'Maldives', phone: '960' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'MY', label: 'Malaysia', phone: '60' },
  { code: 'MZ', label: 'Mozambique', phone: '258' },
  { code: 'NA', label: 'Namibia', phone: '264' },
  { code: 'NC', label: 'New Caledonia', phone: '687' },
  { code: 'NE', label: 'Niger', phone: '227' },
  { code: 'NF', label: 'Norfolk Island', phone: '672' },
  { code: 'NG', label: 'Nigeria', phone: '234' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'NL', label: 'Netherlands', phone: '31' },
  { code: 'NO', label: 'Norway', phone: '47' },
  { code: 'NP', label: 'Nepal', phone: '977' },
  { code: 'NR', label: 'Nauru', phone: '674' },
  { code: 'NU', label: 'Niue', phone: '683' },
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'OM', label: 'Oman', phone: '968' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PF', label: 'French Polynesia', phone: '689' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PK', label: 'Pakistan', phone: '92' },
  { code: 'PL', label: 'Poland', phone: '48' },
  { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
  { code: 'PN', label: 'Pitcairn', phone: '870' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' },
  { code: 'PS', label: 'Palestine, State of', phone: '970' },
  { code: 'PT', label: 'Portugal', phone: '351' },
  { code: 'PW', label: 'Palau', phone: '680' },
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'QA', label: 'Qatar', phone: '974' },
  { code: 'RE', label: 'Reunion', phone: '262' },
  { code: 'RO', label: 'Romania', phone: '40' },
  { code: 'RS', label: 'Serbia', phone: '381' },
  { code: 'RU', label: 'Russian Federation', phone: '7' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SA', label: 'Saudi Arabia', phone: '966' },
  { code: 'SB', label: 'Solomon Islands', phone: '677' },
  { code: 'SC', label: 'Seychelles', phone: '248' },
  { code: 'SD', label: 'Sudan', phone: '249' },
  { code: 'SE', label: 'Sweden', phone: '46' },
  { code: 'SG', label: 'Singapore', phone: '65' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SI', label: 'Slovenia', phone: '386' },
  { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
  { code: 'SK', label: 'Slovakia', phone: '421' },
  { code: 'SL', label: 'Sierra Leone', phone: '232' },
  { code: 'SM', label: 'San Marino', phone: '378' },
  { code: 'SN', label: 'Senegal', phone: '221' },
  { code: 'SO', label: 'Somalia', phone: '252' },
  { code: 'SR', label: 'Suriname', phone: '597' },
  { code: 'SS', label: 'South Sudan', phone: '211' },
  { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
  { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
  { code: 'SZ', label: 'Swaziland', phone: '268' },
  { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
  { code: 'TD', label: 'Chad', phone: '235' },
  { code: 'TF', label: 'French Southern Territories', phone: '262' },
  { code: 'TG', label: 'Togo', phone: '228' },
  { code: 'TH', label: 'Thailand', phone: '66' },
  { code: 'TJ', label: 'Tajikistan', phone: '992' },
  { code: 'TK', label: 'Tokelau', phone: '690' },
  { code: 'TL', label: 'Timor-Leste', phone: '670' },
  { code: 'TM', label: 'Turkmenistan', phone: '993' },
  { code: 'TN', label: 'Tunisia', phone: '216' },
  { code: 'TO', label: 'Tonga', phone: '676' },
  { code: 'TR', label: 'Turkey', phone: '90' },
  { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
  { code: 'TV', label: 'Tuvalu', phone: '688' },
  { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
  { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
  { code: 'UA', label: 'Ukraine', phone: '380' },
  { code: 'UG', label: 'Uganda', phone: '256' },
  { code: 'US', label: 'United States', phone: '1' },
  { code: 'UY', label: 'Uruguay', phone: '598' },
  { code: 'UZ', label: 'Uzbekistan', phone: '998' },
  { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
  { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
  { code: 'VE', label: 'Venezuela', phone: '58' },
  { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
  { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'VU', label: 'Vanuatu', phone: '678' },
  { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
  { code: 'WS', label: 'Samoa', phone: '685' },
  { code: 'XK', label: 'Kosovo', phone: '383' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  { code: 'YT', label: 'Mayotte', phone: '262' },
  { code: 'ZA', label: 'South Africa', phone: '27' },
  { code: 'ZM', label: 'Zambia', phone: '260' },
  { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];
