import { useState, useCallback, memo } from 'react';
import { Link, Typography, Stack } from '@mui/material';
import GeneralModal from 'components/GeneralModal';
import Markdown from 'components/Markdown';

const GeneralWaiverFormModal = ({
  preText = 'By registering ',
  markDownValue = '',
  description = '',
  index = 0,
  buttonOneText = 'Agree',
  preTextValueProp,
  otherSx = { mt: 3 },
}) => {
  const [openModal, setOpenModal] = useState(null);

  const handleModalOpen = useCallback(() => {
    setOpenModal({ markDownValue });
  }, [markDownValue]);

  const preTextValue = preTextValueProp || `${preText}, I agree to `;

  return (
    <>
      {openModal?.markDownValue && (
        <GeneralModal
          open={Boolean(openModal?.markDownValue)}
          id={`waiver-modal-display-${index}`}
          title={openModal?.label}
          message={openModal?.message}
          content={
            <Stack sx={{ paddingTop: 2 }}>
              <Markdown children={openModal?.markDownValue} />
            </Stack>
          }
          buttonOneText={buttonOneText}
          buttonOneClick={() => setOpenModal(null)}
        />
      )}

      <Typography variant="body2" align="center" sx={{ color: 'text.secondary', ...otherSx }}>
        {preTextValue}
        <>
          <Link underline="always" color="text.primary" href="#" onClick={handleModalOpen}>
            {description}
          </Link>
          .
        </>
      </Typography>
    </>
  );
};

export default memo(GeneralWaiverFormModal);
