import { lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { ProfileView, Routes } from 'constants/index';
import { getPath } from 'utils/routes';
import NotFound from 'pages/Page404';
// import DashboardApp from 'pages/DashboardApp';
import { SecureRoutes } from '../SecureRoutes';
import { NonAuthRoute } from '../NonAuthRoute';
import { LazyLoader, LayoutType, Loadable } from './lazyload';

export const Router = ({ isAuth, isEmailConfirmed, profile }) => {
  const userAuth = {
    isAuth,
    isEmailConfirmed,
  };

  const isOrganizationProfileAndIsAuth = profile === ProfileView.organization && isAuth;

  return (
    <>
      <Switch>
        {/* Authenticated Routes */}
        <Redirect exact from={getPath(Routes.FEED)} to={getPath(Routes.EVENTS)} />

        <Redirect
          exact
          from="/"
          to={isOrganizationProfileAndIsAuth ? getPath(Routes.ORGANIZATIONS_DASHBOARD) : getPath(Routes.EVENTS)}
        />

        <SecureRoutes userAuth={userAuth} path={getPath(Routes.MY_PREVIOUS_EVENTS)} exact component={<Events />} />

        <SecureRoutes userAuth={userAuth} path={getPath(Routes.MY_UPCOMING_EVENTS)} exact component={<Events />} />

        <SecureRoutes userAuth={userAuth} path={getPath(Routes.SETTINGS)} exact component={<Events />} />

        <NonAuthRoute
          userAuth={userAuth}
          publicRoute
          path={getPath(Routes.CALCULATOR)}
          exact
          component={<Calculator />}
        />

        <NonAuthRoute
          userAuth={userAuth}
          publicRoute
          path={getPath(Routes.EVENT_RACE_RESULTS)}
          exact
          component={<EventRaceResults />}
        />

        <SecureRoutes userAuth={userAuth} path={getPath(Routes.PROFILE)} exact component={<UserAccount />} />

        {/* Organizer's Routes */}

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ORGANIZATION_EVENTS)}
          organizersRoute
          exact
          component={<OrganizationEvents />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ADMIN_CHOOSE_USER)}
          exact
          isAdminPortalRoute
          component={<AdminChooseUserPortal />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ORGANIZATION_STAFFS)}
          organizersRoute
          exact
          component={<OrganizationStaffs />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ORGANIZATION_DASHBOARD)}
          organizersRoute
          exact
          component={<OrganizationDashboard />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ORGANIZATION_SETTINGS)}
          organizersRoute
          exact
          component={<OrganizationSettings />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.ORGANIZATIONS_DASHBOARD)}
          organizersRoute
          exact
          component={<OrganizationsDashboard />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.EVENT_DASHBOARD)}
          organizersRoute
          exact
          component={<OrganizationEventDashboard />}
        />

        {/* Event's Portal Routes */}

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.EVENT_REGISTRANTS)}
          organizersRoute
          exact
          component={<EventPortalRegistrants />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.EVENT_STAFFS)}
          organizersRoute
          exact
          component={<EventPortalStaffs />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={getPath(Routes.EVENT_PAYOUT)}
          organizersRoute
          exact
          component={<EventPortalPayout />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={[
            getPath(Routes.EVENT_SETUP_GENERAL),
            getPath(Routes.EVENT_ADDITIONAL_NOTES),
            getPath(Routes.EVENT_SETUP_PAYOUT),
            getPath(Routes.EVENT_SETUP_CONTACT),
          ]}
          organizersRoute
          exact
          component={<EventSetupGeneral />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={[getPath(Routes.EVENT_CATEGORIES_GENERAL_SETUP), getPath(Routes.EVENT_CATEGORIES_LIST)]}
          organizersRoute
          exact
          component={<EventCategoriesSetup />}
        />

        <SecureRoutes
          userAuth={userAuth}
          path={[
            getPath(Routes.EVENT_SETUP_CATEGORY_RESULTS),
            getPath(Routes.EVENT_SETUP_CATEGORY_SETUP),
            getPath(Routes.EVENT_SETUP_CATEGORY_FEE),
            getPath(Routes.EVENT_SETUP_CATEGORY_FORM),
            getPath(Routes.EVENT_SETUP_CATEGORY_WAIVER),
            getPath(Routes.EVENT_SETUP_CATEGORY_PRIZES),
            getPath(Routes.EVENT_SETUP_CATEGORY_ROUTE),
            getPath(Routes.EVENT_SETUP_CATEGORY_SLOTS),
          ]}
          organizersRoute
          exact
          component={<EventCategorySetup />}
        />

        {/* None Authenticated Routes */}
        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.LOGIN)} exact component={<Login />} />

        <NonAuthRoute
          path={getPath(Routes.MAINTENANCE)}
          exact
          component={<MaintenancePage shouldDisplayGoHomeButton={false} />}
        />

        <NonAuthRoute
          userAuth={userAuth}
          path={getPath(Routes.TERMS_OF_SERVICE)}
          exact
          component={<TermsOfService />}
        />

        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.PRIVACY_POLICY)} exact component={<PrivacyPolicy />} />

        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.REGISTER)} exact component={<Register />} />

        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.RESETPASSWORD)} exact component={<ResetPassword />} />

        <NonAuthRoute
          userAuth={userAuth}
          isEmailConfirmed={isEmailConfirmed}
          path={getPath(Routes.VERIFICATION)}
          exact
          component={<VerifyCode />}
        />
        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.FORGOTPASSWORD)} exact component={<ForgotPassword />} />

        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.EVENTS)} publicRoute exact component={<Events />} />

        <NonAuthRoute userAuth={userAuth} path={getPath(Routes.EVENT)} publicRoute exact component={<Event />} />

        {/* Event SignUp Routes */}
        <NonAuthRoute
          userAuth={userAuth}
          path={getPath(Routes.REGISTRATION_CONFIRMATION)}
          publicRoute
          exact
          component={<RegistrationConfirmation />}
        />

        <NonAuthRoute
          userAuth={userAuth}
          path={getPath(Routes.EVENTREGISTRATION)}
          publicRoute
          exact
          component={<EventRegistration />}
        />

        <NonAuthRoute
          userAuth={userAuth}
          path={[getPath(Routes.REPORT_BUG), getPath(Routes.REPORT_BUG_EVENT), getPath(Routes.REPORT_BUG_ORG)]}
          publicRoute
          exact
          component={<BugReportingPage />}
        />

        {/* List of Registration */}

        <SecureRoutes userAuth={userAuth} path={getPath(Routes.MY_RACES)} component={<Registrations />} />

        <Route path={getPath(Routes.Page403)} render={() => <LazyLoader children={<Page403 />} />} />
        <Route path={getPath(Routes.Page404)} render={() => <LazyLoader children={<Page404 />} />} />
        <Route path={getPath(Routes.Page500)} render={() => <LazyLoader children={<Page500 />} />} />
        <Route path={'*'} render={() => <LazyLoader children={<NotFound />} />} />
      </Switch>
    </>
  );
};

Router.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  isEmailConfirmed: PropTypes.bool.isRequired,
  isEventAdmin: PropTypes.bool.isRequired,
};

// GENERAL
const TermsOfService = Loadable(
  lazy(() => import('containers/TermsOfServiceContainer')),
  LayoutType.LogoOnlyLayout
);

const PrivacyPolicy = Loadable(
  lazy(() => import('containers/PrivacyPolicyContainer')),
  LayoutType.LogoOnlyLayout
);

const Page404 = Loadable(
  lazy(() => import('pages/Page404')),
  LayoutType.LogoOnlyLayout
);

const Page403 = Loadable(
  lazy(() => import('pages/Page403')),
  LayoutType.LogoOnlyLayout
);

const Page500 = Loadable(
  lazy(() => import('pages/Page500')),
  LayoutType.LogoOnlyLayout
);

// AUTHENTICATION
const Register = Loadable(
  lazy(() => import('containers/RegisterPageContainer')),
  LayoutType.LogoOnlyLayout
);
const ForgotPassword = Loadable(
  lazy(() => import('containers/ForgotPasswordPageContainer')),
  LayoutType.LogoOnlyLayout
);
const Login = Loadable(
  lazy(() => import('containers/LoginPageContainer')),
  LayoutType.LogoOnlyLayout
);

const MaintenancePage = Loadable(
  lazy(() => import('pages/Maintenance')),
  LayoutType.LogoOnlyLayout,
  { disabledLink: true }
);

const VerifyCode = Loadable(
  lazy(() => import('containers/ConfirmSignUpContainer')),
  LayoutType.LogoOnlyLayout
);
const ResetPassword = Loadable(
  lazy(() => import('containers/ResetPasswordPageContainer')),
  LayoutType.LogoOnlyLayout
);

// USER DASHBOARD
const UserAccount = Loadable(
  lazy(() => import('containers/UserAccountContainer')),
  LayoutType.DashboardLayout
);

// EVENT
const Events = Loadable(
  lazy(() => import('containers/EventsPageContainer')),
  LayoutType.DashboardLayout
);

const Calculator = Loadable(
  lazy(() => import('containers/CalculatorPageContainer')),
  LayoutType.DashboardLayout
);

const Event = Loadable(
  lazy(() => import('containers/EventPageContainer')),
  LayoutType.DashboardLayout
);

const EventRaceResults = Loadable(
  lazy(() => import('containers/EventRaceResultsPageContainer')),
  LayoutType.DashboardLayout
);

const RegistrationConfirmation = Loadable(
  lazy(() => import('containers/RegistrationConfirmationContainer')),
  LayoutType.DashboardLayout
);

const EventRegistration = Loadable(
  lazy(() => import('containers/EventSignupPageContainer')),
  LayoutType.DashboardLayout
);

const BugReportingPage = Loadable(
  lazy(() => import('containers/ReportBugPageContainer')),
  LayoutType.DashboardLayout
);

// Registrations

const Registrations = Loadable(
  lazy(() => import('containers/RegistrationListPageContainer')),
  LayoutType.DashboardLayout
);

// Organization Renders

const OrganizationEventDashboard = Loadable(
  lazy(() => import('containers/event/EventDashboardContainer')),
  LayoutType.DashboardLayout
);

const OrganizationDashboard = Loadable(
  lazy(() => import('containers/organization/OrganizationDashboardContainer')),
  LayoutType.DashboardLayout
);

const OrganizationSettings = Loadable(
  lazy(() => import('containers/organization/OrganizationSettingsContainer')),
  LayoutType.DashboardLayout
);

const OrganizationStaffs = Loadable(
  lazy(() => import('containers/organization/OrganizationStaffsContainer')),
  LayoutType.DashboardLayout
);

const OrganizationsDashboard = Loadable(
  lazy(() => import('containers/organization/OrganizationsDashboardContainer')),
  LayoutType.DashboardLayout
);

const OrganizationEvents = Loadable(
  lazy(() => import('containers/organization/OrganizationEventsContainer')),
  LayoutType.DashboardLayout
);

// Event's Portal Renders

const EventPortalDashboard = Loadable(
  lazy(() => import('containers/event/EventDashboardContainer')),
  LayoutType.DashboardLayout
);

const EventPortalStaffs = Loadable(
  lazy(() => import('containers/event/EventPortalStaffsContainer')),
  LayoutType.DashboardLayout
);

const EventPortalPayout = Loadable(
  lazy(() => import('containers/event/EventPortalPayoutContainer')),
  LayoutType.DashboardLayout
);

const EventPortalRegistrants = Loadable(
  lazy(() => import('containers/event/EventPortalRegistrantsContainer')),
  LayoutType.DashboardLayout
);

const EventSetupGeneral = Loadable(
  lazy(() => import('containers/event/EventSetupGeneralContainer')),
  LayoutType.DashboardLayout
);

const EventCategoriesSetup = Loadable(
  lazy(() => import('containers/event/EventCategoriesSetupContainer')),
  LayoutType.DashboardLayout
);

const EventCategorySetup = Loadable(
  lazy(() => import('containers/event/EventCategorySetupContainer')),
  LayoutType.DashboardLayout
);

// ADMIN ROUNTES

const AdminChooseUserPortal = Loadable(
  lazy(() => import('containers/AdminContainer')),
  LayoutType.DashboardLayout,
  { shouldShowNavBar: false }
);
