// import { fieldTypes } from 'components/hook-form';
import { precedenceDataSharingConsent } from 'constants/longTextConstants';
import { AppConstants } from './common';

export const distances = ['3km', '5km', '10km', '15km', '20km', 'Half-Marathon', 'Marathon', 'Other'];

export const defaultFormId = 'no-id-form';

export const defaultCategoryForm = {
  id: `${defaultFormId}-1`,
  eventId: '',
  formOrderNumber: 1,
  categoryId: '',
  updatedAt: '2024-02-02T04:20:44.927Z', // Dummy date
  formFields: [
    {
      defaultValue: null,
      fieldId: 'firstName',
      fieldType: 'TextBox',
      label: 'First Name',
      options: [],
      orderNumber: 0,
      placeholder: 'Jhon',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[2,"FirstName cannot be less than 3 characters"]',
          type: 'min',
        },
        {
          params: '[50,"FirstName cannot be more than 50 characters"]',
          type: 'max',
        },
        // { // TODO workon with this one
        //   params: '["Only text input is allowed", {"regex": "^[^0-9]*$"}]',
        //   type: 'matches',
        // },
        {
          params: '["FirstName is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'lastName',
      fieldType: 'TextBox',
      label: 'Last  Name',
      options: [],
      orderNumber: 1,
      placeholder: 'Doe',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[2,"Last Name cannot be less than 3 characters"]',
          type: 'min',
        },
        {
          params: '[50,"Last Name cannot be more than 50 characters"]',
          type: 'max',
        },
        {
          params: '["Last Name is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'age',
      fieldType: 'TextBox',
      label: 'Age on the race day',
      options: [],
      orderNumber: 2,
      placeholder: '',
      type: 'number',
      validationType: 'number',
      validations: [
        {
          params: '[2,"Should be at least 2 years old"]',
          type: 'min',
        },
        {
          params: '["Age is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'gender',
      fieldType: 'Radio',
      label: 'Sex',
      options: [
        {
          label: 'Male',
          value: 'Male',
        },
        {
          label: 'Female',
          value: 'Female',
        },
      ],
      orderNumber: 3,
      placeholder: '',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '["Sex is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: '+63',
      fieldId: 'phoneNumber',
      fieldType: 'TextBox',
      label: 'Mobile Number',
      options: [],
      orderNumber: 4,
      placeholder: '+639123456789',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[11,"Mobile Number cannot be less than 11 characters"]',
          type: 'min',
        },
        {
          params: '[13,"Mobile Number cannot be more than 13 characters"]',
          type: 'max',
        },
        {
          params: '["Mobile Number is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'email',
      fieldType: 'TextBox',
      label: 'Email',
      options: [],
      orderNumber: 5,
      placeholder: 'Jhon',
      type: null,
      validationType: 'email',
      validations: [
        {
          params: '["Please enter a valid email"]',
          type: 'email',
        },
        {
          params: '["Email is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'shirtSize',
      fieldType: 'Select',
      label: 'T-Shirt Size',
      options: [
        {
          label: 'Small',
          value: 'Small',
        },
        {
          label: 'Medium',
          value: 'Medium',
        },
        {
          label: 'Large',
          value: 'Large',
        },
      ],
      orderNumber: 6,
      placeholder: '',
      type: null,
      validationType: '',
      validations: [
        {
          params: '["Shirt Size is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'hearEvent',
      fieldType: 'Checkbox',
      label: 'Where did you hear this event ? ',
      options: [
        {
          label: 'Social Media',
          value: 'SocialMedia',
        },
        {
          label: 'Poster',
          value: 'Poster',
        },
        {
          label: 'Email',
          value: 'Email',
        },
      ],
      orderNumber: 7,
      placeholder: '',
      type: null,
      validationType: '',
      validations: [
        {
          params: '["Question is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: null,
      fieldId: 'emergencyContactName',
      fieldType: 'TextBox',
      label: 'Emergency Contact Name',
      options: [],
      orderNumber: 8,
      placeholder: 'John Doe',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[2,"Emergency Contact Name cannot be less than 3 characters"]',
          type: 'min',
        },
        {
          params: '[70,"Emergency Contact Name cannot be more than 70 characters"]',
          type: 'max',
        },
        {
          params: '["Emergency Contact Name is required"]',
          type: 'required',
        },
      ],
    },
    {
      defaultValue: '+63',
      fieldId: 'emergencyContactNumber',
      fieldType: 'TextBox',
      label: 'Emergency Contact Mobile Number',
      options: [],
      orderNumber: 9,
      placeholder: '+639123456789',
      type: null,
      validationType: 'string',
      validations: [
        {
          params: '[11,"Emergency Contact Mobile Number cannot be less than 11 characters"]',
          type: 'min',
        },
        {
          params: '[13,"Emergency Contact Mobile Number cannot be more than 13 characters"]',
          type: 'max',
        },
        {
          params: '["Emergency Contact Mobile Number is required"]',
          type: 'required',
        },
      ],
    },
  ],
};

const defaultOptions = [
  {
    label: 'option 1',
    value: 'option 1',
  },
  {
    label: 'option 2',
    value: 'option 2',
  },
];

export const FieldInitialValues = (index) => ({
  TextBox: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'TextBox',
    label: `Label ${index}`,
    options: [],
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Select: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Select',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Radio: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Radio',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
  Checkbox: {
    defaultValue: null,
    fieldId: `fieldId-${index}`,
    fieldType: 'Checkbox',
    label: `Label ${index}`,
    options: defaultOptions,
    orderNumber: index,
    placeholder: '',
    type: null,
    validationType: 'string',
    validations: [],
  },
});

export const CUSTOM_FIELD_ID = 'custom_id';

export const defaultWaiver = `Running is a potentially hazardous activity. I should not enter and run unless I am medically able and properly trained. I agree to abide by any decision of a race official relative to my ability to safely complete the run.

I hereby certify that I am in good health and I have trained to run the distance of the race, which I am entering. I assume all risks associated with running in this event including, but not limited to: falls/stumbling, suffering heat stroke, heart attack, and other similar risks, contact with other participants, the effects of weather, including high heat and/or humidity, all such risks being known and appreciated by me. 

Having read this waiver and knowing these facts and in consideration of your accepting my entry into this marathon. I, for myself and anyone entitled to act on my behalf, waive and release, its officers, directors, agents, volunteers and employees, or other government bodies or locations in which events or segments of events are held, all sponsors their representatives, and successors, from all claims or liabilities of any kind arising out my participation in this event though that liability may arise out of negligence or carelessness on the part of the person named in this waiver. `;

export const FieldsToInclude = {
  registeredDate: 'Registered Date',
  bibNumber: 'bibNumber',
  distance: 'Distance',
  firstName: 'firstName',
  lastName: 'lastName',
  gender: 'gender',
  email: 'registrantEmail',
  paidPrice: 'Paid Price',
  Agreements: 'Agreements',
  BibNumber: 'Bib Number',
  GunTime: 'Gun Time',
  FinishingTime: 'Finishing Time',
  ChipGunTime: 'Chip Gun Time',
  ChipFinishingTime: 'Chip Finishing Time',
  lineItemId: 'lineItemId',
  eventId: 'eventId',
  orderId: 'orderId',
  registrantId: 'registrantId',
  categoryId: 'categoryId',
  fullName: 'fullName',
  Category: 'Category',
  age: 'age',
  rank: 'rank',
};

export const contactInfoTypes = {
  email: 'email',
  mobileNumber: 'mobileNumber',
};

export const UploadIndicatorTypes = {
  CONSTANT: 'CONSTANT',
  UPLOADED_FILE: 'UPLOADED_FILE',
};

// This is in order
export const FieldsResultsToExcludeForDisplay = [
  FieldsToInclude.bibNumber,
  FieldsToInclude.email,
  FieldsToInclude.lineItemId,
  FieldsToInclude.eventId,
  FieldsToInclude.orderId,
  FieldsToInclude.registrantId,
  FieldsToInclude.categoryId,
];

export const TableArrayOrderOfColumns = [
  FieldsToInclude.rank,
  FieldsToInclude.BibNumber,
  FieldsToInclude.Category,
  FieldsToInclude.fullName,
  FieldsToInclude.age,
  FieldsToInclude.gender,
];

export const ShouldCapitalizeHeaders = [FieldsToInclude.fullName, FieldsToInclude.gender];

export const sampleJsonForCsvDownload = {
  [FieldsToInclude.BibNumber]: 'HM-0001',
  [FieldsToInclude.GunTime]: '00:00:00',
  [FieldsToInclude.FinishingTime]: '01:00:00',
  [FieldsToInclude.ChipGunTime]: '',
  [FieldsToInclude.ChipFinishingTime]: '01:02:00',
  // eslint-disable-next-line no-useless-computed-key
  ['1km Split']: '01:02:00',
  // eslint-disable-next-line no-useless-computed-key
  ['2km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['3km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['4km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['5km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['6km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['7km Split']: '',
  // eslint-disable-next-line no-useless-computed-key
  ['8km Split']: '01:02:00',
};

export const PRECEDENCE_CONSTANTS = (data) => ({
  [AppConstants.DATA_SHARING_AGREEMENT]: precedenceDataSharingConsent(data),
});

export const AgeGap = {
  FIVE: 5,
  TEN: 10,
};
