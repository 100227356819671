import React, { useEffect } from 'react';

const UrlRedirector = ({ url, target = '_self' }) => {
  useEffect(() => {
    if (url) {
      window.open(url, target);
    }
  }, [url, target]);

  return null; // This component doesn't render anything
};

export default UrlRedirector;
