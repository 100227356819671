import { PaymentMethod, PaymongoPaymentMethodCode } from 'constants/index';
import { getCountryISOCode } from 'utils';

export const createPaymentMethod = async (paymentDetails, paymentType, configuration) => {
  const url = configuration?.createPaymentMethodUrl || 'https://api.paymongo.com/v1/payment_methods';
  const base64PaymongoPrivateKey = configuration?.base64PaymongoPrivateKey;
  if (!base64PaymongoPrivateKey || !url || !paymentType) {
    return {
      error: 'No URL, or paymentType or private key hash provided',
      success: false,
    };
  }
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${base64PaymongoPrivateKey}`,
    },
    body: JSON.stringify({
      data: {
        attributes: {
          ...([PaymentMethod.CARD, PaymentMethod.BANKTRANSFER_UNION, PaymentMethod.BANKTRANSFER_BPI].includes(
            paymentType
          )
            ? {
                details: {
                  ...(paymentType === PaymentMethod.CARD
                    ? {
                        card_number: paymentDetails?.cardNumber,
                        exp_month: paymentDetails?.expMonth,
                        exp_year: paymentDetails?.expYear,
                        cvc: paymentDetails?.cvv,
                      }
                    : {}),
                  ...([PaymentMethod.BANKTRANSFER_UNION, PaymentMethod.BANKTRANSFER_BPI].includes(paymentType)
                    ? { bank_code: paymentDetails?.bankCode }
                    : {}),
                },
              }
            : {}),
          billing: {
            address: {
              line1: paymentDetails?.address?.addressLine1,
              city: paymentDetails?.address?.city,
              state: paymentDetails?.address?.state,
              postal_code: paymentDetails?.address?.zipCode,
              country: getCountryISOCode(paymentDetails?.address?.country),
            },
            email: paymentDetails?.email,
            name: paymentDetails?.fullName,
            phone: paymentDetails?.phoneNumber,
          },
          type: PaymongoPaymentMethodCode[paymentType],
        },
      },
    }),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error('Error:', response);
      // Try to read the response body as JSON
      let errorBody;
      try {
        errorBody = await response.json();
      } catch {
        // If it's not JSON, read it as text
        errorBody = await response.text();
      }

      return {
        status: response.statusText,
        success: false,
        errors: errorBody?.errors,
      };
    }

    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error:', error);
    return {
      error,
      success: false,
    };
  }
};

export const attachPaymentIntentToPaymentMethod = async (paymentIds, paymentType, configuration) => {
  const returnUrl = configuration?.returnUrl;
  const base64PaymongoPrivateKey = configuration?.base64PaymongoPrivateKey;
  const clientKey = configuration?.clientKey;
  const paymentIntentId = paymentIds?.paymentIntentId;

  const paymentMethodId = paymentIds?.paymentMethodId;
  const url = `https://api.paymongo.com/v1/payment_intents/${paymentIntentId}/attach`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${base64PaymongoPrivateKey}`,
    },
    body: JSON.stringify({
      data: {
        attributes: {
          payment_method: paymentMethodId,
          client_key: clientKey,
          ...([PaymentMethod.PAYMAYA, PaymentMethod.BRANKAS, PaymentMethod.CARD, PaymentMethod.GCASH].includes(
            paymentType
          )
            ? { return_url: returnUrl }
            : {}),
        },
      },
    }),
  };

  try {
    const response = await fetch(url, options);

    if (!response.ok) {
      console.error('Error:', response);
      // Try to read the response body as JSON
      let errorBody;
      try {
        errorBody = await response.json();
      } catch {
        // If it's not JSON, read it as text
        errorBody = await response.text();
      }

      return {
        status: response.statusText,
        success: false,
        errors: errorBody?.errors,
      };
    }
    const json = await response.json();
    return json;
  } catch (error) {
    console.error('Error:', error);
    return {
      status: null,
      success: false,
    };
  }
};
