// event
export const FETCH_EVENT_DASHBOARD_DATA = 'event-portal/FETCH_EVENT_DASHBOARD_DATA';
export const IS_EVENT_DASHBOARD_DATA_FETCHED = 'event-portal/IS_EVENT_DASHBOARD_DATA_FETCHED';
export const IS_EVENT_DASHBOARD_DATA_FETCHING = 'event-portal/IS_EVENT_DASHBOARD_DATA_FETCHING';
export const EVENT_DATA_STATE = 'event-portal/EVENT_DATA_STATE';
export const FETCH_EVENT_DATA = 'event-portal/FETCH_EVENT_DATA';
export const RESET_SELECTED_EVENT = 'event-portal/RESET_SELECTED_EVENT';
export const UPDATE_EVENT_DATA = 'event/UPDATE_EVENT_DATA';
export const UPDATE_DYNAMO_EVENT_DATA = 'event/UPDATE_DYNAMO_EVENT_DATA';
export const SET_UPDATING_EVENT = 'event/SET_UPDATING_EVENT';
export const FETCH_EVENT_REGISTRANTS = 'event/FETCH_EVENT_REGISTRANTS';
export const IS_FETCHING_EVENT_REGISTRANTS = 'event/IS_FETCHING_EVENT_REGISTRANTS';
export const GET_EVENT_REGISTRATION_ORDER_DETAILS = 'event/GET_EVENT_REGISTRATION_ORDER_DETAILS';
export const GET_REGISTRATION_DATA = 'event/GET_REGISTRATION_DATA';
export const IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED = 'event/IS_EVENT_REGISTRATION_ORDER_DETAILS_FETCHED';
export const FETCH_EVENT_STAFFS = 'event/FETCH_EVENT_STAFFS';
export const SET_FETCHING_STATE = 'event/SET_FETCHING_STATE';
export const REMOVE_USER_TO_AN_EVENT = 'event/REMOVE_USER_TO_AN_EVENT';
export const GET_EVENT_STAFF = 'event/GET_EVENT_STAFF';
export const EVENT_STAFF_FETCHING_STATE = 'event/EVENT_STAFF_FETCHING_STATE';
export const ADD_STAFF_TO_EVENT = 'event/ADD_STAFF_TO_EVENT';
export const UPDATE_STAFF_EVENT_ROLE = 'event/UPDATE_STAFF_EVENT_ROLE';
export const FETCH_EVENT_CATEGORIES = 'event/FETCH_EVENT_CATEGORIES';
export const FETCH_EVENT_CATEGORY = 'event/FETCH_EVENT_CATEGORY';
export const CREATE_NEW_CATEGORY = 'event/CREATE_NEW_CATEGORY';
export const DELETE_NEW_CATEGORY = 'event/DELETE_NEW_CATEGORY';
export const UPDATE_CATEGORY = 'event/UPDATE_CATEGORY';
export const UPDATE_DRAFT_FORMS = 'event/UPDATE_DRAFT_FORMS';
export const UPDATE_CATEGORY_FORMS = 'event/UPDATE_CATEGORY_FORMS';
export const UPDATE_CATEGORY_WAVIER = 'event/UPDATE_CATEGORY_WAVIER';
export const REMOVE_CATEGORY_FORM = 'event/REMOVE_CATEGORY_FORM';
export const REMOVE_CATEGORY_WAIVER = 'event/REMOVE_CATEGORY_WAIVER';
export const UPSERT_CATEGORY_FORM = 'event/UPSERT_CATEGORY_FORM';
export const UPSERT_CATEGORY_WAIVER = 'event/UPSERT_CATEGORY_WAIVER';
export const RESET_DRAFT_FORMS = 'event/RESET_DRAFT_FORMS';
export const UPDATE_CATEGORY_DATA = 'event/UPDATE_CATEGORY_DATA';
export const UPDATE_CATEGORY_DATA_PRIZES = 'event/UPDATE_CATEGORY_DATA_PRIZES';
export const CREATE_CATEGORY_KIT = 'event/CREATE_CATEGORY_KIT';
export const UPSERT_CATEGORY_SLOTS = 'event/UPSERT_CATEGORY_SLOTS';
export const GET_EVENT_REGISTRANTS = 'event/GET_EVENT_REGISTRANTS';
export const PUBLISH_EVENT = 'events/PUBLISH_EVENT';

// others
export const UPDATE_IMAGE_LINK = 'others/UPDATE_IMAGE_LINK';
