import React, { useState } from 'react';
import { Card, IconButton, CircularProgress } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const CardWithDelete = ({ onDelete, children, displayDelete }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await onDelete();
    } catch (error) {
      console.error('Delete failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {displayDelete && (
        <IconButton
          aria-label="delete"
          onClick={handleDelete}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          disabled={isLoading}
          style={{
            position: 'absolute',
            top: 2,
            right: 2,
            color: 'red',
            zIndex: 1,
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : isHovered ? <DeleteIcon /> : <DeleteOutlineIcon />}
        </IconButton>
      )}
      <Card sx={{ p: 3, mb: 3, position: 'relative', border: 1 }}>{children}</Card>
    </div>
  );
};

export default CardWithDelete;
