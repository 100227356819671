import React, { useEffect } from 'react';
import { useLocation, useHistory, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { checkAlertType } from 'utils/toastValues';
// components
import ScrollToTop from 'components/ScrollToTop';
import { ProgressBarStyle } from 'components/ProgressBar';
import { BaseOptionChartStyle } from 'components/chart/BaseOptionChart';
import LoadingScreen from 'components/LoadingScreen';
import { AppConstants, ProfileView, Routes } from 'constants/index';
import { getEventIdByPath, getOrganizationIdByPath, getPath, isOrganizationsRoute, whiteListedRoutes } from 'utils';
import { setProfileView } from 'modules/app/actions';
import { setSelectedEventId, setSelectedOrganizationId } from 'modules/organization/actions';
import { constantsSelector } from 'modules/app/selector';
import { Router } from './routes';

export const App = (props) => {
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const history = useHistory();
  const dispatch = useDispatch();
  const appConstants = useSelector(constantsSelector);

  const isAppInMaintenanceMood = appConstants?.[AppConstants.APP_MAINTENANCE] || false;

  const { alerts, initializeApp, removeAllAlerts, isAppFullyInitialized, profile } = props;

  const isOrgProfile = profile === ProfileView.organization;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (alerts && alerts.length > 0) {
      alerts.forEach((alert) => {
        const shouldPersist = Boolean(alert?.message?.length > 20);
        enqueueSnackbar(`${alert?.message ?? ''}`, {
          variant: checkAlertType(alert.type) || 'error',
          persist: shouldPersist,
          key: alert.id,
        });
      });
      removeAllAlerts();
    }
  }, [alerts]);

  useEffect(() => {
    initializeApp(pathname);
  }, []);

  useEffect(() => {
    if (isAppFullyInitialized) {
      if (profile === ProfileView.organization && pathname && !whiteListedRoutes.includes(pathname)) {
        const isOrganizationsRoutes = isOrganizationsRoute(pathname);

        if (pathname === '/' || !isOrganizationsRoutes) {
          return history.push({
            pathname: getPath(Routes.ORGANIZATIONS_DASHBOARD),
            search: queryParams.toString(),
          });
        }

        return history.push({
          pathname,
          search: queryParams.toString(),
        });
      }

      if (profile === ProfileView.user && pathname && isOrganizationsRoute(pathname)) {
        dispatch(setProfileView(ProfileView.organization));

        const organizationId = getOrganizationIdByPath(pathname);
        const eventId = getEventIdByPath(pathname);

        if (organizationId) {
          dispatch(setSelectedOrganizationId(organizationId));
          if (eventId) {
            dispatch(setSelectedEventId(eventId));
          }
        }

        return history.push({
          pathname,
          search: queryParams.toString(),
        });
      }

      if (pathname === '/') {
        return history.push({
          pathname: getPath(Routes.EVENTS),
          search: queryParams.toString(),
        });
      }
    }
  }, [isAppFullyInitialized, profile]);

  useEffect(() => {
    if (isAppFullyInitialized && isAppInMaintenanceMood) {
      return history.push({
        pathname: getPath(Routes.MAINTENANCE),
      });
    }
  }, [isAppFullyInitialized, isAppInMaintenanceMood]);

  const isMatchMaintenance = matchPath(pathname, { path: getPath(Routes.MAINTENANCE), exact: true });

  useEffect(() => {
    if (isAppFullyInitialized && !isAppInMaintenanceMood && isMatchMaintenance) {
      if (isOrgProfile) {
        return history.push({
          pathname: getPath(Routes.ORGANIZATIONS_DASHBOARD),
          search: queryParams.toString(),
        });
      }

      return history.push({
        pathname: getPath(Routes.EVENTS),
        search: queryParams.toString(),
      });
    }
  }, [isAppFullyInitialized, isAppInMaintenanceMood, isMatchMaintenance, isOrgProfile]);

  return (
    <>
      {isAppFullyInitialized ? (
        <>
          <ProgressBarStyle />
          <ScrollToTop />
          <BaseOptionChartStyle />
          <Router {...props} />
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  isEventAdmin: PropTypes.bool.isRequired,
  alertsSelector: PropTypes.array,
  removeAlert: PropTypes.func,
  initializeApp: PropTypes.func.isRequired,
  isAppFullyInitialized: PropTypes.bool.isRequired,
};
