import * as yup from 'yup';

/**
 * Safely parses validation parameters
 * @param {string|Array} params - Validation parameters
 * @returns {Array} Parsed parameters
 */
const parseValidationParams = (params) => {
  try {
    return typeof params === 'string' ? JSON.parse(params) : params;
  } catch (error) {
    console.warn('Failed to parse validation params:', error);
    return Array.isArray(params) ? params : [params];
  }
};

/**
 * Creates a Yup schema based on configuration settings
 * @param {Object} schema - The base schema object
 * @param {Object} config - Configuration object containing field settings
 * @param {string} config.fieldId - The field identifier
 * @param {string} config.validationType - The Yup validation type (e.g., 'string', 'number')
 * @param {Array} config.validations - Array of validation rules
 * @returns {Object} Updated schema object
 */
export const createYupSchema = (schema, config) => {
  const { fieldId, validationType, validations = [] } = config;

  // Return early if validation type doesn't exist in Yup
  if (!yup[validationType]) {
    return schema;
  }

  // Check if the field has a required validation
  const hasRequiredValidation = validations.some((validation) => validation.type === 'required');

  // Initialize the validator
  let validator = yup[validationType]();

  // For number fields without required validation, allow empty values
  if (validationType === 'number' && !hasRequiredValidation) {
    validator = validator
      .transform((value, originalValue) => {
        // If it's an empty string or undefined, return undefined
        // This prevents Yup from trying to cast empty values to numbers
        if (originalValue === '' || originalValue === undefined) {
          return undefined;
        }
        // For actual numbers or valid number strings, return the parsed value
        return value;
      })
      .nullable()
      .notRequired(); // This is key to allow undefined values
  }

  // Apply all validations
  validations.forEach((validation) => {
    const { params, type } = validation;

    // Skip if validation method doesn't exist
    if (!validator[type]) {
      return;
    }

    // Parse params and apply the validation
    const parsedParams = parseValidationParams(params);
    validator = validator[type](...parsedParams);
  });

  // Add the validator to the schema
  schema[fieldId] = validator;
  return schema;
};
