import { Suspense } from 'react';
import LazyLoad from 'react-lazyload';
import { useLocation } from 'react-router-dom';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import DashboardLayout from 'layouts/dashboard';
// components
import LoadingScreen from 'components/LoadingScreen';

export const LayoutType = {
  LogoOnlyLayout: 'LogoOnlyLayout',
  DashboardLayout: 'DashboardLayout',
};

export const Layout = (children, options) => ({
  [LayoutType.LogoOnlyLayout]: <LogoOnlyLayout children={children} disabledLink={options?.disabledLink} />,
  [LayoutType.DashboardLayout]: <DashboardLayout children={children} shouldShowNavBar={options?.shouldShowNavBar} />,
});

export const LazyLoader = ({ children, ComponentLayout, ...props }) => {
  if (ComponentLayout) {
    return (
      <LazyLoad placeholder={<LoadingScreen isDashboard={!!children} />} {...props}>
        {Layout(children)[ComponentLayout]}
      </LazyLoad>
    );
  }
  return <LazyLoad {...props}>{children}</LazyLoad>;
};

export const Loadable =
  (Component, ComponentLayout, options = null) =>
  (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
      <Suspense fallback={<LoadingScreen />}>
        {ComponentLayout ? Layout(<Component {...props} />, options)[ComponentLayout] : <Component {...props} />}
      </Suspense>
    );
  };
