import { useState, useEffect } from 'react';
import usePaymentIntentStatus from 'hooks/usePaymentIntentStatusHook';

export const PaymentProgressModal = ({ paymentIntentId, publicKey, customLoading = false }) => {
  const { status, error } = usePaymentIntentStatus(paymentIntentId, publicKey);
  const [loading, setLoading] = useState(customLoading);

  const modalContent = () => {
    return <></>;
  };

  const modalToRender = () => {
    return <></>;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!status) {
    return <div>Waiting for payment status...</div>;
  }

  switch (status) {
    case 'succeeded':
      return <div>Payment successful!</div>;
    case 'awaiting_payment_method':
      return <div>Payment processing error. Please try again.</div>;
    case 'processing':
      return <div>Payment is being processed...</div>;
    default:
      return <div>Unknown payment status: {status}</div>;
  }
};
