// @mui
import { Grid, Skeleton, Card, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonEvents() {
  return (
    <Grid container spacing={3}>
      {[...Array(9)].map((val, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Card>
            <Skeleton variant="rectangular" sx={{ paddingTop: '100%' }} />
            <Stack spacing={2} sx={{ p: 3 }}>
              <Skeleton variant="text" sx={{ width: 0.5 }} />
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row">
                  <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
                  <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
                  <Skeleton variant="circular" sx={{ width: 16, height: 16 }} />
                </Stack>
                <Skeleton variant="text" sx={{ width: 40 }} />
              </Stack>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
