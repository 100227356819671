export const ADD_TO_USERCHOICE_CATEGORIES = 'registration/ADD_TO_USERCHOICE_CATEGORIES';
export const QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY = 'registration/QUANTITY_CHANGE_TO_USERCHOICE_CATEGORY';
export const CREATE_DRAFT_REGISTRATION = 'registration/CREATE_DRAFT_REGISTRATION';
export const REGISTRATION_ORDER_DATA = 'registration/REGISTRATION_ORDER_DATA';
export const UPDATE_REGISTRATION_ORDER = 'registration/UPDATE_REGISTRATION_ORDER';
export const FETCH_USER_REGISTRATION_DETAILS = 'registration/FETCH_USER_REGISTRATION_DETAILS';
export const CHANGE_REGISTRATION_PROGRESS = 'registration/CHANGE_REGISTRATION_PROGRESS';
export const DELETE_REGISTRATION_ORDER = 'registration/DELETE_REGISTRATION_ORDER';
export const RESET_CART_ORDER = 'registration/RESET_CART_ORDER';

export const UPSERT_REGISTRANT_DATA = 'registration/UPSERT_REGISTRANT_DATA';
export const UPDATE_REGISTRATION_FETCHED_STATUS = 'registration/UPDATE_REGISTRATION_FETCHED_STATUS';
export const CALCULATE_REGISTRATION_CART_SUMMARY = 'registration/CALCULATE_REGISTRATION_CART_SUMMARY';
export const GET_CALCULATED_REGISTRATION_CART = 'registration/GET_CALCULATED_REGISTRATION_CART';
export const DELETE_LINE_ITEM = 'registration/DELETE_LINE_ITEM';
export const CREATE_PAYMENT_INTENT = 'registration/CREATE_PAYMENT_INTENT';
export const FETCH_PAYMENT_OPTIONS = 'registration/FETCH_PAYMENT_OPTIONS';
export const FETCHING_REGISTRATION_CART_SUMMARY = 'registration/FETCHING_REGISTRATION_CART_SUMMARY';
export const PRE_CHECKOUT_CHECKER = 'registration/PRE_CHECKOUT_CHECKER';
export const REMOVE_FEES_FROM_CART = 'registration-cart/REMOVE_FEES_FROM_CART';

export const UPSERT_AGREEMENTS = 'registration-cart/UPSERT_AGREEMENTS';
export const GET_USER_REGISTERED_PARTICIPANTS_DATA = 'registration-cart/GET_USER_REGISTERED_PARTICIPANTS_DATA';
