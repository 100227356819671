import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Iconify from 'components/Iconify';
import { formatCategoryValue, getTimeDifference, sortDistances } from 'utils';
import { TimeReturnType } from 'constants';

const columns = ['Category', 'Assembly Time', 'Gun Time', 'Cutoff Time'];

export default function CategoriesInfo({ event }) {
  const { categories: categoriesList, metricUsedIsKm } = event;

  const categories = categoriesList?.items || [];

  const sortedCategoriesByDistance = sortDistances(categories);

  const rows = sortedCategoriesByDistance?.reduce((acc, category) => {
    acc.push({
      distance: formatCategoryValue(metricUsedIsKm, category),
      assemblyTime: category?.assemblyTime ? moment(category?.assemblyTime).format('HH:mm') : 'Not indicated',
      gunTime: !category?.gunTime ? 'Not indicated' : moment(category?.gunTime).format('HH:mm') || '',
      cutoffTime: !category?.cutOffTime
        ? 'Not indicated'
        : getTimeDifference(category?.cutOffTime, category?.gunTime, TimeReturnType.MinuteSecAndInWord) || '',
    });

    return acc;
  }, []);

  return <CollapsibleTable rows={rows} columnHeaders={columns || []} minWidth={400} />;
}

const CollapsibleTable = ({ columnHeaders, rows }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {(columnHeaders ?? [])?.map((column, index) => (
              <TableCell key={index}>{column}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{row?.distance}</TableCell>
                  <TableCell>{row?.assemblyTime}</TableCell>
                  <TableCell>{row?.gunTime}</TableCell>
                  <TableCell>{row?.cutoffTime}</TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
