import { AppConstants, Roles } from 'constants/index';
import { createSelector } from 'reselect';

export const appSelector = createSelector(
  (state) => state.app,
  (app) => app
);

export const isInitializedSelector = createSelector(appSelector, (app) => app.isInitialized);

export const profileSelector = createSelector(appSelector, (app) => app.profile);

export const featureTogglesSelector = createSelector(appSelector, (app) => app.featureToggles);

export const constantsSelector = createSelector(appSelector, (app) => app.constants);

export const adminHostnameSelector = createSelector(
  constantsSelector,
  (constants) => constants?.[AppConstants.ADMIN_HOSTNAME]
);

export const isAdminPortalSelector = createSelector(
  adminHostnameSelector,
  (adminHostname) => adminHostname === window.location.hostname
);

export const appAdminSelector = createSelector(appSelector, (app) => app?.admin);

export const redirectPathSelector = createSelector(appSelector, (app) => app?.redirectPath);

export const appAdminEmailSelector = createSelector(appAdminSelector, (admin) => admin?.email);

export const appAdminRolesSelector = createSelector(appAdminSelector, (admin) => admin?.roles);

export const isAppAdminHasAdminRoles = createSelector(
  [appAdminRolesSelector, isAdminPortalSelector],
  (roles, isAdminPortal) => isAdminPortal && roles?.includes(Roles.ADMIN)
);
