import React from 'react';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';

const CardNumberInput = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange(values.value);
      }}
      format="####-####-####-####"
      mask="_"
      allowEmptyFormatting
      customInput={TextField}
    />
  );
});

const RHFCardNumberInput = ({ name, control, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CardNumberInput
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
          fullWidth
          label="Card Number"
          {...rest}
        />
      )}
    />
  );
};

export default RHFCardNumberInput;
