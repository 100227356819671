import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';

import DateTimePicker from '@mui/lab/DateTimePicker';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  Radio,
  Stack,
  Button,
  Collapse,
  TextField,
  Typography,
  RadioGroup,
  CardHeader,
  CardContent,
  FormHelperText,
  FormControlLabel,
  Alert,
} from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
import moment from 'moment-timezone';
import Image from 'components/Image';
import Iconify from 'components/Iconify';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { isPaymentOptionFetchedSelector } from 'modules/registration/selector';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAvailablePaymentOptions } from 'modules/registration/actions';
import { PaymentMethod } from 'constants/index';
import { RHFCardNumberInput, RHFTextField } from 'components/hook-form';
import { LoadingButton } from '@mui/lab';
import { capitalCase } from 'change-case';

CheckoutPaymentMethods.propTypes = {
  paymentOptions: PropTypes.array,
};

export default function CheckoutPaymentMethods({
  paymentOptions,
  cardOptions = [],
  paymentType,
  enableNextCard,
  cardNumber,
  handlePayment,
  enabledSubmitButton,
  isSubmitting,
  paymentErrors = [],
  isCartSummaryFetchedSuccess,
}) {
  const { control, setValue } = useFormContext();
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const isMountedComponentRef = useIsMountedRef();

  const isDesktop = useResponsive('up', 'sm');

  const isPaymentOptionsFetched = useSelector(isPaymentOptionFetchedSelector);

  // Set Payment if Available is one
  useEffect(() => {
    if (paymentOptions?.length === 1 && enableNextCard === cardNumber && isMountedRef.current) {
      setValue('payment', paymentOptions?.[0]?.value);
      return () => {
        isMountedRef.current = false;
      };
    }
  }, [paymentOptions, cardNumber, enableNextCard, isMountedRef]);

  // Set Payment if Available is one
  useEffect(() => {
    if (isMountedComponentRef.current && !isPaymentOptionsFetched) {
      (async () => await dispatch(fetchAvailablePaymentOptions()))();
      return () => {
        isMountedComponentRef.current = false;
      };
    }
  }, [isPaymentOptionsFetched, isMountedComponentRef.current, dispatch]);

  const renderErrors = () => {
    return (
      paymentErrors?.length > 0 && (
        <Alert severity="error" sx={{ marginBottom: 2, marginTop: 2 }} icon={false}>
          <ol style={{ paddingRight: 2, paddingLeft: 2 }}>
            {paymentErrors?.map((error, index) => (
              <li key={index}>
                <b>{capitalCase(error?.source?.attribute || '')}</b> - <span>{error?.detail}</span>
              </li>
            ))}
          </ol>
        </Alert>
      )
    );
  };

  return (
    <Card sx={{ my: 3 }}>
      <CardHeader
        title={'Payment options'}
        action={
          <>
            {(!isPaymentOptionsFetched || !isCartSummaryFetchedSuccess) && (
              <Button size="small" startIcon={<Iconify icon={'line-md:loading-twotone-loop'} color="#00b3ff" />}>
                {!isCartSummaryFetchedSuccess ? 'Calculating...' : 'Fetching...'}
              </Button>
            )}
          </>
        }
      />
      <CardContent>
        <Controller
          name="payment"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <RadioGroup row {...field}>
                <Stack spacing={2} sx={{ width: '100%' }}>
                  {paymentOptions?.map((method) => {
                    const { value, title, icons, description } = method;

                    const hasChildren = value === PaymentMethod.CARD;

                    const selected = field.value === value;

                    const shouldDisableButton = cardNumber !== enableNextCard || !isCartSummaryFetchedSuccess;

                    return (
                      <OptionStyle
                        key={title}
                        sx={{
                          ...(selected && {
                            boxShadow: (theme) => theme.customShadows.z20,
                          }),
                          ...(hasChildren && { flexWrap: 'wrap' }),
                        }}
                      >
                        <Stack sx={{ width: '100%' }}>
                          {![PaymentMethod.CARD].includes(paymentType) && selected && renderErrors()}

                          <FormControlLabel
                            value={value}
                            disabled={shouldDisableButton}
                            control={
                              <Radio
                                checkedIcon={
                                  <>
                                    {!isCartSummaryFetchedSuccess ? (
                                      <Iconify icon={'line-md:loading-twotone-loop'} color="#00b3ff" />
                                    ) : (
                                      <Iconify icon={'eva:checkmark-circle-2-fill'} />
                                    )}
                                  </>
                                }
                              />
                            }
                            label={
                              <Box sx={{ ml: 1 }}>
                                <Typography variant="subtitle2">{title}</Typography>

                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                  {description}
                                </Typography>
                              </Box>
                            }
                            sx={{ flexGrow: 1, py: 3 }}
                          />
                        </Stack>

                        {isDesktop && (
                          <Stack direction="row" spacing={1} flexShrink={0}>
                            {icons?.map((icon) => (
                              <Image key={icon} alt="logo card" src={icon} />
                            ))}
                          </Stack>
                        )}

                        {hasChildren && (
                          <Collapse in={field.value === PaymentMethod.CARD} sx={{ width: 1 }}>
                            {/* <TextField select fullWidth label="Cards" SelectProps={{ native: true }}>
                              {cardOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </TextField>

                            <Button
                              size="small"
                              startIcon={<Iconify icon={'eva:plus-fill'} width={20} height={20} />}
                              sx={{ my: 3 }}
                            >
                              Add new card
                            </Button> */}

                            <Collapse in>
                              <Box
                                sx={{
                                  padding: 3,
                                  marginBottom: 3,
                                  borderRadius: 1,
                                  bgcolor: 'background.neutral',
                                }}
                              >
                                <Alert severity="info" sx={{ marginBottom: 2 }} icon={false}>
                                  <b>Your CARD details are SAFE with us</b>. We use <b>PayMongo</b> to process payments
                                  directly. All transactions are handled client-side for maximum security. This means
                                  your card data is never sent to our server, providing an extra layer of protection.
                                </Alert>
                                {renderErrors()}
                                <Stack spacing={3}>
                                  <Typography variant="subtitle1">Card Details</Typography>

                                  <RHFTextField name="nameOnCard" control={control} fullWidth label="Name on Card" />

                                  <RHFCardNumberInput name="cardNumber" control={control} />

                                  <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Controller
                                      name="expirationMonth"
                                      control={control}
                                      render={({ field: eDField, fieldState: { error: edError } }) => {
                                        const currentDate = new Date();
                                        const currentYear = currentDate.getFullYear();

                                        return (
                                          <DateTimePicker
                                            label="Expiration Month"
                                            views={['month']}
                                            openTo="month"
                                            inputFormat="MM"
                                            value={eDField.value ? moment(eDField.value, 'MM') : null}
                                            onChange={(newValue) => {
                                              eDField.onChange(newValue ? moment(newValue).format('MM') : '');
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                error={!!edError}
                                                helperText={edError?.message || ''}
                                              />
                                            )}
                                            maxDate={new Date(currentYear, 11, 31)}
                                            showToolbar={false}
                                          />
                                        );
                                      }}
                                    />

                                    <Controller
                                      name="expirationYear"
                                      control={control}
                                      render={(fieldInput) => {
                                        const { field: eDField, fieldState: edFieldState } = fieldInput;
                                        const { error: edError } = edFieldState;
                                        const currentYear = new Date().getFullYear();
                                        const minDate = new Date(currentYear, 0, 1);
                                        const maxDate = new Date(currentYear + 10, 11, 31); // 10 years into the future

                                        return (
                                          <DateTimePicker
                                            label="Expiration Year"
                                            views={['year']}
                                            openTo="year"
                                            inputFormat="yyyy"
                                            value={eDField.value ? moment(eDField.value, 'yyyy') : null}
                                            onChange={(newValue) => {
                                              eDField.onChange(newValue ? moment(newValue).format('yyyy') : '');
                                            }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                error={!!edError}
                                                helperText={edError?.message || ''}
                                              />
                                            )}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            showToolbar={false}
                                          />
                                        );
                                      }}
                                    />
                                  </Stack>
                                  <RHFTextField name="cvv" control={control} fullWidth label="cvv" />

                                  <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
                                    <LoadingButton
                                      type="submit"
                                      variant="contained"
                                      onClick={handlePayment}
                                      disabled={!enabledSubmitButton}
                                      loading={isSubmitting}
                                    >
                                      Pay Now
                                    </LoadingButton>
                                  </Stack>
                                </Stack>
                              </Box>
                            </Collapse>
                          </Collapse>
                        )}
                      </OptionStyle>
                    );
                  })}
                </Stack>
              </RadioGroup>

              {!!error && (
                <FormHelperText error sx={{ pt: 1, px: 2 }}>
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
      </CardContent>
    </Card>
  );
}

const OptionStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  transition: theme.transitions.create('all'),
  border: `solid 1px ${theme.palette.divider}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));
