export const NeedsCTXQueries = [
  'createToken',
  'verifyToken',
  'upsertUser',
  'addUserToGroup',
  'createDraftRegistration',
  'resetDraftRegistration',
  'getCalculatedRegistrationCart',
  'updateCategoriesInventory',
  'updateAmountAccumulated',
  'preCheckoutChecker',
  'createPaymentIntent',
  'fetchPaymentMethodsAvailable',
  'createAnOrganization',
  'deleteAnOrganization',
  'updateAnOrganization',
  'removeStaffsFromOrganization',
  'batchDeleteUserOrganizationEventJunction',
  'createNewEvent',
  'updateAnEvent',
  'deleteAnEvent',
  'createNewCategory',
  'updateCategory',
  'deleteCategory',

  // Queries
  'getOrganizationDashboardData',
  'fetchEventPortalDashboardData',
];

export const PriceType = {
  REGULAR_PRICE: 'Regular Price',
};

export const AMPLIFY_ENVIRONMENT = {
  DEVELOP: 'develop',
  PRODUCTION: 'production',
  TESTING: 'testing',
};

export const ReturnType = {
  SortedNameValue: 'SortedNameValue',
  ValueOnlyWithCurrencyFormat: 'ValueOnlyWithCurrencyFormat',
  ValueWithValueValidity: 'ValueWithValueValidity',
};

export const FeeType = {
  SERVICE_FEE: 'service-fee',
  TRANSACTION_FEE: 'transaction-fee',
  TAX_FEE: 'tax-fee',
  DELIVERY_FEE: 'delivery-fee',
};

export const FeatureToggle = {
  FEATURE_ADMIN: 'FEATURE_ADMIN',
};

export const AppConstants = {
  ADMIN_HOSTNAME: 'ADMIN_HOSTNAME',
  REGISTRATION_SIGNUP_PORTAL_AGREEMENT: 'REGISTRATION_SIGNUP_PORTAL_AGREEMENT',
  GENERAL_EVENT_REGISTRATION_WAIVER: 'GENERAL_EVENT_REGISTRATION_WAIVER',
  DATA_SHARING_AGREEMENT: 'DATA_SHARING_AGREEMENT',
  PLATFORM_AGREEMENT: 'PLATFORM_AGREEMENT',
  EVENT_CREATION_AGREEMENT: 'EVENT_CREATION_AGREEMENT',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  DEFAULT_WAIVER: 'DEFAULT_WAIVER',

  // Company Info
  COMPANY_NAME: 'COMPANY_NAME',
  COMPANY_ADDRESS: 'COMPANY_ADDRESS',
  COMPANY_CONTACT_PERSON: 'COMPANY_CONTACT_PERSON',
  COMPANY_SUPPORT_EMAIL: 'COMPANY_SUPPORT_EMAIL',
  COMPANY_COMPANY_MOBILE_NUMBER: 'COMPANY_COMPANY_MOBILE_NUMBER',

  // Paymongo
  PAYMONGO_PUBLIC_API_KEY_HASH: 'PAYMONGO_PUBLIC_API_KEY_HASH',
  PAYMONGO_CREATE_PAYMENT_METHOD_URL: 'PAYMONGO_CREATE_PAYMENT_METHOD_URL',

  // OTHERS
  BUG_FORM_URL: 'BUG_FORM_URL',
  SUPPORT_EMAIL: 'SUPPORT_EMAIL',

  PAYMENT_OPTIONS_STATE: 'PAYMENT_OPTIONS_STATE',
  APP_MAINTENANCE: 'APP_MAINTENANCE',
};

export const EVENT_STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  DONE: 'DONE',
  NO_SLOTS_AVAILABLE: 'NO_SLOTS_AVAILABLE',
};

export const PaymentErrorType = {
  PaymentMethodNoListError: 'PaymentMethodNoListError',
  ErrorRetrievingPaymentMethods: 'ErrorRetrievingPaymentMethods',
  ErrorOnCreatingPaymentIntent: 'ErrorOnCreatingPaymentIntent',
  QuantitySelectionError: 'QuantitySelectionError',
};

export const PaymentErrorTypeMessage = {
  [PaymentErrorType.PaymentMethodNoListError]: 'No list found on our merchant',
  [PaymentErrorType.ErrorRetrievingPaymentMethods]: 'Error retrieving payment methods',
  [PaymentErrorType.QuantitySelectionError]: 'Some quantity being selected are/is out of stock',
  [PaymentErrorType.ErrorOnCreatingPaymentIntent]: 'Error on creating payment intent',
};

export const PaymentMethod = {
  GCASH: 'GCASH',
  PAYMAYA: 'PAYMAYA',
  CARD: 'CARD',
  BANKTRANSFER_UNION: 'BANKTRANSFER_UNION',
  BANKTRANSFER_BPI: 'BANKTRANSFER_BPI',
  GRAB_PAY: 'GRAB_PAY',
  BILLEASE: 'BILLEASE',
  QR_PH: 'QR_PH',
  BRANKAS_BDO: 'BRANKAS_BDO',
  BRANKAS_LANDBANK: 'BRANKAS_LANDBANK',
  BRANKAS_METROBANK: 'BRANKAS_METROBANK',
};

export const PaymongoPaymentMethodCode = {
  [PaymentMethod.GCASH]: 'gcash',
  [PaymentMethod.PAYMAYA]: 'paymaya',
  [PaymentMethod.CARD]: 'card',
  [PaymentMethod.BANKTRANSFER_UNION]: 'dob_ubp',
  [PaymentMethod.BANKTRANSFER_BPI]: 'dob',
  [PaymentMethod.GRAB_PAY]: 'grab_pay',
  [PaymentMethod.BILLEASE]: 'billease',
  [PaymentMethod.QR_PH]: 'qrph',
  [PaymentMethod.BRANKAS_BDO]: 'brankas_bdo',
  [PaymentMethod.BRANKAS_LANDBANK]: 'brankas_landbank',
  [PaymentMethod.BRANKAS_METROBANK]: 'brankas_metrobank',
};

export const PaymentMethodData = {
  [PaymentMethod.GCASH]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.GCASH],
  },
  [PaymentMethod.PAYMAYA]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.PAYMAYA],
  },
  [PaymentMethod.CARD]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.CARD],
  },
  [PaymentMethod.BANKTRANSFER_UNION]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BANKTRANSFER_UNION],
  },
  [PaymentMethod.BANKTRANSFER_BPI]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BANKTRANSFER_BPI],
  },
  [PaymentMethod.GRAB_PAY]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.GRAB_PAY],
  },
  [PaymentMethod.BILLEASE]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BILLEASE],
  },
  [PaymentMethod.QR_PH]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.QR_PH],
  },
  [PaymentMethod.BRANKAS_BDO]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BRANKAS_BDO],
  },
  [PaymentMethod.BRANKAS_LANDBANK]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BRANKAS_LANDBANK],
  },
  [PaymentMethod.BRANKAS_METROBANK]: {
    type: PaymongoPaymentMethodCode[PaymentMethod.BRANKAS_METROBANK],
  },
};

// ACTIVITY

export const ActivityAction = {
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export const ActivityTable = {
  EVENT: 'EVENT',
  REGISTRANT_DATA: 'REGISTRANT_DATA',
  ORGANIZATION: 'ORGANIZATION',
  PAYOUT: 'PAYOUT',
};

export const PaymongoPaymentStatus = {
  AWAITING_PAYMENT_METHOD: 'awaiting_payment_method',
  AWAITING_NEXT_ACTION: 'awaiting_next_action',
  PROCESSING: 'processing',
  SUCCESS: 'succeeded',
};
