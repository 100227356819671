import uuidv4 from 'utils/uuidv4';
import { FieldInitialValues, FieldsToInclude, PriceType, QueryParams, Routes } from 'constants/index';
import { camelCase, snakeCase } from 'change-case';
import qs from 'qs';
import { generatePath } from 'react-router-dom';
import { formatCategoryTypeAndValue } from 'utils/eventHelper';
import { getNumericChars, hasNumber } from 'utils/stringHelper';
import { FieldProperties, fieldTypes } from 'components/hook-form';
import moment from 'moment-timezone';
import { getPath } from './routes';

export const formFieldsRegrouping = (originalFormFields, newFormFields = null, numberOfRequiredForm) => {
  if (numberOfRequiredForm === 1 || !newFormFields) {
    return [originalFormFields];
  }
  const originalFormFieldsLength = Object.keys(originalFormFields).length;

  const newFormFieldsLength = Object.keys(newFormFields).length;

  if (newFormFieldsLength % originalFormFieldsLength) {
    console.log('object between newForm original form is not equal');
    return [originalFormFields];
  }

  return [...Array(numberOfRequiredForm)].reduce(
    (acc, _, index) => [
      ...acc,
      newFormFields.slice(index * originalFormFieldsLength, (index + 1) * originalFormFieldsLength),
    ],
    []
  );
};

const toCent = (amount) => {
  const str = amount.replace(',', '.');
  return str.length < 3 || str[str.length - 3] === '.'
    ? Number(str.replace('.', ''))
    : Number(str.replace('.', '')) * 100;
};

export const categoryFeePriceCreator = (price, currency = 'PHP', registrationEnd, registrationStart) => {
  const parseFloatPrice = Number.parseFloat(price).toFixed(2);

  const amountInCent = toCent(parseFloatPrice);

  return {
    type: PriceType.REGULAR_PRICE,
    currency: currency || 'PHP',
    amountInCent,
    enabled: true,
    priceValidFrom: registrationStart || '',
    priceValidTo: registrationEnd || '',
  };
};

const formatToCent = (price) => {
  const parseFloatPrice = Number.parseFloat(price).toFixed(2);
  return toCent(parseFloatPrice);
};

export const formatFees = (prices, eventCreatedDate, eventEndDate) => {
  return prices?.map((price) => {
    return {
      type: price?.type || PriceType.REGULAR_PRICE,
      currency: price?.currency || 'PHP',
      amountInCent: formatToCent(price?.amountInCent || '0'),
      enabled: Boolean(price?.enabled),
      priceValidFrom: moment(price?.priceValidFrom || eventCreatedDate).toISOString(),
      priceValidTo: moment(price?.priceValidTo || eventEndDate).toISOString(),
    };
  });
};

export const sortFormFields = (formFields) => formFields?.sort((a, b) => a?.orderNumber - b?.orderNumber);

export const sortForms = (forms) => forms?.sort((a, b) => a?.formOrderNumber - b?.formOrderNumber);

export const sortFormsByLatestUpdate = (forms) =>
  forms?.sort((a, b) => (moment(a?.updatedAt).isBefore(moment(b?.updatedAt)) ? 1 : -1));

export const sortFormFieldsByIndex = (formFields) =>
  formFields?.reduce(
    (acc, item, index) => [
      ...acc,
      {
        ...item,
        orderNumber: index,
      },
    ],
    []
  );

export const reconstructedFieldValidations = (field, isExistingId) => {
  const isTextField = field?.fieldType === fieldTypes.TextBox;
  const newFieldId = isExistingId ? `${field?.fieldId}_${uuidv4(4)}` : field?.fieldId || '';

  if (!isTextField) {
    return {
      ...field,
      ...(newFieldId && { [FieldProperties.FIELD_ID]: newFieldId }),
    };
  }

  const type = field?.type;
  const isEmailType = type === FieldProperties.TYPE_EMAIL;
  let validations = field?.validations;

  // Email Type remove Min and Max
  if (isEmailType) {
    validations = validations.filter(
      (item) => item?.type !== FieldProperties.MIN_VALUE && item?.type !== FieldProperties.MAX_VALUE
    );
  }

  return {
    ...field,
    ...(newFieldId && { [FieldProperties.FIELD_ID]: newFieldId }),
    validations,
  };
};

export const removeNewFieldId = ({ newFieldId = undefined, ...rest }) => ({ ...rest });

export const formFieldInitiator = (fieldType = fieldTypes.TextBox, index = 0) => {
  return FieldInitialValues(index)[fieldType];
};

export const constructPrizeValue = (prizeValue = []) =>
  prizeValue
    ?.map((item) => ` ${item?.prizeAmountType || ''} ${item?.prizeAmountValue ? ` - ${item?.prizeAmountValue}` : ''}`)
    .join(`, `);

export const sortedCategoryFormsByLastUpdate = (category) =>
  category?.waiverForm?.items.sort((a, b) => moment(b.updatedAt).diff(moment(a.updatedAt))) || {};

// CANNOT BE USED IN BACKEND
// should be populated with distancesSelector
export const isDistanceAlreadyExistsChecker = ({ formValues, distances, metricUsedIsKm, distanceSelectValue }) => {
  // format first distances value
  distances = distances?.reduce((acc, item) => {
    acc.push({
      ...item,
      categoryTypeAndValue: camelCase(item?.categoryTypeAndValue)?.toLowerCase(),
    });
    return acc;
  }, []);
  const { distance, categoryValue } = getDistanceTypeAndValueFromForm({
    distanceSelect: distanceSelectValue,
    formCategoryValue: formValues?.categoryValue,
    categoryType: formValues?.categoryType,
    distanceValue: formValues?.distance,
  });

  const formDistance = {
    distance,
    categoryValue,
    categoryType: formValues?.categoryType,
  };

  return isDistanceAlreadyExists({ metricUsedIsKm, distance: formDistance, distances });
};

// should be populated with distancesSelector
export const isDistanceAlreadyExists = ({ distances, distance, metricUsedIsKm }) => {
  const distanceFromForm = camelCase(formatCategoryTypeAndValue(metricUsedIsKm, distance)).toLowerCase();
  return distances?.map((dist) => dist?.categoryTypeAndValue)?.includes(distanceFromForm);
};

export const getDistanceTypeAndValueFromForm = ({ distanceSelect, formCategoryValue, distanceValue, categoryType }) => {
  let distance = '';
  let categoryValue = formCategoryValue;

  if (distanceSelect !== 'Other') {
    if (hasNumber(distanceSelect)) {
      distance = `${getNumericChars(distanceSelect)}`;
    } else {
      distance = `${distanceSelect}`;
    }
  } else {
    distance = `${getNumericChars(distanceValue)}`;
  }

  if (categoryType?.toLocaleLowerCase() === 'open') {
    categoryValue = '';
  }

  return {
    distance,
    categoryValue,
  };
};

export const sortCategoriesByDistance = (categories, ascending = true) =>
  sortDistances(
    categories?.items?.reduce((acc, category) => {
      const indexOfDistanceIfItsInTheList = acc.findIndex((cat) => cat?.distance === category?.distance);

      if (indexOfDistanceIfItsInTheList && indexOfDistanceIfItsInTheList === -1) {
        acc.push({
          distance: category?.distance,
          subCategory: [
            ...(category?.categoryValue && [`${category?.categoryType || ''} - ${category?.categoryValue}`]),
          ],
        });
      } else if (indexOfDistanceIfItsInTheList > -1) {
        acc[indexOfDistanceIfItsInTheList].subCategory = [
          ...acc[indexOfDistanceIfItsInTheList].subCategory,
          ...(category?.categoryValue && [`${category?.categoryType || ''} - ${category?.categoryValue}`]),
        ];
      }

      return acc;
    }, []),
    ascending
  );

export const sortDistances = (distances, ascend = true) => {
  const sortAscendingDistance = () => {
    // Separate numbers from non-numbers
    const sortedDistance = distances.filter((item) => !Number.isNaN(parseFloat(item?.distance || item)));
    const nonNumbersDistances = distances.filter((item) => Number.isNaN(parseFloat(item?.distance || item)));

    // Sort numbers in descending order
    sortedDistance.sort((a, b) => (b?.distance || b).localeCompare(a?.distance || a, undefined, { numeric: true }));

    // Combine non-numbers and sorted numbers
    const sortedDistances = nonNumbersDistances.concat(sortedDistance);

    // Move "Marathon" before "Half-Marathon"
    const marathonIndex = sortedDistances.indexOf('Marathon');
    if (marathonIndex > 0) {
      sortedDistances.splice(marathonIndex, 1);
      sortedDistances.unshift('Marathon');
    }

    return sortedDistances;
  };

  if (!ascend) {
    return sortAscendingDistance(distances).reverse();
  }

  return sortAscendingDistance(distances);
};

export const sortSortedDistanceWithData = (arr) => {
  // Create a map to store the original indices
  const indexMap = new Map(arr.map((item, index) => [item, index]));

  // Helper function to compare registrantDataLength values
  const compareRegistrantDataLength = (a, b) => {
    if (a === 0 && b === 0) return 0;
    if (a === 0) return 1;
    if (b === 0) return -1;
    return b - a; // Sort in descending order
  };

  // Sort the array
  return arr.sort((a, b) => {
    // If both items have the same id, prioritize based on registrantDataLength
    if (a.distance === b.distance) {
      const comparison = compareRegistrantDataLength(a.registrantDataLength, b.registrantDataLength);
      if (comparison !== 0) return comparison;
      // If registrantDataLength values are the same, maintain original order
      return indexMap.get(a) - indexMap.get(b);
    }
    // If distances are different, maintain original order
    return indexMap.get(a) - indexMap.get(b);
  });
};

export const registrantsDataFormatter = (items, distances, options) =>
  items?.reduce((acc, registrantData) => {
    const {
      lineItemId,
      orderId,
      eventId,
      formOrderNumber,
      lineItem,
      userData,
      createdAt,
      updatedAt,
      bibNumber,
      userEmail,
      categoryId,
      category,
      id,
      fullName,
      ...rest
    } = registrantData;

    const userDataParsed = JSON.parse(userData || '{}');

    let paidDate = createdAt;
    let agreementsLink = '';

    const othersFields = {};

    if (options?.addPrice) {
      const paymentInfo = lineItem?.registrationOrder?.paymentInformation;
      const paymentBreakdown = paymentInfo?.breakDown;
      const paymentDate = paymentInfo?.createAt;
      const paymentAmountInCent = paymentBreakdown?.find((item) => item?.id === categoryId)?.price?.amountInCent;

      if (paymentDate) {
        paidDate = paymentDate;
      }

      if (paymentAmountInCent && paymentAmountInCent > 0) {
        othersFields[FieldsToInclude.paidPrice] = paymentAmountInCent / 100;
      }

      // AgreementsLinks
    }
    const organizationId = lineItem?.category?.event?.organization?.id;

    if (organizationId && eventId) {
      const queryParams = { [QueryParams.REGISTRANT_NAME]: `${snakeCase(fullName || '')}` };

      const host = `${window.location.protocol}//${window.location.host}`;
      agreementsLink = `${host}${generatePath(getPath(Routes.EVENT_REGISTRANTS), {
        organizationId,
        selectedEventId: eventId,
      })}?${qs.stringify(queryParams)}`;
    }

    return [
      ...acc,
      {
        [FieldsToInclude.distance]: distances?.find((distance) => distance?.id === categoryId)?.categoryTypeAndValue,
        [FieldsToInclude.BibNumber]: `'${bibNumber}`,
        ...userDataParsed,
        ...rest,
        registrantEmail: userEmail,
        [FieldsToInclude.registeredDate]: moment(paidDate).format('MMMM Do YYYY, h:mm:ss a'),
        ...othersFields,
        ...{ [FieldsToInclude.Agreements]: agreementsLink },
      },
    ];
  }, []);

// eslint-disable-next-line
export const registrantsForUploadFormatter = (items, distances = [], options = {}) =>
  items?.reduce((acc, registrantData) => {
    const {
      lineItemId,
      orderId,
      eventId,
      bibNumber,
      userEmail,
      categoryId,
      id: registrantId,
      fullName,
      gender,
      age,
    } = registrantData;

    return [
      ...acc,
      {
        [FieldsToInclude.bibNumber]: bibNumber,
        [FieldsToInclude.gender]: gender,
        [FieldsToInclude.age]: age,
        [FieldsToInclude.email]: userEmail,
        [FieldsToInclude.lineItemId]: lineItemId,
        [FieldsToInclude.eventId]: eventId,
        [FieldsToInclude.orderId]: orderId,
        [FieldsToInclude.registrantId]: registrantId,
        [FieldsToInclude.categoryId]: categoryId,
        [FieldsToInclude.fullName]: fullName,
      },
    ];
  }, []);

export const parseDistanceHelper = (input = '') => {
  const lowerInput = input.toLowerCase();
  let distance = null;

  // Check for specific race names
  if (lowerInput.includes('half-marathon')) {
    distance = 21.0975;
  } else if (lowerInput.includes('marathon')) {
    distance = 42.195;
  } else {
    // Extract numeric distance
    const match = lowerInput.match(/(\d+(\.\d+)?)/);
    if (match) {
      distance = parseFloat(match[1]);
    }
  }

  return distance;
};
