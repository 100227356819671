import { useCallback, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generatePath, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import { Grid, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import {
  ProgressPage,
  CheckoutErrorType,
  Routes,
  PaymentMethod,
  AppConstants,
  CheckoutState,
  PaymongoPaymentStatus,
} from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Iconify from 'components/Iconify';
import { getHostUrl, getPath, removeEmptyFieldsInObj, titleUrlFormatter } from 'utils';
import useAuth from 'hooks/useAuth';
import { FormProvider } from 'components/hook-form';
import GeneralModal from 'components/GeneralModal';
import {
  updateEventRegistrationOrder,
  createPaymentIntent,
  getCalculatedRegistrationCart,
} from 'modules/registration/actions';
import { attachPaymentIntentToPaymentMethod, createPaymentMethod } from 'helpers/index';
import { constantsSelector } from 'modules/app/selector';
import { userDataSelector } from 'modules/user/selector';
import { paymentOptionsAvailableSelector, registrationDetailsSelector } from 'modules/registration/selector';
import { eventDataSelector } from 'modules/events/selector';
import UrlRedirector from 'components/UrlRedirector';
// import DialogIframeRenderer from 'components/DialogIframeRenderer';
import RegistrationCartSummary from './RegistrationCartSummary';
import CheckoutBillingShippingAddress from './CheckoutBillingShippingAddress';
import CheckoutPaymentMethods from './CheckoutPaymentMethods';
import { PaymentProgressModal } from './PaymentProgressModal';

export default function CheckoutPayment({ isCartSummaryFetchedSuccess }) {
  const appConstants = useSelector(constantsSelector);

  const base64PaymongoPrivateKey = appConstants?.[AppConstants.PAYMONGO_PUBLIC_API_KEY_HASH];
  const createPaymentMethodUrl = appConstants?.[AppConstants.PAYMONGO_CREATE_PAYMENT_METHOD_URL];

  const [enableNextCard, setEnableNextCard] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [iframeRedirectUrl, setIframeRedirectUrl] = useState('');
  const [paymentErrors, setPaymentErrors] = useState([]);
  const [paymentModalData, setPaymentModalData] = useState({
    paymentIntentId: '',
    paymentMethodId: '',
    paymentOption: '',
    base64PaymongoPrivateKey,
  });

  const openPaymentModal =
    paymentModalData?.paymentIntentId &&
    paymentModalData?.paymentMethodId &&
    paymentModalData?.paymentOption &&
    paymentModalData?.base64PaymongoPrivateKey;

  const openNextActionModal = (data) => {
    const attributes = data?.attributes;
    const status = attributes?.status;

    const nextAction = attributes?.next_action;
    const nextActionType = nextAction?.type;
    const redirectUrl = nextAction?.redirect?.url;

    switch (status) {
      case PaymongoPaymentStatus.AWAITING_NEXT_ACTION:
        if (nextActionType === 'redirect' && redirectUrl) {
          setIframeRedirectUrl(redirectUrl);
        }
        break;
      case PaymongoPaymentStatus.PROCESSING:
        break;
      case PaymongoPaymentStatus.SUCCESS:
        break;
      default:
        break;
    }
  };

  const [loadingModal, setLoadingModal] = useState(false);
  const history = useHistory();
  const { id: eventId, title: eventName } = useParams();
  const dispatch = useDispatch();

  const { user } = useAuth();

  const registrationDetails = useSelector(registrationDetailsSelector);
  const paymentOptions = useSelector(paymentOptionsAvailableSelector);
  const eventData = useSelector(eventDataSelector);
  const userData = useSelector(userDataSelector);
  const hostUrl = getHostUrl();

  const orderId = registrationDetails?.id;

  const billingShippingAddress = user?.address?.billingShippingAddress;

  const redirectUrls = (() => {
    const redirects = {
      success: `${hostUrl}${generatePath(getPath(Routes.REGISTRATION_CONFIRMATION), {
        orderId: orderId || 'dummy',
        eventId: eventData?.id,
        eventName: titleUrlFormatter(eventData.eventName),
        checkoutState: CheckoutState.success,
      })}`,
      failure: `${hostUrl}${generatePath(getPath(Routes.REGISTRATION_CONFIRMATION), {
        orderId: orderId || 'dummy',
        eventId: eventData?.id,
        eventName: titleUrlFormatter(eventData.eventName),
        checkoutState: CheckoutState.failed,
      })}`,
    };
    return redirects;
  })();

  const handleBackClick = useCallback(async () => {
    await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
  }, [dispatch]);

  const PaymentSchema = Yup.object().shape({
    payment: Yup.string().required('Payment is required!'),
    cardNumber: Yup.string().when('payment', {
      is: PaymentMethod.CARD,
      then: Yup.string()
        .required('Card number is required for card payment')
        .matches(/^\d{16}$/, 'Card number must be 16 digits'),
    }),
    expirationMonth: Yup.string().when('payment', {
      is: PaymentMethod.CARD,
      then: Yup.string()
        .required('Expiration month is required for card payment')
        .test(
          'valid-month',
          'Expiration month must be two digits, starting with 0 or 1, and between 01 and 12',
          (value) => {
            if (!value) return false; // This is handled by the `required()` check
            if (value.length === 1) return false;
            const firstDigit = parseInt(value[0], 10);
            const month = parseInt(value, 10);
            return (firstDigit === 0 || firstDigit === 1) && month >= 1 && month <= 12;
          }
        ),
    }),
    expirationYear: Yup.string().when('payment', {
      is: PaymentMethod.CARD,
      then: Yup.string()
        .required('Expiration year is required for card payment')
        .matches(/^\d{4}$/, 'Expiration year must be 4 digits')
        .test('is-future', 'Expiration year must be in the future', (value) => {
          const currentYear = new Date().getFullYear();
          return parseInt(value, 10) >= currentYear;
        }),
    }),
    cvv: Yup.string().when('payment', {
      is: PaymentMethod.CARD,
      then: Yup.string()
        .required('CVV is required for card payment')
        .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits'),
    }),
    nameOnCard: Yup.string().when('payment', {
      is: PaymentMethod.CARD,
      then: Yup.string()
        .required('Name on card is required for card payment')
        .matches(/^[a-zA-Z\s]+$/, 'Name on card must only contain letters and spaces'),
    }),
    // TODO bank Code
    // bankCode: Yup.string().when('payment', {
    //   is: PaymentMethod.BANKTRANSFER_BPI || PaymentMethod.BANKTRANSFER_UNION,
    //   then: Yup.string()
    //     .required('Name on card is required for card payment')
    //     .matches(/^[a-zA-Z\s]+$/, 'Name on card must only contain letters and spaces'),
    // }),
  });

  const defaultValues = {
    delivery: 'onsite',
    payment: '',
    cardNumber: '',
    expirationMonth: '',
    expirationYear: '',
    nameOnCard: '',
    cvv: '',
  };

  const methods = useForm({
    resolver: yupResolver(PaymentSchema),
    defaultValues,
    mode: 'onChange', // This enables auto-validation
  });

  const {
    handleSubmit,
    watch,
    formState: { isValid },
    setError,
  } = methods;

  const watchPayment = watch('payment');
  const watchDelivery = watch('delivery');
  const values = watch();

  const shouldEnableButton = Boolean(watchPayment) && enableNextCard === 2 && isCartSummaryFetchedSuccess && isValid; // 2 as of the moment once delivery is implemented then complete the logic

  // Watch payment
  useEffect(() => {
    (async () => {
      if (watchPayment) {
        setPaymentErrors([]);
        await dispatch(getCalculatedRegistrationCart(orderId, watchPayment));
        setPaymentModalData((prev) => ({
          ...prev,
          paymentOption: watchPayment,
        }));
      }
    })();
  }, [watchPayment, orderId]);

  const handleCreatePaymentMethod = useCallback(async () => {
    const configuration = {
      base64PaymongoPrivateKey,
      createPaymentMethodUrl,
    };
    const paymentDetails = removeEmptyFieldsInObj({
      cardNumber: values.cardNumber,
      expMonth: Number.parseInt(values.expirationMonth, 10),
      expYear: Number.parseInt(values.expirationYear, 10),
      cvv: values.cvv,
      address: billingShippingAddress,
      fullName: `${userData?.firstName} ${userData?.lastName}`,
      phoneNumber: userData?.phoneNumber,
      email: userData?.email,
    });

    const response = await createPaymentMethod(paymentDetails, values.payment, configuration);

    if (response.errors?.length) {
      setPaymentErrors((prev) => [...prev, ...(response?.errors || [])]);
      return null;
    }

    return response?.data;
  }, [values, billingShippingAddress, userData, base64PaymongoPrivateKey, createPaymentMethodUrl]);

  const attachPaymentMethodToPaymentIntent = useCallback(
    async (paymentIds, clientKey) => {
      const configuration = {
        returnUrl: redirectUrls.success,
        base64PaymongoPrivateKey,
        clientKey,
      };

      const response = await attachPaymentIntentToPaymentMethod(paymentIds, watchPayment, configuration);
      if (response.errors?.length) {
        setPaymentErrors((prev) => [...prev, ...(response?.errors || [])]);
        return null;
      }

      return response.data;
    },
    [watchDelivery, watchPayment, base64PaymongoPrivateKey, redirectUrls]
  );

  const handleCreatePaymentIntent = useCallback(async () => {
    const payload = {
      checkoutOptions: {
        paymentMethod: watchPayment,
        deliveryOption: watchDelivery,
      },
    };
    const response = await dispatch(createPaymentIntent(payload));

    return response.payload;
  }, [dispatch, watchPayment, watchDelivery]);

  const onSubmit = useCallback(async () => {
    try {
      // Errors Checking
      setPaymentErrors([]);
      if (values.payment === PaymentMethod.CARD) {
        const currentDate = moment();
        const enteredDate = moment(`${values.expirationYear}-${values.expirationMonth.padStart(2, '0')}-01`);
        if (enteredDate.isSameOrBefore(currentDate, 'month')) {
          setError('expirationMonth', {
            message: 'Expiration month and year should be on the future date',
          });
          setError('expirationYear', {
            message: 'Expiration month and year should be on the future date',
          });
          return;
        }
      }

      setIsSubmitting(true);
      const response = await handleCreatePaymentIntent();
      if (response?.type === CheckoutErrorType.SlotsAvailability) {
        setModalData({
          title: 'Slot availability issue',
          message:
            response?.message ||
            'Slot availability is less than what is required, remove some of slots or try again later',
          id: 'slots-availability',
          buttonOneText: 'Back to event',
          // buttonTwoText: 'Remove Some',
          buttonOneClick: () => {
            history.push(generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId }));
            setModalOpen(false);
          },
          // buttonTwoClick: async () => {
          //   setLoadingModal(true);
          //   await dispatch(updateEventRegistrationOrder({ progress: ProgressPage.summary }));
          //   setLoadingModal(false);
          //   setModalOpen(false);
          // },
        });
        setModalOpen(true);

        return;
      }

      if (response?.type === CheckoutErrorType.PriceChange) {
        setModalData({
          title: 'Price Changes',
          message:
            response?.message || "There's a sudden change of price, These might be caused by price validity date.",
          id: 'price-change',
          buttonOneText: 'Refresh Cart',
          buttonTwoText: 'Back to event',
          buttonOneClick: async () => {
            setLoadingModal(true);
            await dispatch(getCalculatedRegistrationCart());
            setLoadingModal(false);
            setModalOpen(false);
          },
          buttonTwoClick: () => {
            history.push(generatePath(getPath(Routes.EVENT), { title: titleUrlFormatter(eventName), id: eventId }));
            setModalOpen(false);
          },
        });
        setModalOpen(true);

        return;
      }

      const paymentIntentId = response?.paymentIntentData?.data?.data?.id;
      const clientKey = response?.paymentIntentData?.data?.data?.attributes?.client_key;
      if (paymentIntentId && clientKey) {
        const paymentMethod = await handleCreatePaymentMethod();

        const paymentMethodId = paymentMethod?.id;

        if (paymentMethodId) {
          //
          const attachedPaymentMethodToPaymentIntent = await attachPaymentMethodToPaymentIntent(
            {
              paymentIntentId,
              paymentMethodId,
            },
            clientKey
          );

          // TODO: UPDATE THE STATUS TO PENDING HERE
          setPaymentModalData((prev) => ({
            ...prev,
            paymentMethodId,
            paymentIntentId,
          }));

          openNextActionModal(attachedPaymentMethodToPaymentIntent);
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  }, [orderId, eventId, eventName, values]);

  const handleClosenDialogIframeRenderer = () => {
    setIframeRedirectUrl('');
  };

  return (
    <>
      {/* <DialogIframeRenderer
        onClose={handleClosenDialogIframeRenderer}
        open={iframeRedirectUrl}
        link={iframeRedirectUrl}
        renderCloseButton={false}
      /> */}

      {iframeRedirectUrl && <UrlRedirector url={iframeRedirectUrl} />}
      {openPaymentModal && (
        <PaymentProgressModal
          paymentIntentId={paymentModalData.paymentIntentId}
          publicKey={paymentModalData.base64PaymongoPrivateKey}
        />
      )}
      {modalData?.title && modalOpen && <GeneralModal {...modalData} open={modalOpen} loading={loadingModal} />}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Button color="inherit" onClick={handleBackClick} startIcon={<Iconify icon={'eva:arrow-ios-back-fill'} />}>
              Back
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12} md={8}>
          <CheckoutBillingShippingAddress
            billingShippingAddress={billingShippingAddress}
            setEnableNextCard={setEnableNextCard}
            cardNumber={1}
          />
          <FormProvider methods={methods} onSubmit={handleSubmit(() => {})}>
            {/* {!previewOrder && (
              <CheckoutDelivery
                onApplyShipping={handleApplyShipping}
                deliveryOptions={deliveryOptions || defaultDeliveryOptions}
                enableNextCard={enableNextCard}
                cardNumber={2}
                setEnableNextCard={setEnableNextCard}
              />
            )}
            )} */}
            <CheckoutPaymentMethods
              paymentOptions={paymentOptions}
              enableNextCard={enableNextCard}
              paymentErrors={paymentErrors}
              cardNumber={2}
              setEnableNextCard={setEnableNextCard}
              handlePayment={onSubmit}
              isSubmitting={isSubmitting}
              enabledSubmitButton={shouldEnableButton}
              paymentType={watchPayment}
              isCartSummaryFetchedSuccess={isCartSummaryFetchedSuccess}
            />
          </FormProvider>
        </Grid>

        <Grid item xs={12} md={4}>
          <RegistrationCartSummary isCheckoutPage displayFees={Boolean(watchPayment)} />
          <LoadingButton
            fullWidth
            size="large"
            variant="contained"
            loading={isSubmitting}
            disabled={!shouldEnableButton}
            onClick={onSubmit}
          >
            {'Pay now'}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
